/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useMemo, useContext, Fragment } from 'react';

import { ThemeContext } from '@cvent/carina-theme-provider';
import { Row, Col } from '@cvent/carina-layout';
import { Tabs } from '@cvent/carina-tabs';
import { Badge } from '@cvent/carina-tags';
import { CheckBox } from '@cvent/carina-checkbox';

import { translate } from 'translate';
import testID from 'utils/generateTestId';
import { injectTestId } from '@cvent/nucleus-test-automation';

/**
 * A group of scope checkboxes to put inside a scope tab
 * @param {array} scopes - List of scopes to display as checkboxes
 * @param {array} activeScopes - List of selected scopes by their name
 * @param {function} setActiveScopes - function to set all active scopes
 * @param {function} setDirty - function to set the dirtiness of the form
 * @param {array} lockedSCopes - List of disabled scopes that shouldn't be changed
 * @param {string} lockedScopeReason - A reason for why the scope is disabled / locked
 */
const ScopeGroup = ({ scopes, activeScopes, setActiveScopes, setDirty, lockedScopes = [], lockedScopeReason = '' }) => {
  const theme = useContext(ThemeContext);

  const Scopes = {
    borderBottom: `1px solid ${theme.borderColor.soft}`,
    padding: '1rem 0',

    '.description': {
      lineHeight: theme.font.lineHeight.base,
      fontWeight: theme.font.base.weight.light,
      color: theme.font.color.focus,
      fontSize: theme.font.mono.size.xs,
      marginTop: 4,
      marginLeft: 22,
    },
  };

  return (
    <Row {...injectTestId('scopes.container')}>
      {scopes.map((scope, idx) => (
        <Col key={scope.name} width={1 / 2} padding={0}>
          <div
            title={lockedScopes.includes(scope.name) ? lockedScopeReason : undefined}
            css={Scopes}
            {...injectTestId(`scopes.${scope.name}`)}
          >
            <CheckBox
              checked={activeScopes.includes(scope.name)}
              disabled={lockedScopes.includes(scope.name)}
              onChange={({ target }) => {
                if (target.checked) {
                  // Add checked value to the active scopes
                  setActiveScopes([...activeScopes, target.value]);
                } else {
                  // Remove unchecked value from the active scopes
                  setActiveScopes(activeScopes.filter((userScope) => userScope !== target.value));
                }

                // Set form to be dirty
                setDirty(true);
              }}
              id={scope.name}
              value={scope.name}
              testID={testID({
                type: 'checkbox',
                entity: `role[${idx}]`,
                intent: 'input',
              })}
            >
              {scope.name.split('/')[1]}
            </CheckBox>
            <div className="description">{scope.description}</div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

/**
 * A set of tabs for scope checkboxes. Each scope is filtered by it's prefix and grouped into separate tabs.
 * @param {array} scopes - List of scopes to display in all tabs
 * @param {array} activeScopes - List of selected scopes by their name
 * @param {function} setActiveScopes - function to set the list of activeScopes
 * @param {function} setDirty - function to set the dirtiness of the form
 * @param {array} lockedSCopes - List of disabled scopes that shouldn't be changed
 * @param {string} lockedScopeReason - A reason for why the scope is disabled / locked
 *
 */
export default function ScopeTabs({
  scopes,
  activeScopes,
  setActiveScopes,
  setDirty,
  lockedScopes = [],
  lockedScopeReason = '',
}) {
  /**
   *  Reduce the scopes into an object with key as the name's prefix and an array of scopes as the value
   * {
   *  event: [
   *    { name: 'event/contact-groups:write', description: '...'},
   *    { name: 'event/events:read', description: '...'},
   *  ],
   *  appointments: [
   *    { name: 'appointments/appointments:read', description: '...'},
   *  ]
   * }
   */
  const scopeGroups = {
    ...useMemo(() => {
      return scopes.reduce((groups, scope) => {
        const [group] = scope.name.split('/');
        groups[group] = groups[group] ? [...groups[group], scope] : [scope];
        return groups;
      }, []);
    }, [scopes]),
    all: [...scopes],
  };

  const theme = useContext(ThemeContext);

  const DEFAULT_GROUP = 'all';
  const [selected, setSelected] = useState(scopeGroups[DEFAULT_GROUP] ? DEFAULT_GROUP : Object.keys(scopeGroups)[0]);

  // Generate the options for the tabs component
  const tabOptions = [];
  const addGroupToOptions = (groupName) => {
    const groupActiveCount = scopeGroups[groupName].filter((scope) => activeScopes.includes(scope.name)).length;
    const TabName = css`
      margin-right: ${groupActiveCount ? '0.5rem' : 0};
    `;
    tabOptions.push({
      testID: `tabLabel.${groupName}`,
      label: (
        <Fragment>
          <span css={TabName}>{translate(`scopes.${groupName}`)}</span>
          {!!groupActiveCount && <Badge appearance="info" content={groupActiveCount} />}
        </Fragment>
      ),
      value: groupName,
    });
  };

  const selectAll = () => {
    setActiveScopes([...new Set([...activeScopes, ...scopeGroups[selected].map((scope) => scope.name)])]);
  };

  const selectNone = () => {
    setActiveScopes(
      activeScopes.filter((userScope) => {
        // Keep locked scopes as they are.
        if (lockedScopes.includes(userScope)) {
          return true;
        }

        // Filter the rest by the their inclusivity to the current group
        return !scopeGroups[selected].map((scope) => scope.name).includes(userScope);
      })
    );
  };

  // If there are scopes in the DEFAULT_GROUP, add them to the options first
  if (scopeGroups[DEFAULT_GROUP]) {
    addGroupToOptions(DEFAULT_GROUP);
  }

  // Add the remaining groups in any order.
  Object.keys(scopeGroups).map((groupName) => {
    if (groupName !== DEFAULT_GROUP) {
      addGroupToOptions(groupName);
    }
  });

  const TabContent = css`
    margin: 0.5rem 0;
  `;

  const SelectAllRow = css`
    display: flex;
    align-items: center;
    > span,
    > button {
    }
  `;

  const SelectLabel = css`
    color: ${theme.font.color.soft};
    margin-right: 0.5rem;
  `;

  const SelectButton = css`
    box-sizing: border-box;
    outline: none;
    appearance: none;
    background-color: transparent;
    color: ${theme.font.color.interactive.base};
    font-size: 0.8125rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 2px solid transparent;
    margin-right: 0.5rem;
    &:active {
      color: ${theme.font.color.interactive.active};
    }
    &:hover {
      text-decoration: underline;
      color: ${theme.font.color.interactive.hover};
    }
    &:focus {
      color: ${theme.font.color.interactive.focus};
      border: 2px solid rgb(66, 145, 233);
    }
  `;

  return (
    <Fragment>
      <Tabs onUpdate={setSelected} options={tabOptions} selected={selected} testID="app.scopes." />
      <div css={TabContent}>
        <div css={SelectAllRow}>
          <span css={SelectLabel}>{translate('labels.select')}</span>
          <button {...injectTestId('workspace.button.selectAll')} css={SelectButton} onClick={selectAll}>
            {translate('buttons.all')}
          </button>
          <button {...injectTestId('workspace.button.selectNone')} css={SelectButton} onClick={selectNone}>
            {translate('buttons.none')}
          </button>
        </div>
      </div>
      <ScopeGroup
        scopes={scopeGroups[selected]}
        activeScopes={activeScopes}
        setActiveScopes={setActiveScopes}
        setDirty={setDirty}
        lockedScopes={lockedScopes}
        lockedScopeReason={lockedScopeReason}
      />
    </Fragment>
  );
}
