/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import LoadingSpinner from '@cvent/carina-progress-indicator';
import DeveloperTable from 'components/tables/DeveloperTable';
import useFetchData from 'utils/useFetchData';
import useBreadcrumb from 'utils/useBreadcrumb';
import { translate } from 'translate';

export default function AcceptedDevelopersView() {
  const [developers, isLoading] = useFetchData('developers', {});
  useBreadcrumb({ link: '/developers/accepted', label: translate('title.accepted') });

  const Container = css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
    margin-top: 1rem;
  `;

  return <div css={Container}>{isLoading ? <LoadingSpinner /> : <DeveloperTable developers={developers} />}</div>;
}
