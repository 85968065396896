/** @jsx jsx */
import { useState, useEffect, useMemo, useContext } from 'react';
import { jsx, css } from '@emotion/core';

import { Alerts } from '@cvent/carina-alert';
import { Form } from '@cvent/carina-layout';
import { ScrollViewWithBars } from 'components/layout/ScrollViewWithBars';
import { ThemeContext } from '@cvent/carina-theme-provider';
import FormElement from '@cvent/carina-form-element';
import LoadingSpinner from '@cvent/carina-progress-indicator';
import RadioGroup from '@cvent/carina-radio-group';
import Textbox from '@cvent/carina-textbox';
import { codeToAlert } from '@universal-frontend/alerts';
import { translate } from 'translate';
import { useAppState } from 'components/AppContextProvider';
import { workspaceCallStatus } from 'enums';
import CompleteIllustration from 'components/images/Complete';
import DialogueFormFooter from './DialogueFormFooter';
import DialogueFormHeader from './DialogueFormHeader';
import ScopeTabs from './ScopeTabs';
import useFormValidator from 'utils/useFormValidator';
import { injectTestId } from '@cvent/nucleus-test-automation';

/**
 * Display a message to the user that their action has been a success
 * @param {string} successMessage The message to display to the user
 */
const SuccessView = ({ successMessage }) => {
  const theme = useContext(ThemeContext);
  const SuccessContainer = css`
    margin: 120px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  const SuccessMessage = css`
    font-size: ${theme.font.base.size.h1};
    font-weight: ${theme.font.base.weight.light};
  `;

  return (
    <section css={SuccessContainer}>
      <CompleteIllustration />
      <p css={SuccessMessage}>{successMessage}</p>
    </section>
  );
};

/* Check name length for validity */
const nameValidator = (name) => {
  if (name.length < 3 || name.length > 55) {
    return translate('errors.workspaceNameLength');
  }

  return false;
};

/**
 * A form to create or edit workspaces.
 * @param {func} onCancel A function for canceling the form. Most likely used to close the dialogue.
 * @param {func} onSave A function for saving the form. It should return a workspace call status object
 * @param {object} initialData Data to pre-populate the form in case of an update scenario. The form will lock any
 *                             scopes that are defined in initialData
 */
export default function WorkspaceForm({ onCancel, onSave, initialData = false }) {
  const [{ clients, scopes, setScopes }] = useAppState();
  const [isAllScopes, setIsAllScopes] = useState(initialData ? initialData.allScopes : true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [activeScopes, setActiveScopes] = useFormValidator(initialData ? initialData.scopes : [], () => {}, setDirty);
  const [name, setName, nameError] = useFormValidator(initialData ? initialData.name : '', nameValidator, setDirty);
  const [successMessage, setSuccessMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [filteredScopes, setFilteredScopes] = useState(false);

  const radioGroupScopeOptions = [
    { label: translate('scopes.all'), value: true },
    { label: translate('scopes.custom'), value: false },
  ];

  const handleUpdate = (value) => {
    setIsAllScopes(value);
    setDirty(true);
  };

  // Workspaces must have at least 1 scope
  const isScopesValid = (_scopes) => {
    return isAllScopes || _scopes.length;
  };

  const _onSave = async () => {
    setSubmitted(true);
    // if a form element is not valid don't save and show validation
    if (nameError || !isScopesValid(activeScopes)) {
      return;
    }
    setIsLoading(true);
    const { status, message } = await onSave(isAllScopes ? { name, allScopes: true } : { name, scopes: activeScopes });

    if (status === workspaceCallStatus.SUCCESS) {
      setSuccessMessage(message);
      setErrorMessage(false);
      setWarningMessage(false);
    } else if (status === workspaceCallStatus.ERROR) {
      setErrorMessage(message);
      setWarningMessage(false);
    } else if (status === workspaceCallStatus.WARNING) {
      setWarningMessage(message);
      setErrorMessage(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (scopes) {
      if (!!initialData) {
        // Use non-disabled scopes and already selected scopes in edit case
        setFilteredScopes(scopes.filter((_scope) => !_scope.disabled || initialData.scopes.includes(_scope.name)));
      } else {
        // Only use non-disabled scopes in create case
        setFilteredScopes(scopes.filter((_scope) => !_scope.disabled));
      }
    }
  }, [scopes, initialData]);

  // Fetch scopes on initial load
  useEffect(() => {
    // only set scopes if the component is still mounted
    let isMounted = true;

    const asyncEffect = async () => {
      if (!scopes) {
        try {
          setIsLoading(true);

          const { data } = await clients.authClient.getScopes();

          if (isMounted) {
            setScopes(data);
          }
        } catch (error) {
          if (isMounted) {
            setErrorMessage(codeToAlert(error.status || 500));
          }
        } finally {
          if (isMounted) {
            setIsLoading(false);
          }
        }
      }
    };
    asyncEffect();

    return () => {
      isMounted = false;
    };
  }, []);

  // Generate the list of alerts
  const alerts = useMemo(() => {
    const _alerts = [];
    if (warningMessage) {
      _alerts.push({
        appearance: 'warning',
        content: warningMessage,
      });
    }

    if (errorMessage) {
      _alerts.push({
        appearance: 'danger',
        content: errorMessage,
      });
    }
    return _alerts;
  }, [errorMessage, warningMessage]);

  const initialScopes = initialData && initialData.scopes ? initialData.scopes : [];

  const lockedScopes = useMemo(() => (initialData ? initialData.scopes : []), [initialScopes]);

  const sortedScopes = useMemo(
    () => (filteredScopes ? filteredScopes.sort((a, b) => (a.name || '').localeCompare(b.name || '')) : []),
    [filteredScopes]
  );

  if (isLoading || !filteredScopes) {
    return <LoadingSpinner testID="workspace-form.loading-spinner" />;
  }
  const shouldShowValidation = (validation) => validation && isDirty && submitted;

  const FormContainer = css`
    height: ${successMessage ? 'auto' : 'auto'};
    form {
      height: 100%;
    }
  `;

  const Padding = css`
    padding: 0 1.5rem;

    p {
      padding: 0 0 1.5rem;
    }
  `;

  return (
    <div css={FormContainer}>
      <Form>
        <ScrollViewWithBars
          header={
            <DialogueFormHeader
              hasPadding
              onDismiss={onCancel}
              title={
                !!initialData
                  ? translate('dialogues.workspace.updateTitle')
                  : translate('dialogues.workspace.createTitle')
              }
              testID="workspace-form-header"
            />
          }
          footer={
            <DialogueFormFooter
              onCancel={onCancel}
              onSave={_onSave}
              isLoading={isLoading}
              hasSave={!successMessage}
              isDirty={isDirty}
              hasPadding
              testID="workspace-form-footer"
            />
          }
        >
          {alerts ? <Alerts variant="user" testID="workspace-dialogue.form.alert" alerts={alerts} /> : null}
          {successMessage ? (
            <SuccessView successMessage={successMessage} />
          ) : (
            <div css={Padding}>
              <p>{translate('workspace.description')}</p>
              <FormElement>
                <FormElement.Label required label={translate('headings.workspaceName')} labelFor="workspaceName" />
                <Textbox
                  id="workspaceName"
                  testID="workspace-form.input.name"
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                  onBlur={({ target }) => setName(target.value ? target.value.trim() : target.value)}
                  hasError={shouldShowValidation(nameError)}
                />
                {shouldShowValidation(nameError) && <FormElement.Message type="error" text={nameError} />}
              </FormElement>

              <FormElement>
                <FormElement.Label
                  label={translate('labels.textPermissions')}
                  labelFor={translate('labels.textPermissions')}
                  required
                />
                <RadioGroup
                  inline
                  id={translate('labels.textPermissions')}
                  name={translate('headings.permissions')}
                  options={radioGroupScopeOptions}
                  selected={isAllScopes}
                  onUpdate={handleUpdate}
                />

                {
                  // When user changes the radio button from Custom Scopes to All Scopes while doing an update, show a warning.

                  // !!initialData - pre-existing data for the workspace, if this exists, we are doing an
                  // update, not a create.
                  // initialData.allScopes - boolean that determines if the saved state of the workspace
                  // has allScopes selected.
                  // isAllScopes(state of the button) - this boolean determines if the "All" radio button is currently
                  // selected on the UI.
                  !!initialData && !initialData.allScopes && isAllScopes ? (
                    <FormElement.Message
                      type="error"
                      text={translate('warnings.allScopesWarning')}
                      {...injectTestId('allScopesWarning')}
                    />
                  ) : null
                }

                {shouldShowValidation(!isScopesValid(activeScopes)) && (
                  <FormElement.Message type="error" text={translate('errors.workspaceMinimumScopes')} />
                )}
                {!isAllScopes ? (
                  <ScopeTabs
                    lockedScopes={lockedScopes}
                    scopes={sortedScopes}
                    activeScopes={activeScopes}
                    setActiveScopes={setActiveScopes}
                    setDirty={setDirty}
                    lockedScopeReason={translate('workspace.lockedScope')}
                  />
                ) : null}
              </FormElement>
            </div>
          )}
        </ScrollViewWithBars>
      </Form>
    </div>
  );
}
