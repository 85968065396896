export const sortDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const sort = ({ items, getter = (i) => i, order = sortDirections.ASC }) => {
  if (!items) {
    return null;
  }

  const comparator = (a, b) => {
    const left = getter(a) || '';
    const right = getter(b) || '';

    if (left >= right) {
      return order === sortDirections.ASC ? 1 : -1;
    }

    if (left <= right) {
      return order === sortDirections.ASC ? -1 : 1;
    }

    return 0;
  };

  return items.slice().sort(comparator);
};
