/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Alerts from '@cvent/carina-alert';
import translate from '../../translate';
import { useMemo } from 'react';
import { developerPortalUrl } from 'config.json';

/**
 * Keyword to search for in a usage-tier name to identify if it is Cvent's Free tier.
 */
const FREE_USAGE_TIER_NAME_KEYWORD = 'free';

/**
 * URL to the Rate Limits section of our public API docs.
 */
const USAGE_TIER_LEARN_MORE_URL = `${developerPortalUrl}/documentation#section/Getting-Started/Rate-Limits`;

/**
 * Component to display the free-tier info banner if the provided usage tier is the free tier.
 *
 * @param usageTier Usage Tier object from universal-auth's /usage/tier endpoint (with `quota` and `name` properties)
 * @returns {JSX.Element} Free Tier Informational Banner
 */
export const FreeTierInfoBanner = ({ usageTier }) => {
  // Give the free-tier alert a little space on top so it doesn't crowd against other elements
  const FreeTierAlertCss = css`
    margin: 2.5rem 0 0;
  `;

  // CSS constructs for displaying the message and a "Learn More" Link side-by-side inside the Carina Info Alert.
  const FreeTierDivRowCss = css`
    display: flex;
  `;

  const FreeTierDivColumnLeftCss = css`
    flex-grow: 1;
  `;

  const FreeTierDivColumnRightCss = css`
    white-space: nowrap;
    padding: 0 0 0 0.5rem;
  `;
  // Construct the localized messages
  const freeTierMessage = translate('info.freetier.limits', {
    // if no quota is present on the current usage tier (i.e large event tier), set it to an empty string
    quota: usageTier.quota ? usageTier.quota.toLocaleString() : '',
  });
  const learnMoreLabel = translate('labels.learnMore');
  // Construct an HTML element for showing the banner message with a Learn More link on the right to embed in the
  // Carina Info Page Alert
  const freeTierBannerElement = (
    <div css={FreeTierDivRowCss}>
      <div data-cvent-id="info.freetier.banner.message" css={FreeTierDivColumnLeftCss}>
        {freeTierMessage}
      </div>
      <div data-cvent-id="info.freetier.banner.learnmore" css={FreeTierDivColumnRightCss}>
        <a target="_blank" href={USAGE_TIER_LEARN_MORE_URL}>
          {learnMoreLabel}
        </a>
      </div>
    </div>
  );

  // Display the info alert only if the logged-in user is in an account assigned to the free tier
  const freeTierAlerts = useMemo(() => {
    if (usageTier.name.toLowerCase().includes(FREE_USAGE_TIER_NAME_KEYWORD)) {
      return [
        {
          content: freeTierBannerElement,
          appearance: 'info',
        },
      ];
    }
    return [];
  }, [usageTier]);

  // Usage tier is not the free one? No element to display.
  if (freeTierAlerts.length === 0) {
    return null;
  }

  return (
    <div id="info.freetier.banner.div" css={FreeTierAlertCss}>
      <Alerts
        data-cvent-id="info.freetier.banner"
        testID="info.freetier.banner"
        variant="page"
        dismissible={false}
        alerts={freeTierAlerts}
      />
    </div>
  );
};

export default FreeTierInfoBanner;
