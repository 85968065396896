import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { css } from '@emotion/core';

import { Modal } from '@cvent/carina-modal';

import { translate } from 'translate';
import { useAppState } from 'components/AppContextProvider';
import { invitationCallStatus } from 'enums';
import InvitationForm from 'components/forms/InvitationForm';

// Capture the limit number (10) in this type of message:
// "Account 456ac2ff-3609-4b0a-9a79-81bf344881ef has reached the maximum number of developers (10)"
const INVITE_LIMIT_REGEX = /.+\((\d+)\)$/;

/**
 * Takes the body of an HTTP error response and checks to see if it has the invitation developer limit characteristics.
 *
 * @param errorBody the .body from a caught HTTP request error.
 * @returns {boolean} True if this looks like an invite developer limit has been hit.
 */
function conflict409IsDeveloperLimit(errorBody) {
  return (
    errorBody?.error?.message === 'A limit has been exceeded' &&
    errorBody?.error?.details &&
    errorBody.error.details.length > 0
  );
}

/**
 * Once {@link conflict409IsDeveloperLimit} has reported true for the argument,
 * extract the limit value out of the message.
 *
 * @param errorBody the .body from a caught HTTP request error.
 * @returns {string|string} The limit value or 'unknown' if for some reason the error doesn't match the regex.
 */
function extractInvitationLimit(errorBody) {
  const limitMessage = errorBody.error.details[0].message;
  const match = INVITE_LIMIT_REGEX.exec(limitMessage);
  return match ? match[1] : 'unknown';
}

/**
 *  A modal for inviting developers.
 */
export default function InviteDeveloperDialogue() {
  const htmlElementRef = useRef(document.getElementsByTagName('html')[0]); // for scrollLock
  const [{ clients, accountMappingId, setInvitations, invitations }] = useAppState();
  const { authClient } = clients;
  const history = useHistory();
  const location = useLocation();

  const sendInvite = async (inviteData) => {
    try {
      const _developers = await authClient.getDevelopers({
        email: inviteData.email,
      });

      const records = _developers.data || _developers;

      if (records.length > 0) {
        const developersError = {
          status: 400,
          body: {
            error: {
              message: translate('errors.userAlreadyJoined'),
            },
          },
        };

        throw developersError;
      }

      const _invite = await authClient.sendInvite({
        body: { ...inviteData, accountMappingId },
      });

      setInvitations([...invitations, _invite]);

      return {
        status: invitationCallStatus.SUCCESS,
        message: translate('invitation.sent'),
      };
    } catch (error) {
      switch (error.status) {
        case 400:
          return {
            status: invitationCallStatus.ERROR,
            message: `${translate('errors.badRequest')} ${
              error.body.error.details ? error.body.error.details[0].message : error.body.error.message
            }`,
          };
        case 401:
        case 403:
          return { status: invitationCallStatus.ERROR, message: translate('errors.unauthorized') };
        case 409:
          if (conflict409IsDeveloperLimit(error.body)) {
            return {
              status: invitationCallStatus.WARNING,
              message: translate('warnings.invitationLimitReached', { limit: extractInvitationLimit(error.body) }),
            };
          }
          return { status: invitationCallStatus.ERROR, message: translate('errors.unkownError') };

        default:
          return { status: invitationCallStatus.ERROR, message: translate('errors.unkownError') };
      }
    }
  };

  const closeDialogue = () => {
    if (location.previousPath === undefined) {
      history.push('/developers/invitations');
    } else {
      history.go(-1);
    }
  };
  const ResetOverflow = css`
    div {
      overflow: inherit;
    }
  `;

  return (
    <div css={ResetOverflow}>
      <Modal format="m" isOpen testID="workspaces.modal.create" scrollLock={htmlElementRef}>
        <InvitationForm onCancel={closeDialogue} onSave={sendInvite} />
      </Modal>
    </div>
  );
}

InviteDeveloperDialogue.propTypes = {
  match: PropTypes.object.isRequired,
};
