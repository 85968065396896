/** @jsx jsx */
import { useContext, Fragment } from 'react';
import { jsx, css } from '@emotion/core';
import { useLocation } from 'react-router-dom';

import UniversalLink from '@universal-frontend/universal-link';
import { ThemeContext } from '@cvent/carina-theme-provider';
import { PersonAccountIcon } from '@cvent/carina-icon';
import Menu from '@cvent/carina-menu';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { LogOutIcon, UserIcon } from '@cvent/carina-icon';

import translate from 'translate';
import { useAppState } from 'components/AppContextProvider';
import AppWidth from 'components/layout/AppWidth';
import CventLogoWhite from 'components/images/CventLogoWhite';
import { storage } from 'utils/storage';
import Logger from '@cvent/nucleus-logging';

const logger = new Logger('AppHeader.js');

const HeaderNavLink = ({ isActive, isDisabled, ...rest }) => {
  const disabled = typeof isDisabled !== 'undefined' ? isDisabled : isActive;
  const activeCSS = isActive
    ? css`
        background: rgba(26, 32, 38, 0.12);
      `
    : css``;

  const Link = css`
    ${activeCSS}
    &:hover {
      background: rgba(26, 32, 38, 0.06);
    }
    a {
      padding: 1rem;
      text-decoration: none;
      height: 100%;
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }
    }
  `;

  return (
    <li css={Link}>
      <UniversalLink aria-selected={isActive} disabled={disabled} neutral variant="brand" {...rest} />
    </li>
  );
};

const HeaderNav = () => {
  const location = useLocation();

  const List = css`
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
  `;

  return (
    <ul css={List}>
      <HeaderNavLink
        testID="header.link.overview"
        isActive={location.pathname.indexOf('/overview') === 0 || location.pathname === '/'}
        disabled={false}
        href="/overview"
      >
        {translate('header.overview')}
      </HeaderNavLink>
      <HeaderNavLink
        testID="header.link.access-management"
        disabled={false}
        isActive={location.pathname.indexOf('/workspaces') === 0 || location.pathname.indexOf('/developers') === 0}
        href="/workspaces"
      >
        {translate('header.accessManagement')}
      </HeaderNavLink>
    </ul>
  );
};

const HeaderAction = ({ children }) => {
  const Container = css`
    margin-left: 1rem;
  `;
  return <div css={Container}>{children}</div>;
};

const Space = () => <span css={{ width: '10px' }} />;

const HeaderActions = () => {
  const [{ username, returnUrl, logoutUrl, clients }] = useAppState();
  const { authClient } = clients;

  const theme = useContext(ThemeContext);

  const handleUserAction = async ({ value }) => {
    if (value === 'logout') {
      try {
        await authClient.logout();
      } catch (e) {
        logger.error('Failed to logout', e);
      } finally {
        storage.clear();
        window.location.replace(logoutUrl);
      }
    }
  };

  const menuOptions = [];

  if (username) {
    const Username = css`
      color: ${theme.font.color.disabled};
      text-transform: none;
      margin-top: -0.5rem;
      display: flex;
      align-items: center;
      font-size: ${theme.font.base.size.s};
    `;

    menuOptions.push({
      heading: true,
      label: () => (
        <div css={Username}>
          <UserIcon color={theme.font.color.disabled} />
          <Space />
          {username}
        </div>
      ),
      value: username,
    });
  }

  if (logoutUrl) {
    menuOptions.push({
      value: 'logout',
      label: () => (
        <Fragment>
          <LogOutIcon />
          <Space />
          {translate('buttons.logout')}
        </Fragment>
      ),
      lineBreak: true,
    });
  }

  const Container = css`
    margin-left: auto;
    color: #fff;
    display: flex;
    > div {
      margin-left: 1rem;
    }
    a svg,
    a:hover svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
      fill: #fff;
    }
  `;

  const GoToAdmin = css`
    border-left: 1px solid rgba(255, 255, 255, 0.7);
    padding-left: 0.75rem;
    line-height: 28px;
    height: 100%;
    a {
      color: #fff;
    }
  `;

  return (
    <div css={Container}>
      <HeaderAction>
        <Menu
          testID="header.user-actions."
          options={menuOptions}
          placement="bottom-end"
          onSelect={handleUserAction}
          trigger={(handlePress) => (
            <UniversalLink testID="header.link.user-actions" neutral onClick={handlePress}>
              <PersonAccountIcon size="xl" />
            </UniversalLink>
          )}
        />
      </HeaderAction>
      {returnUrl && (
        <HeaderAction>
          <div css={GoToAdmin}>
            <a {...injectTestId('header.go-to-admin')} href={returnUrl}>
              {translate('header.goToAdmin')}
            </a>
          </div>
        </HeaderAction>
      )}
    </div>
  );
};

/**
 * The header
 */
export default function AppHeader() {
  const theme = useContext(ThemeContext);

  const Header = css`
    background: linear-gradient(90deg, #0071f2 0%, #00b2b4 73.41%, #00ca9d 100%);
    box-shadow: 0 1px 0 0 #d9dcde;
    color: #fff;
  `;

  const Container = css`
    display: flex;
    align-items: center;
  `;

  const TitleContainer = css`
    display: flex;
    align-items: center;
  `;

  const LogoContainer = css`
    padding: 3px 1rem 3px 0;
    margin-right: 1rem;
    height: 24px;
    width: 109px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  `;

  const Title = css`
    margin: 0;
    margin-right: 4.5rem;
    font-weight: ${theme.font.base.weight.light};
    color: ${theme.font.color.fill};
  `;

  return (
    <header css={Header} {...injectTestId('app.header')}>
      <AppWidth>
        <div css={Container} {...injectTestId('app.header.container')}>
          <div css={TitleContainer}>
            <div css={LogoContainer}>
              <CventLogoWhite />
            </div>
            <h3 {...injectTestId('header.title')} css={Title}>
              {translate('header.restAPIAccess')}
            </h3>
          </div>
          <HeaderNav />
          <HeaderActions />
        </div>
      </AppWidth>
    </header>
  );
}
