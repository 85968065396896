import dataReducer, { actions as dataActions } from './data';
import globalStateReducer, { actions as globalActions } from './global';
import alertsReducer, { createAlertActions } from '@universal-frontend/alerts';
import Logger from '@cvent/nucleus-logging';
import isProd from 'utils/isProd';
import { storage } from 'utils/storage';
import { translate } from 'translate';

const LOG = new Logger('reducers/index.js');

const allReducers = [dataReducer, globalStateReducer, alertsReducer];

export const actions = {
  ...dataActions,
  ...globalActions,
  ...createAlertActions(translate),
};

const reduceReducers =
  (...reducers) =>
  (prevState, value, ...args) => {
    if (!isProd) {
      // eslint-disable-next-line no-console
      console.group(`Transition ${value.type}`);

      LOG.debug('Previous state', prevState);

      LOG.debug('Current action', value);
    }

    const nextState = reducers.reduce((newState, reducer) => reducer(newState, value, ...args), prevState);

    // only store data thats actually being reduced
    const { clients, ...rest } = nextState;
    storage.put('app-state', rest);

    if (!isProd) {
      LOG.debug('Next state', nextState);

      // eslint-disable-next-line no-console
      console.groupEnd();
    }
    return nextState;
  };
export default reduceReducers(...allReducers);
