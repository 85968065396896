/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';

import { DataTable, Columns } from '@cvent/carina-data-table';
import { AddPersonAttendeeIcon, TrashIcon } from '@cvent/carina-icon';
import { tokens } from '@cvent/carina-tokens';
import Logger from '@cvent/nucleus-logging';

import ConfirmationDialog from 'components/ConfirmationDialog';
import EmptyTable from 'components/EmptyTable';
import { translate } from 'translate';
import { useAppState } from 'components/AppContextProvider';
import { sort, sortDirections } from 'utils/sorting';
import { codeToAlert } from '@universal-frontend/alerts';

import { injectTestId } from '@cvent/nucleus-test-automation';

const LOG = new Logger('components/tables/InvitationTable.js');

/**
 * A table to list applications
 * @param {array} invitations A list of invitations to display
 */
export default function InvitationTable({ invitations: _invitations = [] }) {
  const [sorting, setSorting] = useState({ sortBy: 'name', sortDirection: sortDirections.ASC });
  const [sortedInvitations, setSortedInvitations] = useState(_invitations);
  const [invitationToDelete, setInvitationToDelete] = useState(false);
  const [{ clients, addTranslatedAlert, invitations, setInvitations }] = useAppState();
  const { authClient } = clients;

  let isMounted = true;

  // Watch the mounted status so we don't try using state when the component isn't mounted
  useEffect(() => {
    isMounted = true;
    return () => {
      isMounted = false;
    };
  }, []);

  // Keep invitations updated with the passed prop.
  useEffect(() => {
    setInvitations(_invitations);
  }, [_invitations]);

  // Keep invitations sorted
  useEffect(() => {
    setSortedInvitations(
      sort({
        items: invitations,
        getter: (a) => a[sorting.sortBy],
        order: sorting.sortDirection,
      })
    );
  }, [invitations, sorting]);

  const deleteInvitation = async () => {
    try {
      await authClient.deleteInvitation(invitationToDelete);
      if (isMounted) {
        setInvitations(invitations.filter((invitation) => invitation.id !== invitationToDelete.id));
      }
    } catch (e) {
      LOG.error(e);
      addTranslatedAlert({
        alert: codeToAlert(e.status || 500),
        props: { entity: translate('headings.invitations') },
      });
    }
    setInvitationToDelete(false);
  };

  const Container = css`
    flex-grow: 1;
    height: 100%;
    padding: 1rem;
    width: 100%;
    > div {
      box-sizing: content-box;
    }
  `;

  const ActionCell = css`
    display: flex;
    align-items: center;
    margin-top: -3px;
    justify-content: space-evenly;

    svg {
      cursor: pointer;
    }
  `;

  return (
    <div css={Container}>
      {sortedInvitations && sortedInvitations.length ? (
        <DataTable
          tableData={sortedInvitations}
          rowCount={sortedInvitations.length}
          sort={(newSort) => setSorting(newSort)}
          sortBy={sorting.sortBy}
          sortDirection={sorting.sortDirection}
          aria-label="Invitations"
          dynamicHeightDataKey="email"
        >
          <Columns.NameColumn dataKey="email" width={250} flexGrow={1} label={translate('headings.email')} />
          <Columns.BasicColumn
            flexGrow={1}
            width={100}
            dataKey="expiry"
            testID="invitations.table.status"
            label={translate('headings.status')}
            cellRenderer={({ rowData }) => {
              const isExpired = Date.parse(rowData.expiry) < Date.now();
              if (isExpired) {
                return translate('status.expired');
              }

              return translate('status.sent');
            }}
          />
          <Columns.BasicColumn
            testID={'invitations.column.actions'}
            dataKey={'id'}
            disableSort
            width={50}
            alignment={'center'}
            cellRenderer={(cell) => (
              <div css={ActionCell} title={''}>
                <i
                  {...injectTestId('invitations.table.delete')}
                  onClick={(event) => {
                    event.preventDefault();
                    setInvitationToDelete(cell.rowData);
                  }}
                >
                  <TrashIcon
                    size="l"
                    color={tokens.default.font.color.soft}
                    title={translate('dialogues.deleteInvite.title')}
                  />
                </i>
              </div>
            )}
            label={translate('headings.workspace.actions')}
          />
        </DataTable>
      ) : (
        <EmptyTable message="emptyInvitationState" testID="invitations.table.empty">
          <AddPersonAttendeeIcon size="xl" color={tokens.default.font.color.brand.focus} />
        </EmptyTable>
      )}

      <ConfirmationDialog
        isOpen={!!invitationToDelete}
        title={translate('dialogues.deleteInvite.title')}
        description={translate('dialogues.deleteInvite.description')}
        confirmBtnText={translate('dialogues.deleteInvite.button')}
        onCancelClick={() => setInvitationToDelete(false)}
        onConfirmClick={deleteInvitation}
      />
    </div>
  );
}
