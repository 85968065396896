import { handleActions, combineActions } from 'redux-actions';
import actions from './actions';
export { default as actions } from './actions';

// Sets the default app staste; if no initial state is provided
const defaultState = {};

const { pushDeveloperWorkspaces, ...restActions } = actions;

// here is how you define how actions are handled. in this case, we want to
// hand setScopes and setUsers in a consistent manner since they're both simple
// data setters.

// if we wanted we could write, add / remove actions which do more complex
// manipulations, they would go...
export default handleActions(
  {
    [combineActions(...Object.values(restActions))]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    PUSH_DEVELOPER_WORKSPACES: (state, { payload }) => ({
      ...state,
      developerWorkspaces: { ...state.developerWorkspaces, [payload.developerId]: payload.developerWorkspaces },
    }),
    // here! you can define normal actions here as well.
  },
  defaultState
);
