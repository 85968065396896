/** @jsx jsx */
import { useState, useContext, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { Switch, Route } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';

import { ThemeContext } from '@cvent/carina-theme-provider';
import Button from '@cvent/carina-button';
import Menu from '@cvent/carina-menu';
import { PlusIcon, ChevronDownIcon } from '@cvent/carina-icon';
import { injectTestId } from '@cvent/nucleus-test-automation';
import Card from '@cvent/carina-card';

import { translate } from 'translate';
import { developerPageTabs } from 'enums';
import InvitationsView from 'components/pages/InvitationsView';
import AcceptedDevelopersView from 'components/pages/AcceptedDevelopersView';

const navigationOptions = [
  { label: translate('title.acceptedDevelopers'), value: developerPageTabs.ACCEPTED },
  { label: translate('title.invitedDevelopers'), value: developerPageTabs.INVITATIONS },
];

const DevelopersPage = () => {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const location = useLocation();
  const urlPathSegments = location.pathname.split('/');
  const [selectedOption, setSelectedOption] = useState(() => {
    if (urlPathSegments.length === 3) {
      // If the path has 3 parts use the last part to navigate to the selected tab...
      return navigationOptions.find((_option) => urlPathSegments[2] === _option.value);
    } else if (urlPathSegments.length === 4) {
      // If the path has 4 parts it should go to the invitation page, ...
      return navigationOptions.find((_option) => _option.value === developerPageTabs.INVITATIONS);
    }
    // ... otherwise default to Accepted.
    return navigationOptions.find((_option) => _option.value === developerPageTabs.ACCEPTED);
  });

  const handleSelect = (option) => {
    history.replace(`/developers/${option}`);
  };

  useEffect(() => {
    let selected = navigationOptions.find((_option) => _option.value === developerPageTabs.ACCEPTED);
    if (urlPathSegments.length === 3) {
      selected = navigationOptions.find((_option) => urlPathSegments[2] === _option.value);
    } else if (urlPathSegments.length === 4) {
      return;
    }
    setSelectedOption(selected);
  }, [location.pathname]);

  // div& is more specific than the parent's .Container > div so we can remove the flex-grow.
  const Header = css`
    div& {
      display: flex;
      justify-content: space-between;
      flex-grow: 0;
      margin: 1.625rem 0;
      align-items: center;
    }
  `;

  const SelectedTab = css`
    color: ${theme.font.color.base};
    font-size: 20px;
    font-weight: 300;
    line-height: 25px;
    margin-right: 3px;
  `;

  const DropDown = css`
    cursor: pointer;
    display: flex;
    align-items: center;
  `;

  const Container = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    height: 100%;
    min-height: 500px;
  `;
  return (
    <div>
      <div css={Header}>
        <Menu
          testID="developer.tab.menu"
          options={navigationOptions}
          onSelect={(option) => handleSelect(option.value)}
          placement="bottom-end"
          trigger={(toggleOpen) => (
            <div css={DropDown} onClick={toggleOpen} {...injectTestId('developers.tab.switcher')}>
              <span css={SelectedTab}>{selectedOption ? selectedOption.label : ''}</span>
              <ChevronDownIcon size="l" color={theme.font.color.soft} />
            </div>
          )}
        />

        <Button
          testID="developers.invite.button"
          appearance="primary"
          onClick={() => {
            history.push({
              pathname: '/developers/invitations/create',
              previousPath: location.pathname,
            });
          }}
          text={translate('buttons.inviteDeveloper')}
          icon={PlusIcon}
        />
      </div>

      <div>
        <Card
          height="100%"
          width="100%"
          bodyContent={
            <section css={Container}>
              <Switch>
                <Route
                  path="/developers/invitations/create"
                  component={
                    location.previousPath === '/developers/invitations' || location.previousPath === undefined
                      ? InvitationsView
                      : AcceptedDevelopersView
                  }
                />
                <Route path="/developers/invitations" component={InvitationsView} />
                <Route path="/developers/accepted" component={AcceptedDevelopersView} />
                <Route path="/developers/" component={AcceptedDevelopersView} />
              </Switch>
            </section>
          }
        />
      </div>
    </div>
  );
};

const DevelopersView = () => {
  const DevelopersPageCss = css`
    display: flex;
    flex-direction: column;
    > div:nth-of-type(2) {
      flex-grow: 1;
    }
  `;

  return (
    <div {...injectTestId('app.pages.developers')} css={DevelopersPageCss}>
      <DevelopersPage />
    </div>
  );
};

export default DevelopersView;
