/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { ThemeContext } from '@cvent/carina-theme-provider';
import { Row, Col } from '@cvent/carina-layout';
import Button from '@cvent/carina-button';
import Card from '@cvent/carina-card';
import { injectTestId } from '@cvent/nucleus-test-automation';

import { translate } from 'translate';
import DocumentationImage from 'components/images/Documentation';
import WorkspacesImage from 'components/images/Workspaces';
import { pixelToRem } from 'utils/styling';
import { developerPortalUrl } from 'config.json';

/**
 * A container for illustrations
 * @param {Node} children Child components for the illustration container
 */
const OverviewIllustration = ({ children }) => {
  const theme = useContext(ThemeContext);
  const IllustrationContainer = css`
    height: 321px;
    width: 100%;
    display: flex;
    background-color: ${theme.backgroundColor.surface};
    margin-bottom: 2rem;
    padding: 80px 139px;
    svg {
      height: 100%;
      width: 100%;
    }
  `;

  return <div css={IllustrationContainer}>{children}</div>;
};

/**
 * A column of the overview content displaying an illustration and a button.
 * @param {Component} illustration The image component
 * @param {Component} button The button component
 */
const OverviewColumn = ({ illustration, button }) => {
  const Column = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  `;

  return (
    <Col width={1 / 2}>
      <div css={Column}>
        <OverviewIllustration>{illustration}</OverviewIllustration>
        {button}
      </div>
    </Col>
  );
};

/**
 * The overview page
 */
export default function OverviewView() {
  const history = useHistory();

  const theme = useContext(ThemeContext);

  const Container = css`
    padding: 2rem;
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    h1 {
      margin-bottom: 2rem;
      margin-left: 0.5rem;
    }
  `;

  const Title = css`
    font-weight: 300;
    color: ${theme.font.color.active};
    font-size: ${pixelToRem(28)}rem;
    line-height: ${33 / 28}rem;
    margin: 26px 0 27px;
  `;

  return (
    <Fragment>
      <h1 css={Title} {...injectTestId('overview.title')}>
        {translate('pages.overview')}
      </h1>
      <Card
        height={686}
        bodyContent={
          <div css={Container}>
            <Row>
              <OverviewColumn
                illustration={<DocumentationImage />}
                button={
                  <a href={`${developerPortalUrl}/docs/rest-api/overview`} target="_blank">
                    <Button
                      testID="overview.button.view-documentation"
                      appearance="primary"
                      text={translate('buttons.viewDocumentation')}
                      isPill
                    />
                  </a>
                }
              />
              <OverviewColumn
                illustration={<WorkspacesImage />}
                button={
                  <Button
                    onClick={() => {
                      history.push('/workspaces');
                    }}
                    appearance="primary"
                    text={translate('buttons.manageWorkspaces')}
                    isPill
                    testID="overview.button.manage-workspaces"
                  />
                }
              />
            </Row>
          </div>
        }
      />
    </Fragment>
  );
}
