/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

const appWidth = '1400px';
const innerAppWidth = '1000px';

/**
 * A wrapper component that squeezes content to the app's width for consistent width across
 * components at different levels.
 * @param {boolean} inner - There are 2 app widths 1 wider one for header and footer and an inner width for the rest
 */
export default function AppWidth({ children, inner, ...rest }) {
  const Container = css`
    max-width: ${inner ? innerAppWidth : appWidth};
    margin: 0 auto;
    padding: 0 1.5rem;
    width: 100%;
  `;

  return (
    <div css={Container} {...rest}>
      {children}
    </div>
  );
}

AppWidth.propTypes = {
  children: PropTypes.node,
  inner: PropTypes.bool,
};
