import Logger from '@cvent/nucleus-logging';

import { translate } from 'translate';
import { workspaceActions } from 'enums';
import { codeToAlert } from '@universal-frontend/alerts';

const LOG = new Logger('components/tables/WorkspaceTable/helpers.js');
const WORKSPACE_HAS_CLIENTS = 'WORKSPACE_HAS_CLIENTS';

import timerManager, { TIMER_NAMESPACES } from 'app-timers';

/**
 * A function to delete a workspace from the confirmation dialogue.
 * @param {func} setIsLoading a function to let the table know when it is loading something.
 * @param {object} clients a set of API clients from useAppState.
 * @param {array} workspaces a list of workspaces being displayed in the table.
 * @param {func} addTranslatedAlert a function to set the app level alert
 * @param {func} setWorkspaces a function to set the list of workspaces
 * @param {string} id the id of the workspace to delete
 */
const onDeleteWorkspaceClick = async (
  { setIsLoading, clients, workspaces, setAlerts, addAlert, addTranslatedAlert, setWorkspaces },
  { id }
) => {
  setIsLoading(true);

  // hack to ensure we don't update after the view has changed (which will happen
  // when no workspaces are left)
  let willExist = true;

  try {
    await clients.authClient.deleteWorkspace({ id });

    const finalWorkspaces = workspaces.filter((workspace) => workspace.id !== id);

    willExist = finalWorkspaces.length > 0;

    setWorkspaces(finalWorkspaces);

    timerManager.setTimeout({
      callback: () => setAlerts([]),
      namespace: TIMER_NAMESPACES.ALERT_GLOBAL,
    });
  } catch (error) {
    LOG.error('Failed to delete workspace', error);

    try {
      if (error.body.error.details[0].code === WORKSPACE_HAS_CLIENTS) {
        addAlert({
          appearance: 'danger',
          dismissible: true,
          content: translate('errors.failedToDeleteWorkspace'),
        });
      } else {
        addAlert({
          appearance: 'danger',
          dismissible: true,
          content: translate('errors.unknown'),
        });
      }
    } catch (e) {
      addTranslatedAlert({
        alert: codeToAlert(error.status || 500),
        props: { entity: translate('header.invitations') },
      });
    }
  } finally {
    if (willExist) {
      setIsLoading(false);
    }
  }
};

/**
 * A handler for all dialogs on the Workspace table
 * @param {func} setActiveDialog a function to dismiss the open dialog
 * @param {string | enum} actionType The action to check to determine which function to run on confirm
 * @param {object} row The row data to be provided to confirm action
 * @param {event} e Click Event
 */
export const onDialogConfirm = async ({ setActiveDialog, ...rest }, actionType, row, e) => {
  e.preventDefault();
  let fn = () => {};

  if (actionType === workspaceActions.DELETE_WORKSPACE) {
    fn = onDeleteWorkspaceClick;
  }

  const res = fn(rest, row);

  setActiveDialog(null);

  return res;
};

/**
 *
 * @param {func} setSortDirection A function to set the sort direction
 * @param {func} setSortBy A function to set the column to sort by
 * @param {string} sortBy The column to sort by
 * @param {string} sortDirection The direction to sort.
 */
export const onSort = ({ setSortDirection, setSortBy }, { sortBy, sortDirection }) => {
  setSortDirection(sortDirection);
  setSortBy(sortBy);
};
