import { redirectValidation } from 'config.json';
import isProd from 'utils/isProd';

export const validateLogoutParam = (param) => {
  const regex = RegExp(redirectValidation);
  const regexValid = regex.test(param);

  // in production the only valid logout URLs match our pre-defined regex
  if (isProd) {
    return regexValid;
  }

  // if not production, ensure the logout URL is back to /dev-login, or is valid regex otherwise
  return param === '/#/dev-login' || regexValid;
};

export default validateLogoutParam;
