import { apiRequest } from '@universal-frontend/api-request';
import { environmentKey } from 'config.json';
import { storage } from 'utils/storage';
import { storageMap } from 'enums';
import isProd from 'utils/isProd';

class UniversalAuthClient {
  constructor({ endpoint, fullToken, environment, onUnauthorized = () => {} }) {
    this.domain = endpoint;
    this.fullToken = fullToken;
    this.isMock = environmentKey === 'mock';
    this.onUnauthorized = onUnauthorized;
    this.environment = environment;
  }

  setOnUnauthorized(onUnauthorized) {
    this.onUnauthorized = onUnauthorized;
  }

  _clearToken() {
    this.fullToken = null;
    storage.put(storageMap.FULL_TOKEN, null);
    this.onUnauthorized();
  }

  _defaultAuthHeader() {
    return this.fullToken ? { Authorization: `BEARER ${this.fullToken}` } : {};
  }

  _getRequestHeaders = ({ mockResponseCode = 200, headers = {} } = {}) => {
    const finalHeaders = {
      ...this._defaultAuthHeader(),
      'Content-Type': 'application/json',
      ...headers,
    };

    if (this.isMock) {
      finalHeaders['x-mock-response-code'] = mockResponseCode;
    }

    return finalHeaders;
  };

  isAuthenticated() {
    return !!this.fullToken;
  }

  /**
   * Make an api Request
   * @param {object} query An object of query string params
   * @param {object} params The rest of the request params
   */
  apiRequest({ query = {}, ...params }) {
    // Append environment if it was defined and if not in production
    if (this.environment && !isProd) {
      query.environment = this.environment;
    }

    return apiRequest({ ...params, query }).catch((err) => {
      if (this.isAuthenticated() && err.status === 401) {
        this._clearToken();
      }

      throw err;
    });
  }

  exchangeToken({ token }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/exchange-token`,
      method: 'GET',
      headers: this._getRequestHeaders({
        responseCode: 200,
        headers: { Authorization: `bearer ${token}` },
      }),
    }).then((res) => {
      // keep a copy of our token for future use.
      if (res.token) {
        this.fullToken = res.token;
        storage.put(storageMap.FULL_TOKEN, res.token);
        if (!isProd) {
          storage.put(storageMap.INITIAL_TOKEN, token);
          storage.put(storageMap.ENVIRONMENT, this.environment);
        }
      }

      return res;
    });
  }

  getScopes() {
    return this.apiRequest({
      endpoint: `${this.domain}v1/scopes`,
      method: 'GET',
      headers: this._getRequestHeaders(),
    });
  }

  sendInvite({ body }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/invitations`,
      method: 'POST',
      headers: this._getRequestHeaders(),
      body,
    });
  }

  editUser({ id, body }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/admin-ui/users/${id}`,
      method: 'PUT',
      headers: this._getRequestHeaders(),
      body,
    });
  }

  deleteDeveloper({ id }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/developers/${id}`,
      method: 'DELETE',
      headers: this._getRequestHeaders(),
    });
  }

  getDeveloperWorkspaces({ id }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/developers/${id}/workspaces`,
      method: 'GET',
      headers: this._getRequestHeaders(),
    });
  }

  deleteDeveloperWorkspace({ developerId, workspaceId }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/developers/${developerId}/workspaces/${workspaceId}`,
      method: 'DELETE',
      headers: this._getRequestHeaders(),
    });
  }

  updateDeveloperWorkspaces({ developerId, workspaceId }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/developers/${developerId}/workspaces/${workspaceId}`,
      method: 'PUT',
      headers: this._getRequestHeaders(),
    });
  }

  resendInvite({ id }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/admin-ui/users/${id}/resend-invitation`,
      method: 'POST',
      headers: this._getRequestHeaders(),
    });
  }

  getUsageTier() {
    return this.apiRequest({
      endpoint: `${this.domain}v1/usage/tier`,
      method: 'GET',
      headers: this._getRequestHeaders(),
    });
  }

  getUserInfo() {
    return this.apiRequest({
      endpoint: `${this.domain}v1/user-info`,
      method: 'GET',
      headers: this._getRequestHeaders(),
    });
  }

  getWorkspaces() {
    return this.apiRequest({
      endpoint: `${this.domain}v1/workspaces`,
      method: 'GET',
      headers: this._getRequestHeaders(),
    });
  }

  deleteWorkspace({ id }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/workspaces/${id}`,
      method: 'DELETE',
      headers: this._getRequestHeaders({ mockResponseCode: 202 }),
    });
  }

  createWorkspace({ body }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/workspaces`,
      method: 'POST',
      headers: this._getRequestHeaders(),
      body,
    });
  }

  getWorkspace({ id }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/workspaces/${id}`,
      method: 'GET',
      headers: this._getRequestHeaders(),
    });
  }

  updateWorkspace({ id, body }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/workspaces/${id}`,
      method: 'PUT',
      headers: this._getRequestHeaders(),
      body,
    });
  }

  getInvitations({ query } = { query: {} }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/invitations`,
      method: 'GET',
      headers: this._getRequestHeaders(),
      query,
    });
  }

  deleteInvitation({ id }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/invitations/${id}`,
      method: 'DELETE',
      headers: this._getRequestHeaders(),
    });
  }

  getDevelopers({ email, workspaceId }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/developers`,
      method: 'GET',
      headers: this._getRequestHeaders(),
      query: {
        email,
        workspaceId,
      },
    });
  }

  logout() {
    return this.apiRequest({
      endpoint: `${this.domain}v1/exchange-token`,
      method: 'DELETE',
      headers: this._getRequestHeaders(),
    });
  }

  removeDeveloperFromAccount({ developerId, accountMappingId }) {
    return this.apiRequest({
      endpoint: `${this.domain}v1/developers/${developerId}/accounts/${accountMappingId}`,
      method: 'DELETE',
      headers: this._getRequestHeaders(),
    });
  }
}

export default UniversalAuthClient;
