import { tokens } from '@cvent/carina-tokens';

export const getGlobalStyles = () => ({
  html: {
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
  },

  '*, *::before, *::after': {
    boxSizing: 'inherit',
  },

  body: {
    boxSizing: 'border-box',
    display: 'block',
    fontFamily: tokens.default.font.base.family,
    fontWeight: tokens.default.font.base.weight.regular,
    color: tokens.default.font.color.base,
    lineHeight: tokens.default.font.lineHeight.base,
    backgroundColor: tokens.default.backgroundColor.surface,
    height: '100%',
    width: '100%',
  },

  '#react-mount': {
    height: '100%',
    width: '100%',
  },

  ':focus:not(:focus-visible)': {
    outline: 'none',
  },

  'h1, h2, h3, h4, h5': {
    lineHeight: tokens.default.font.lineHeight.heading,
    fontWeight: tokens.default.font.base.weight.regular,
  },

  h1: {
    fontSize: tokens.default.font.base.size.h1,
  },

  h2: {
    fontSize: tokens.default.font.base.size.h2,
  },

  h3: {
    fontSize: tokens.default.font.base.size.h3,
  },

  h4: {
    fontSize: tokens.default.font.base.size.h4,
  },

  a: {
    color: tokens.default.font.color.interactive.base,
    textDecoration: 'none',
  },

  strong: {
    fontWeight: 500,
  },
});
