/** @jsx jsx */
import { jsx } from '@emotion/core';

export default function DocumentationImage() {
  return (
    <svg
      width="89px"
      height="85px"
      viewBox="0 0 89 85"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Spot/Plans</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient x1="91.9299518%" y1="41.8602573%" x2="7.74227281%" y2="80.3521644%" id="linearGradient-1">
          <stop stopColor="#B8D9FA" offset="0%" />
          <stop stopColor="#E7F2FD" offset="100%" />
        </linearGradient>
        <path
          d="M65.2969305,12.5044717 C63.521068,8.80557106 60.4005429,5.87571507 56.8976117,3.73645515 C52.7660364,1.21494146 47.9195773,-0.317476442 43.0924086,0.0557030471 C38.26524,0.428882536 33.5004818,2.86872776 30.8939923,6.94647932 C28.1501997,11.2397449 28.0321871,16.6933831 25.9998742,21.3643653 C21.8410653,30.9229779 10.8295833,35.2854348 3.84301124,43.0246556 C-9.71481928,58.0425779 16.0584486,66.0733098 27.6611283,61.9036812 C41.3131419,56.9967679 59.0320508,43.2061411 64.8952338,29.9565678 C67.3247045,24.4654982 67.8977463,17.9172757 65.2969305,12.5044717 Z"
          id="path-2"
        />
        <filter x="-30.6%" y="-19.8%" width="161.2%" height="165.1%" filterUnits="objectBoundingBox" id="filter-3">
          <feOffset dx="0" dy="8" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="5.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0.611724213   0 0 0 0 1  0 0 0 0.320722247 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0.611764706   0 0 0 0 1  0 0 0 0.179441652 0"
            type="matrix"
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
        <polygon
          id="path-4"
          points="9.39999998e-05 0.638353998 47.3665999 0.638353998 47.3665999 62.0391538 9.39999998e-05 62.0391538"
        />
        <polygon id="path-6" points="0 62.3416458 47.3665999 62.3416458 47.3665999 0.939905998 0 0.939905998" />
      </defs>
      <g id="Illustrations" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Spot" transform="translate(-269.000000, -1586.000000)">
          <g id="Spot/Plans" transform="translate(264.000000, 1570.000000)">
            <g id="Illustration" transform="translate(16.000000, 19.000000)">
              <g id="Organic-Shape">
                <use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-2" />
                <use fill="url(#linearGradient-1)" fillRule="evenodd" xlinkHref="#path-2" />
              </g>
              <g id="Group-16" transform="translate(11.000000, 0.000000)">
                <path
                  d="M11.402764,6.20287198 L8.77170398,6.20287198 L8.77170398,7.95691198 L39.4721039,7.95691198 C39.9571439,7.95691198 40.3500639,8.34983198 40.3500639,8.83487198 L40.3500639,53.5704119 L42.1041039,53.5704119 L42.1041039,50.9384119 L42.1041039,6.20287198 L11.402764,6.20287198 Z"
                  id="Fill-1"
                  fill="#F7F8F9"
                />
                <path
                  d="M43.8584259,5.32622799 L43.8584259,50.0617679 L45.6124659,50.0617679 L45.6124659,2.69422799 L12.280066,2.69422799 L12.280066,4.44826799 L42.9804659,4.44826799 C43.4655059,4.44826799 43.8584259,4.84118799 43.8584259,5.32622799"
                  id="Fill-3"
                  fill="#F7F8F9"
                />
                <path
                  d="M7.89430798,9.71132798 L5.26324799,9.71132798 L5.26324799,11.466308 L35.9636479,11.466308 C36.4486879,11.466308 36.8406679,11.858288 36.8406679,12.343328 L36.8406679,57.0788679 L38.5956479,57.0788679 L38.5956479,54.4478079 L38.5956479,9.71132798 L7.89430798,9.71132798 Z"
                  id="Fill-5"
                  fill="#F7F8F9"
                />
                <g id="Group-9" transform="translate(0.000000, 0.301646)">
                  <mask id="mask-5" fill="white">
                    <use xlinkHref="#path-4" />
                  </mask>
                  <g id="Clip-8" />
                  <path
                    d="M45.6126539,49.7599339 L43.8586139,49.7599339 L43.8586139,5.02439399 C43.8586139,4.54029399 43.4656939,4.14737399 42.9806539,4.14737399 L12.280254,4.14737399 L12.280254,2.39239399 L45.6126539,2.39239399 L45.6126539,49.7599339 Z M42.1045739,53.2680139 L40.3495939,53.2680139 L40.3495939,8.53341398 C40.3495939,8.04837398 39.9566739,7.65545398 39.4725739,7.65545398 L8.77217398,7.65545398 L8.77217398,5.90141399 L42.1045739,5.90141399 L42.1045739,53.2680139 Z M38.5946139,56.7770339 L36.8405739,56.7770339 L36.8405739,12.041494 C36.8405739,11.556454 36.4485939,11.163534 35.9635539,11.163534 L5.26315399,11.163534 L5.26315399,9.40949398 L38.5946139,9.40949398 L38.5946139,56.7770339 Z M1.754134,60.2851138 L35.0865339,60.2851138 L35.0865339,12.918514 L1.754134,12.918514 L1.754134,60.2851138 Z M46.4896739,0.638353998 L11.402294,0.638353998 C10.919134,0.638353998 10.526214,1.031274 10.526214,1.515374 L10.526214,4.14737399 L7.89421398,4.14737399 C7.40917398,4.14737399 7.01719398,4.54029399 7.01719398,5.02439399 L7.01719398,7.65545398 L4.38613399,7.65545398 C3.90109399,7.65545398 3.50817399,8.04837398 3.50817399,8.53341398 L3.50817399,11.163534 L0.877113998,11.163534 C0.393013999,11.163534 9.39999998e-05,11.556454 9.39999998e-05,12.041494 L9.39999998e-05,61.1630738 C9.39999998e-05,61.6471738 0.393013999,62.0391538 0.877113998,62.0391538 L35.9635539,62.0391538 C36.4485939,62.0391538 36.8405739,61.6471738 36.8405739,61.1630738 L36.8405739,58.5310739 L39.4725739,58.5310739 C39.9566739,58.5310739 40.3495939,58.1381539 40.3495939,57.6540539 L40.3495939,55.0229939 L42.9806539,55.0229939 C43.4656939,55.0229939 43.8586139,54.6300739 43.8586139,54.1450339 L43.8586139,51.5139739 L46.4896739,51.5139739 C46.9737739,51.5139739 47.3666939,51.1210539 47.3666939,50.6369539 L47.3666939,1.515374 C47.3666939,1.031274 46.9737739,0.638353998 46.4896739,0.638353998 L46.4896739,0.638353998 Z"
                    id="Fill-7"
                    fill="#0071F2"
                    mask="url(#mask-5)"
                  />
                </g>
                <mask id="mask-7" fill="white">
                  <use xlinkHref="#path-6" />
                </mask>
                <g id="Clip-11" />
                <path
                  d="M25.2220799,38.9036859 L29.9963399,38.9036859 L29.9963399,18.451166 L25.2220799,18.451166 L25.2220799,38.9036859 Z M6.84507998,38.9036859 L11.61934,38.9036859 L11.61934,18.451166 L6.84507998,18.451166 L6.84507998,38.9036859 Z M13.02934,38.9036859 L17.71524,38.9036859 L17.71524,18.451166 L13.02934,18.451166 L13.02934,38.9036859 Z M19.12524,38.9036859 L23.8111399,38.9036859 L23.8111399,18.451166 L19.12524,18.451166 L19.12524,38.9036859 Z M30.7013399,17.041166 L6.14007998,17.041166 C5.75091999,17.041166 5.43507999,17.357006 5.43507999,17.746166 L5.43507999,39.6086859 C5.43507999,39.9978459 5.75091999,40.3136859 6.14007998,40.3136859 L30.7013399,40.3136859 C31.0904999,40.3136859 31.4063399,39.9978459 31.4063399,39.6086859 L31.4063399,17.746166 C31.4063399,17.357006 31.0904999,17.041166 30.7013399,17.041166 L30.7013399,17.041166 Z"
                  id="Fill-10"
                  fill="#0071F2"
                  mask="url(#mask-7)"
                />
                <path
                  d="M22.9700279,45.5911279 L6.14026798,45.5911279 C5.75110799,45.5911279 5.43526799,45.2752879 5.43526799,44.8861279 C5.43526799,44.4969679 5.75110799,44.1811279 6.14026798,44.1811279 L22.9700279,44.1811279 C23.3591879,44.1811279 23.6750279,44.4969679 23.6750279,44.8861279 C23.6750279,45.2752879 23.3591879,45.5911279 22.9700279,45.5911279"
                  id="Fill-12"
                  fill="#0071F2"
                  mask="url(#mask-7)"
                />
                <path
                  d="M21.1487779,49.1647259 L6.13979798,49.1647259 C5.75063799,49.1647259 5.43479799,48.8488859 5.43479799,48.4597259 C5.43479799,48.0705659 5.75063799,47.7547259 6.13979798,47.7547259 L21.1487779,47.7547259 C21.5379379,47.7547259 21.8537779,48.0705659 21.8537779,48.4597259 C21.8537779,48.8488859 21.5379379,49.1647259 21.1487779,49.1647259"
                  id="Fill-13"
                  fill="#0071F2"
                  mask="url(#mask-7)"
                />
                <path
                  d="M24.5168919,52.7384179 L6.13989198,52.7384179 C5.75073199,52.7384179 5.43489199,52.4225779 5.43489199,52.0334179 C5.43489199,51.6442579 5.75073199,51.3284179 6.13989198,51.3284179 L24.5168919,51.3284179 C24.9060519,51.3284179 25.2218919,51.6442579 25.2218919,52.0334179 C25.2218919,52.4225779 24.9060519,52.7384179 24.5168919,52.7384179"
                  id="Fill-14"
                  fill="#0071F2"
                  mask="url(#mask-7)"
                />
                <path
                  d="M12.740196,56.3120159 L6.14045598,56.3120159 C5.75035599,56.3120159 5.43545599,55.9961759 5.43545599,55.6070159 C5.43545599,55.2178559 5.75035599,54.9020159 6.14045598,54.9020159 L12.740196,54.9020159 C13.129356,54.9020159 13.445196,55.2178559 13.445196,55.6070159 C13.445196,55.9961759 13.129356,56.3120159 12.740196,56.3120159"
                  id="Fill-15"
                  fill="#0071F2"
                  mask="url(#mask-7)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
