/** @jsx jsx */
import { css, jsx } from '@emotion/core';

// Components
import DeveloperLoginForm from 'components/forms/DeveloperLogin';

const DeveloperLoginView = () => {
  const Container = css`
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: auto;
  `;

  const Padder = css`
    flex-grow: 1;
    padding: 1rem 2rem;
  `;

  return (
    <section css={Container}>
      <div css={Padder}>
        <DeveloperLoginForm />
      </div>
    </section>
  );
};

export default DeveloperLoginView;
