import { createActions } from 'redux-actions';

// define what actions are handled, and how the inputs to the
// actions get processed into data transitions
// for example, SET_SCOPES will provide a data strucutre like:
// { scopes: [ .. the scopes .. ] }
export default createActions({
  SET_SCOPES: (scopes) => ({ scopes }),
  SET_WORKSPACES: (workspaces) => ({ workspaces }),
  SET_INVITATIONS: (invitations) => ({ invitations }),
  SET_DEVELOPERS: (developers) => ({ developers }),
  SET_DEVELOPER_WORKSPACES: (developerWorkspaces) => ({ developerWorkspaces }),
  PUSH_DEVELOPER_WORKSPACES: (developerWorkspaces) => developerWorkspaces,
});
