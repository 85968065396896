/** @jsx jsx */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';

import { Col, Row } from '@cvent/carina-layout';
import Button from '@cvent/carina-button';
import CarinaModal from '@cvent/carina-modal';

import { translate } from 'translate';

const ConfirmationDialog = (props) => {
  const { isOpen, isLoading, title, description, confirmBtnText, onCancelClick, onConfirmClick } = props;

  const onModalKeydown = (event) => {
    if (event.key === 'Escape') {
      onCancelClick();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onModalKeydown, false);

    return function cleanup() {
      window.removeEventListener('keydown', onModalKeydown);
    };
  }, []);

  const Container = css`
    padding: 1rem;
    h2 {
      margin-bottom: 0.5rem;
    }
  `;

  const Actions = css`
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    button {
      margin-left: 0.75rem;
    }
  `;

  return (
    <CarinaModal isOpen={isOpen} format="m">
      <div css={Container}>
        <Row>
          <Col width={1} padding={0}>
            <h2 data-cvent-id="confirmation-dialog-title">{title}</h2>
            <p data-cvent-id="confirmation-dialog-description">{description}</p>
          </Col>
        </Row>
        <div css={Actions}>
          <Button
            text={translate('buttons.cancel')}
            appearance="secondary"
            onClick={(event) => {
              event.preventDefault();
              onCancelClick();
            }}
            testID="confirmation-dialog-cancel-button"
            disabled={isLoading}
          />
          <Button
            text={confirmBtnText}
            appearance="primary"
            onClick={onConfirmClick}
            testID="confirmation-dialog-confirm-button"
            disabled={isLoading}
          />
        </div>
      </div>
    </CarinaModal>
  );
};

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
