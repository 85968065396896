/** @jsx jsx */
import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';

import { Alerts } from '@cvent/carina-alert';
import { Modal } from '@cvent/carina-modal';

import { translate } from 'translate';
import { useAppState } from 'components/AppContextProvider';
import { workspaceCallStatus } from 'enums';
import LoadingSpinner from '@cvent/carina-progress-indicator';
import WorkspaceForm from 'components/forms/Workspace';

/**
 *  A modal for creating / updating Workspaces.
 * @param {object} match The match object provided by react-router
 */
export default function WorkspaceDialogue({ match }) {
  const htmlElementRef = useRef(document.getElementsByTagName('html')[0]); // for scrollLock
  const [{ clients, accountMappingId, setWorkspaces, workspaces }] = useAppState();
  const { authClient } = clients;
  const [workspace, setWorkspace] = useState();
  const [fetchError, setFetchError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const updateId = match.params.id;

  useEffect(() => {
    if (match.params.id) {
      let isMounted = true;
      const asyncEffect = async () => {
        setLoading(true);
        try {
          const _workspace = await authClient.getWorkspace({ id: match.params.id });
          if (isMounted) {
            setWorkspace(_workspace);
          }
        } catch (error) {
          if (isMounted) {
            setFetchError(true);
          }
        }

        if (isMounted) {
          setLoading(false);
        }
      };

      asyncEffect();

      return () => {
        isMounted = false;
      };
    }
  }, [match.params.id]);

  const saveWorkspace = async (workspaceData) => {
    const saveOperation = updateId ? 'updateWorkspace' : 'createWorkspace';
    try {
      const _workspace = await authClient[saveOperation]({
        body: { ...workspaceData, accountMappingId, id: updateId },
        id: updateId,
      });

      // Add to existing list of workspaces if it was created
      if (!updateId) {
        setWorkspaces([...workspaces, _workspace]);
      } else {
        // Update existing workspace
        setWorkspaces(
          workspaces.map((__workspace) => {
            if (__workspace.id === _workspace.id) {
              return _workspace;
            }
            return __workspace;
          })
        );
      }

      return {
        status: workspaceCallStatus.SUCCESS,
        message: translate(updateId ? 'workspace.updated' : 'workspace.created'),
      };
    } catch (error) {
      switch (error.status) {
        case 400:
          return {
            status: workspaceCallStatus.ERROR,
            message: `${translate('errors.badRequest')} ${
              error.body.error.details ? error.body.error.details[0].message : error.body.error.message
            }`,
          };
        case 401:
        case 403:
          return { status: workspaceCallStatus.ERROR, message: translate('errors.unauthorized') };
        case 409:
          return { status: workspaceCallStatus.WARNING, message: translate('warnings.workspaceLimitReached') };
        case 422:
          return { status: workspaceCallStatus.ERROR, message: translate('errors.customScopesNotAllowed') };
        default:
          return { status: workspaceCallStatus.ERROR, message: translate('errors.unkownError') };
      }
    }
  };

  const closeDialogue = () => history.replace('/workspaces');

  return (
    <Modal format="l" isOpen testID="workspaces.modal.create" scrollLock={htmlElementRef} portal>
      {isLoading && <LoadingSpinner />}
      {!isLoading &&
        (fetchError ? (
          <Alerts
            variant="user"
            alerts={[
              {
                dismissible: true,
                onDismiss: closeDialogue,
                appearance: 'danger',
                content: translate('errors.fetchWorkspace'),
              },
            ]}
          />
        ) : (
          <WorkspaceForm initialData={workspace} onCancel={closeDialogue} onSave={saveWorkspace} />
        ))}
    </Modal>
  );
}

WorkspaceDialogue.propTypes = {
  match: PropTypes.object.isRequired,
};
