import { useEffect, useState } from 'react';

import { useAppState } from 'components/AppContextProvider';

/**
 * A custom hook to add breadcrumbs to components.
 * Note that if a child and parent render at the "same" time the child's useEffect
 * will fire first causing it's breadcrumb to come first.
 * To get past this you can conditionally render the child on the return of this hook
 * so it waits for the effect to finish before the child renders.
 * @param {object} breadcrumb the Breadcrumb object. It needs a link and a label
 * @param {array} conditions Array of conditions to fire the effect
 * @return {boolean} Whether or not the breadcrumb has been pushed.
 */
export default function useBreadcrumb(breadcrumb, conditions = []) {
  const [{ pushBreadcrumb, popBreadcrumb }] = useAppState();
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    pushBreadcrumb(breadcrumb);

    setIsReady(true);

    return () => {
      popBreadcrumb();
    };
  }, conditions);

  return isReady;
}
