import { createActions } from 'redux-actions';

export default createActions({
  SET_RETURN_URL: (returnUrl) => ({ returnUrl }),
  SET_RELAUNCH_URL: (relaunchUrl) => ({ relaunchUrl }),
  SET_LOGOUT_URL: (logoutUrl) => ({ logoutUrl }),
  SET_FULL_TOKEN: (fullToken) => ({ fullToken }),
  SET_EDIT_USER: (editUser) => ({ editUser }),
  SET_APP_BREADCRUMBS: (breadcrumbs) => ({ breadcrumbs }),
  PUSH_BREADCRUMB: (breadcrumb) => breadcrumb,
  POP_BREADCRUMB: () => {},
  SET_USAGE_TIER: (usageTier) => ({ usageTier }),
  SET_USERNAME: (username) => ({ username }),
  SET_ACCOUNT_MAPPING_ID: (accountMappingId) => ({ accountMappingId }),
});
