/** @jsx jsx */
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';

import Button from '@cvent/carina-button';

import { translate } from 'translate';

/**
 * The footer of a dialogue form with actions
 * @param {func} onCancel a function to cancel the form
 * @param {func} onSave A function to run when the form is saved
 * @param {boolean} hasSave change buttons displayed if the form has save
 * @param {boolean} isLoading disable buttons if the form is loading something
 * @param {boolean} isDirty disable save button if the form hasn't changed
 * @param {string} testID test ID
 */
export default function DialogueFormFooter({
  onCancel,
  onSave,
  hasSave,
  isLoading,
  isDirty,
  testID = 'dialogue-form-footer',
  hasPadding = false,
}) {
  const Footer = css`
    display: flex;
    justify-content: flex-end;
    padding: ${hasPadding ? '1rem 1.5rem' : '1rem 0'};
    button {
      margin-left: 1rem;
    }
  `;

  return (
    <footer css={Footer}>
      {!hasSave ? (
        <Button
          testID={`${testID}.button.close`}
          appearance="primary"
          text={translate('buttons.close')}
          onClick={onCancel}
        />
      ) : (
        <Fragment>
          <Button
            testID={`${testID}.button.cancel`}
            onClick={onCancel}
            appearance="tertiary"
            text={translate('buttons.cancel')}
            disabled={isLoading}
          />
          <Button
            testID={`${testID}.button.save`}
            appearance="primary"
            type="submit"
            text={translate('buttons.save')}
            onClick={onSave}
            disabled={isLoading || !isDirty}
          />
        </Fragment>
      )}
    </footer>
  );
}

DialogueFormFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  hasSave: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDirty: PropTypes.bool,
  testID: PropTypes.string,
  hasPadding: PropTypes.bool,
};
