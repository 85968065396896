/** @jsx jsx */
import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';

import { Modal } from '@cvent/carina-modal';

import EditDeveloperForm from 'components/forms/EditDeveloperForm';
import { LoadingSpinner } from '@cvent/carina-progress-indicator/lib/cjs';
import useFetchData from 'utils/useFetchData';
import { useAppState } from 'components/AppContextProvider';
import { translate } from 'translate';

/**
 *  A modal for editing developers.
 *  @param {object} match The match object provided by react-router.
 */
export default function EditDeveloperDialogue({ match }) {
  const htmlElementRef = useRef(document.getElementsByTagName('html')[0]); // for scrollLock
  const [{ addAlert }] = useAppState();
  const history = useHistory();
  const [developer, setDeveloper] = useState({});
  const [developers, isLoadingDevelopers] = useFetchData('developers', {});

  useEffect(() => {
    if (developers && developers.length) {
      const foundDeveloper = developers.find((_developer) => {
        return _developer.id === match.params.id;
      });
      if (foundDeveloper) {
        setDeveloper(foundDeveloper);
      } else {
        // Couldn't find the developer navigate away from edit developer page and set app alert
        addAlert({ content: translate('editDeveloper.developerNotFound') });
        history.replace('/developers');
      }
    }
  }, [developers]);

  const closeDialogue = () => history.replace('/developers');

  return (
    <Modal format="l" isOpen testID="devlopers.modal.edit" scrollLock={htmlElementRef} portal>
      {isLoadingDevelopers ? <LoadingSpinner /> : <EditDeveloperForm onClose={closeDialogue} developer={developer} />}
    </Modal>
  );
}

EditDeveloperDialogue.propTypes = {
  match: PropTypes.object.isRequired,
};
