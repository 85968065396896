/** @jsx jsx */
import { useRef, useContext } from 'react';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Modal } from '@cvent/carina-modal';
import Button from '@cvent/carina-button';
import { ThemeContext } from '@cvent/carina-theme-provider';

import translate from 'translate';
import ComeBackLater from 'components/images/ComeBackLater';
import { useAppState } from 'components/AppContextProvider';
import { storage } from 'utils/storage';
import { storageMap } from 'enums';
import isProd from 'utils/isProd';

export const SessionTimeoutDialogue = ({ show = false }) => {
  const htmlElementRef = useRef(document.getElementsByTagName('html')[0]); // for scrollLock
  const [{ relaunchUrl, logoutUrl }] = useAppState();
  const history = useHistory();

  const theme = useContext(ThemeContext);

  if (!show) {
    return null;
  }

  const extendSession = () => {
    storage.put(storageMap.RELAUNCH_LOCATION, window.location.hash.substr(2));
    window.location.replace(relaunchUrl);
  };

  const logout = () => {
    storage.clear();
    window.location.replace(logoutUrl);
  };

  const devLogin = () => {
    if (isProd) {
      return;
    }

    history.push('/dev-login');
  };

  const Container = css`
    padding: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-weight: 300;
    }

    h1,
    button {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 2rem;
      color: ${theme.font.color.soft};
    }
  `;

  const Illustration = css`
    display: flex;
    justify-content: center;
    padding: 29px;

    svg {
      height: 268px;
    }
  `;

  return (
    <Modal format="m" isOpen testID="modal.sessionTimeout" scrollLock={htmlElementRef} portal>
      <div css={Container}>
        <div css={Illustration}>
          <ComeBackLater />
        </div>
        <h1>{translate('sessionTimeout.header')}</h1>
        <p>{translate('sessionTimeout.body')}</p>
        {relaunchUrl && (
          <Button
            testID="sessionTimeout.buttons.extendSession"
            appearance="primary"
            text={translate('buttons.extendSession')}
            onClick={extendSession}
          />
        )}
        {logoutUrl && (
          <Button
            testID="sessionTimeout.buttons.logout"
            appearance="tertiary"
            text={translate('buttons.logout')}
            onClick={logout}
          />
        )}
        {!isProd && (
          <Button
            testID="sessionTimeout.buttons.devLogin"
            appearance="tertiary"
            text={translate('buttons.login')}
            onClick={devLogin}
          />
        )}
      </div>
    </Modal>
  );
};

SessionTimeoutDialogue.propTypes = {
  show: PropTypes.bool,
};

export default SessionTimeoutDialogue;
