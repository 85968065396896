/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Row, Col } from '@cvent/carina-layout';

import { translate } from 'translate';

const EmptyTable = ({ children, message }) => {
  return (
    <Row margin={32} minHeight="100%" data-cvent-id="table:users:empty">
      <Col
        width={50}
        flex={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {children}
      </Col>
      <Col
        width="fill"
        flex={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <p>{translate(message)}</p>
      </Col>
    </Row>
  );
};

EmptyTable.propsTypes = {
  children: PropTypes.element,
  message: PropTypes.string.isRequired,
};

export default EmptyTable;
