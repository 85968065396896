/** @jsx jsx */
import { useState, useEffect, useMemo, Fragment } from 'react';
import { jsx } from '@emotion/core';
import isEmail from 'validator/lib/isEmail';
import { useHistory } from 'react-router-dom';

import { Alerts } from '@cvent/carina-alert';
import { Form } from '@cvent/carina-layout';
import { ScrollViewWithBars } from 'components/layout/ScrollViewWithBars';
import { tokens } from '@cvent/carina-tokens';
import DropDown from '@cvent/carina-dropdown';
import FormElement from '@cvent/carina-form-element';
import LoadingSpinner from '@cvent/carina-progress-indicator';
import Textbox from '@cvent/carina-textbox';

import { invitationCallStatus } from 'enums';
import { translate } from 'translate';
import { useAppState } from 'components/AppContextProvider';
import AppWidth from 'components/layout/AppWidth';
import css from '@emotion/css';
import DialogueFormFooter from './DialogueFormFooter';
import DialogueFormHeader from './DialogueFormHeader';
import EmailSent from 'components/images/EmailSent';
import useFormValidator from 'utils/useFormValidator';
import { codeToAlert } from '@universal-frontend/alerts';

/**
 * Display a message to the user that their action has been a success
 * @param {string} successMessage The message to display to the user
 */
const SuccessView = ({ successMessage }) => {
  const SuccessContainer = css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  const SuccessMessage = css`
    font-size: ${tokens.default.font.base.size.h1};
    font-weight: ${tokens.default.font.base.weight.light};
  `;

  return (
    <section css={SuccessContainer}>
      <EmailSent />
      <p css={SuccessMessage}>{successMessage}</p>
    </section>
  );
};

/* Check email for validity */
const emailValidator = (email) => {
  if (!isEmail(email)) {
    return translate('errors.email');
  }

  return false;
};

/**
 * A form to create or edit invitations.
 * @param {func} onCancel A function for canceling the form. Most likely used to close the dialogue.
 * @param {func} onSave A function for saving the form. It should return a invitation call status object
 */
export default function InvitationForm({ onCancel, onSave }) {
  const [{ clients, workspaces, setWorkspaces }] = useAppState();

  const workspaceValidator = (workspace) => {
    if (!workspace || !workspaces || !workspaces.find((_workspace) => workspace === _workspace.id)) {
      return translate('errors.workspace');
    }
    return false;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail, emailError] = useFormValidator('', emailValidator, setDirty);
  const [workspace, setWorkspace, workspaceError] = useFormValidator(null, workspaceValidator, setDirty);
  const [successMessage, setSuccessMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const [workspaceMessage, setWorkspaceMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const history = useHistory();

  const _onSave = async () => {
    setSubmitted(true);
    // if a form element is not valid don't save and show validation
    if (emailError || workspaceError) {
      return;
    }
    setIsLoading(true);
    const { status, message } = await onSave({ email, workspaces: [{ id: workspace }] });

    if (status === invitationCallStatus.SUCCESS) {
      setSuccessMessage(message);
      setErrorMessage(false);
      setWarningMessage(false);
    } else if (status === invitationCallStatus.ERROR) {
      setErrorMessage(message);
      setWarningMessage(false);
    } else if (status === invitationCallStatus.WARNING) {
      setWarningMessage(message);
      setErrorMessage(false);
    }
    setIsLoading(false);
  };

  // Fetch workspaces on initial load
  useEffect(() => {
    // only set workspaces if the component is still mounted
    let isMounted = true;

    const asyncEffect = async () => {
      try {
        setIsLoading(true);

        const { data } = await clients.authClient.getWorkspaces();

        if (isMounted) {
          if (data.length) {
            setWorkspaces(data);
          } else {
            // Show warning message if workspace list is empty
            setWorkspaceMessage(translate('invitation.needWorkspaces'));
          }
        }
      } catch (error) {
        if (isMounted) {
          setErrorMessage(codeToAlert(error.status || 401));
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    asyncEffect();

    return () => {
      isMounted = false;
    };
  }, []);

  // Generate the list of alerts
  const alerts = useMemo(() => {
    const _alerts = [];

    if (workspaceMessage) {
      _alerts.push({
        appearance: 'warning',
        content: workspaceMessage,
        buttonText: translate('buttons.createWorkspace'),
        onButtonPress: () => {
          history.push('/workspaces/create');
        },
      });
    }

    if (warningMessage) {
      _alerts.push({
        appearance: 'warning',
        content: warningMessage,
      });
    }

    if (errorMessage) {
      _alerts.push({
        appearance: 'danger',
        content: translate(errorMessage.content || errorMessage),
      });
    }
    return _alerts;
  }, [workspaceMessage, errorMessage, warningMessage]);

  const workspaceOptions =
    workspaces && workspaces.length
      ? workspaces.map((_workspace) => ({
          label: _workspace.name,
          value: _workspace.id,
        }))
      : [];

  workspaceOptions.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  if (isLoading) {
    return <LoadingSpinner testID="invitation-form.loading-spinner" />;
  }

  const shouldShowValidation = (validation) => validation && isDirty && submitted;

  return (
    <AppWidth>
      <Form>
        <ScrollViewWithBars
          header={
            <DialogueFormHeader
              onDismiss={onCancel}
              title={translate('dialogues.invitation.createTitle')}
              testID="invitation-form-header"
            />
          }
          footer={
            <DialogueFormFooter
              onCancel={onCancel}
              onSave={_onSave}
              isLoading={isLoading}
              hasSave={!successMessage}
              isDirty={isDirty}
              testID="invitation-form-footer"
            />
          }
        >
          <Alerts testID="invitation-dialogue.form.alert" alerts={alerts} variant="user" />
          {successMessage ? (
            <SuccessView successMessage={successMessage} />
          ) : (
            <Fragment>
              <FormElement>
                <FormElement.Label required label={translate('headings.email')} labelFor="email" />
                <Textbox
                  id="email"
                  testID="invitation-form.input.email"
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  hasError={shouldShowValidation(emailError)}
                  autoFocus
                />
                {shouldShowValidation(emailError) && (
                  <FormElement.Message testID="invitation-form.validation.email" type="error" text={emailError} />
                )}
              </FormElement>
              <FormElement>
                <FormElement.Label required label={translate('headings.workspace')} labelFor="workspace" />
                <DropDown
                  id="workspace"
                  testID="invitation-form.input.workspace"
                  selected={workspace}
                  options={workspaceOptions}
                  onUpdate={setWorkspace}
                  placeholder={translate('invitation.workspace.placeholder')}
                  hasError={shouldShowValidation(workspaceError)}
                />
                {shouldShowValidation(workspaceError) && (
                  <FormElement.Message
                    testID="invitation-form.validation.workspace"
                    type="error"
                    text={workspaceError}
                  />
                )}
              </FormElement>
            </Fragment>
          )}
        </ScrollViewWithBars>
      </Form>
    </AppWidth>
  );
}
