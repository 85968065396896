import LocaleSwitcher from '@universal-frontend/translate';
import requireAll from 'utils/requireAll';
import { storageMap } from 'enums';

export const DEFAULT_LANGUAGE = 'en-US';
const files = requireAll(require.context('./languages', true, /\.json$/));

const switcher = new LocaleSwitcher(files, {
  storagePrefix: storageMap.STORAGE_PREFIX,
  defaultLocale: DEFAULT_LANGUAGE,
});

export const setLocale = switcher.setLocale;
export const translate = switcher.translate;
export const number = switcher.number;
export const currency = switcher.currency;
export const datetime = switcher.datetime;

export default translate;
