/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

// Components
import { Col, Row } from '@cvent/carina-layout';
import Alerts from '@cvent/carina-alert';

// Constants
import { colPaddingShape } from 'enums';

// Utils
import { useAppState } from 'components/AppContextProvider';
import Logger from '@cvent/nucleus-logging';

const LOG = new Logger('components/Alert.js');

const generateAlertText = ({ text, details }) => {
  return `${text} ` + (details ? `<ul><li>${details}</li></ul>` : '');
};

const Alert = ({ testID = '' }) => {
  const [{ alerts, setAlerts }] = useAppState();

  const details = alert && alert.details && alert.details.error ? alert.details.error.message : null;

  const { variant } = alert || {};

  const filteredAlerts = alerts || [];

  const finalAlerts = filteredAlerts.map((item) => ({
    ...item,
    content: generateAlertText({ text: item.content, details }),
    onDismiss: () => {
      LOG.debug('Dismiss alert');

      return setAlerts(alerts.filter((cur) => item.id !== cur.id));
    },
  }));

  return finalAlerts && filteredAlerts.length > 0 ? (
    <Row>
      <Col width={1} padding={colPaddingShape}>
        <Alerts testID={testID} variant={variant || 'page'} alerts={finalAlerts} />
      </Col>
    </Row>
  ) : null;
};

Alert.propsTypes = {
  testID: PropTypes.string,
};

export default Alert;
