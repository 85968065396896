/** @jsx jsx */

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';

// Components
import { Global } from '@emotion/core';
import Routes from 'components/Routes';
import LoadingSpinner from '@cvent/carina-progress-indicator';
import AppHeader from 'components/AppHeader';
import AppFooter from 'components/AppFooter';
import { GlobalTypography } from '@cvent/carina-typography';
import Alerts from '@cvent/carina-alert';

// Styles
import 'less/ResetAndFonts.less';
import { getGlobalStyles } from 'styles';

// Constants
import { storageMap } from 'enums';

// Utils
import { useAppState } from 'components/AppContextProvider';
import asyncUseEffect from 'utils/asyncUseEffect';
import Logger from '@cvent/nucleus-logging';
import { storage } from 'utils/storage';
import isProd from 'utils/isProd';
import { initializePendo } from '@universal-frontend/pendo';
import SessionTimeoutDialogue from 'components/dialogues/SessionTimeoutDialogue';
import TimerManager, { TIMER_NAMESPACES, TIMER_DURATIONS } from 'app-timers';
import { translate } from 'translate';
import validateRedirectParam from 'utils/validateRedirectParam';
import validateLogoutParam from '../../utils/validateLogoutParam';

const DEFAULT_FREE_TIER = {
  name: 'API_Platform_Free_Tier',
  quota: 1000.0,
  burstLimit: 1.0,
  rateLimit: 2.0,
};
const LOG = new Logger('components/containers/App.js');

const App = ({ initialToken, environment, returnUrl, relaunchUrl, logoutUrl, sessionTimeoutDuration }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [invalidParamError, setInvalidParamError] = useState(false);
  const history = useHistory();
  const [{ clients, setReturnUrl, setUsageTier, setUsername, setAccountMappingId, setRelaunchUrl, setLogoutUrl }] =
    useAppState();
  const { authClient } = clients;
  const [showSessionTimeout, setShowSessionTimeout] = useState(false);

  useEffect(() => {
    authClient.setOnUnauthorized(() => {
      setShowSessionTimeout(true);
    });
  }, []);

  // If return url is different and it is truthy set it in app state.
  useEffect(() => {
    if (returnUrl) {
      if (validateRedirectParam(returnUrl)) {
        setReturnUrl(returnUrl);
      } else {
        setInvalidParamError(true);
      }
    }
  }, [returnUrl]);

  // If relaunch url is different and it is truthy set it in app state.
  useEffect(() => {
    if (relaunchUrl) {
      if (validateRedirectParam(relaunchUrl)) {
        setRelaunchUrl(relaunchUrl);
      } else {
        setInvalidParamError(true);
      }
    }
  }, [relaunchUrl]);

  // If logoutUrl url is different and it is truthy set it in app state.
  useEffect(() => {
    if (logoutUrl) {
      if (validateLogoutParam(logoutUrl)) {
        setLogoutUrl(logoutUrl);
      } else {
        setInvalidParamError(true);
      }
    } else if (location.href.indexOf('/dev-login') === -1) {
      // no logout URL was found in app state and we are not in the dev-login page, show param error
      setInvalidParamError(true);
    }
  }, [logoutUrl]);

  asyncUseEffect(async () => {
    try {
      if (!initialToken && !clients.authClient.isAuthenticated()) {
        setShowSessionTimeout(true);
        return;
      }

      const storedEnv = storage.get(storageMap.ENVIRONMENT);
      const storedToken = storage.get(storageMap.INITIAL_TOKEN);

      const exchangeToken = async ({ token }) => {
        await authClient.exchangeToken({ token });
        storage.remove('app-state');

        sessionTimeoutDuration = sessionTimeoutDuration || TIMER_DURATIONS[TIMER_NAMESPACES.SESSION_TIMEOUT];

        // Set timeout time in local storage
        storage.put(storageMap.SESSION_TIMEOUT, Date.now() + Number(sessionTimeoutDuration));

        // Set timer to show the session timeout modal
        TimerManager.setTimeout({
          namespace: TIMER_NAMESPACES.SESSION_TIMEOUT,
          callback: () => setShowSessionTimeout(true),
          timeout: sessionTimeoutDuration,
        });
      };

      if (initialToken) {
        await exchangeToken({ token: initialToken });
      } else if (storedEnv !== environment && storedToken) {
        // If the environment changed and we have a stored token re-authenticate
        await exchangeToken({ token: storedToken });
      } else {
        // Coming back with an existing token
        const timeoutTime = storage.get(storageMap.SESSION_TIMEOUT);
        if (timeoutTime > Date.now()) {
          // session timeout is in future set a new timeout
          TimerManager.setTimeout({
            namespace: TIMER_NAMESPACES.SESSION_TIMEOUT,
            callback: () => setShowSessionTimeout(true),
            timeout: timeoutTime - Date.now(),
          });
        } else if (timeoutTime) {
          // session timeout was in the past show the timeout modal
          setShowSessionTimeout(true);
        }
      }

      try {
        const usageTier = await authClient.getUsageTier();
        setUsageTier(usageTier);
      } catch (e) {
        LOG.error("Couldn't get usage tier");
        LOG.error(e);
        if (e.status && e.status === 404) {
          // if we don't find a usage tier for this account, return free tier by default
          setUsageTier(DEFAULT_FREE_TIER);
        }
      }

      const { username, accountMappingId } = await authClient.getUserInfo();
      setUsername(username);
      setAccountMappingId(accountMappingId);
      initializePendo(username, accountMappingId);

      const relaunchLocation = storage.get(storageMap.RELAUNCH_LOCATION);
      if (relaunchLocation) {
        storage.remove(storageMap.RELAUNCH_LOCATION);
        history.replace(relaunchLocation);
      }
      setIsLoading(false);
    } catch (error) {
      LOG.error('An error occurred', error.body);
      storage.remove(storageMap.INITIAL_TOKEN);
      setShowSessionTimeout(true);
    } finally {
      if (!isProd) {
        setIsLoading(false);
      }
    }
  }, []);

  const Container = css`
    height: 100%;
    display: flex;
    flex-direction: column;
  `;

  const TopContent = css`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  `;

  const HeaderContainer = css`
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06), 0 10px 20px 0 rgba(0, 0, 0, 0.06);
  `;

  if (invalidParamError) {
    return (
      <div>
        <Global styles={{ ...getGlobalStyles() }} />
        <Alerts
          testID="invalid-params.alert"
          variant="app"
          alerts={[
            {
              content: translate('errors.invalidParametersSent'),
              appearance: 'danger',
            },
          ]}
        />
      </div>
    );
  }

  return (
    <div css={Container}>
      <Global styles={{ ...getGlobalStyles() }} />
      <GlobalTypography />
      <div css={TopContent}>
        <div css={HeaderContainer}>
          <AppHeader />
        </div>
        {isLoading ? <LoadingSpinner /> : <Routes />}
      </div>
      <AppFooter />
      {/* Show the session timeout modal if it is supposed to show and is either prod or not the dev login page */}
      <SessionTimeoutDialogue show={showSessionTimeout && (isProd || location.href.indexOf('/dev-login') === -1)} />
    </div>
  );
};

App.propTypes = {
  initialToken: PropTypes.string,
  environment: PropTypes.string,
  returnUrl: PropTypes.string,
  relaunchUrl: PropTypes.string,
  logoutUrl: PropTypes.string,
  sessionTimeoutDuration: PropTypes.string,
};

export default App;
