import Logger from '@cvent/nucleus-logging';

const LOG = new Logger('timers/index.js');

export const TIMER_NAMESPACES = {
  ALERT_GLOBAL: 'alertGlobal',
  SESSION_TIMEOUT: 'sessionTimeout',
};

export const TIMER_DURATIONS = {
  [TIMER_NAMESPACES.SESSION_TIMEOUT]: 60 * 60 * 1000, // 1 hour
};

class TimerManager {
  constructor() {
    this.timers = {};

    this.setTimeout = this.setTimeout.bind(this);
  }

  setTimeout({ namespace, callback, clearOnCollision = true, timeout = 50000 }) {
    if (!namespace || !callback) {
      LOG.error('Tried setting a timer without a namespace or callback. Timer will not be set.');
      return;
    }

    if (clearOnCollision && this.timers[namespace]) {
      LOG.debug(`Cleared existing timer for ${namespace}`);
      clearTimeout(this.timers[namespace]);
    }

    this.timers[namespace] = setTimeout(() => {
      LOG.debug(`Calling callback for ${namespace}`);
      return callback();
    }, timeout);

    LOG.debug(`Set timer for ${namespace}`);
  }
}

const manager = new TimerManager();

export default manager;
