/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';

import { Breadcrumbs, Crumb } from '@cvent/carina-breadcrumbs';

import { useAppState } from 'components/AppContextProvider';

export default function AppBreadcrumb() {
  const [{ breadcrumbs }] = useAppState();
  const history = useHistory();

  if (!breadcrumbs || breadcrumbs.length === 0 || breadcrumbs.length === 1) {
    return null;
  }

  return (
    <Breadcrumbs>
      {breadcrumbs.map((breadcrumb, i) => (
        <Crumb
          key={breadcrumb.link}
          onClick={(e) => {
            e.preventDefault();
            history.push(breadcrumb.link);
          }}
          isLast={i === breadcrumbs.length - 1}
        >
          {breadcrumb.label}
        </Crumb>
      ))}
    </Breadcrumbs>
  );
}
