/** @jsx jsx */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { css, jsx } from '@emotion/core';

import { DataTable, Columns } from '@cvent/carina-data-table';
import { AddPersonAttendeeIcon, EditPencilIcon, TrashIcon } from '@cvent/carina-icon';
import { tokens } from '@cvent/carina-tokens';
import Logger from '@cvent/nucleus-logging';
import { LoadingIcon } from '@cvent/carina-progress-indicator';

import ConfirmationDialog from 'components/ConfirmationDialog';
import EmptyTable from 'components/EmptyTable';
import { translate } from 'translate';
import { useAppState } from 'components/AppContextProvider';
import { sort, sortDirections } from 'utils/sorting';
import { codeToAlert } from '@universal-frontend/alerts';
import { injectTestId } from '@cvent/nucleus-test-automation';

const LOG = new Logger('components/tables/DeveloperTable.js');

const setFullName = (developer) => {
  return { ...developer, name: `${developer.firstName} ${developer.lastName}` };
};

/**
 * A table to list Developers
 * @param {array} developers A list of developers to display
 */
export default function DeveloperTable({ developers: _developers = [] }) {
  const [sorting, setSorting] = useState({ sortBy: 'name', sortDirection: sortDirections.ASC });
  const [sortedDevelopers, setSortedDevelopers] = useState(_developers);
  const [developerToDelete, setDeveloperToDelete] = useState(false);
  const [
    {
      clients,
      addTranslatedAlert,
      developers,
      setDevelopers,
      developerWorkspaces,
      pushDeveloperWorkspaces,
      accountMappingId,
    },
  ] = useAppState();
  const { authClient } = clients;
  const history = useHistory();

  let isMounted = true;

  const fetchDevelopersWorkspaces = async (developerId) => {
    try {
      const { data } = await authClient.getDeveloperWorkspaces({ id: developerId });
      if (isMounted && data.length) {
        pushDeveloperWorkspaces({ developerWorkspaces: data, developerId });
      } else if (isMounted && !data.length) {
        pushDeveloperWorkspaces({ developerId, developerWorkspaces: [] });
      }
    } catch (e) {
      LOG.error(`Couldn't fetch developer workspaces for developer ${developerId}`, e);
      addTranslatedAlert({
        alert: codeToAlert(e.status || 500),
        props: { entity: translate('headings.workspaces') },
      });
    }
  };

  // Keep Developers updated with the passed prop.
  useEffect(() => {
    setDevelopers(_developers);
    isMounted = true;
    if (_developers) {
      _developers.forEach((developer) => fetchDevelopersWorkspaces(developer.id));
    }
    return () => {
      isMounted = false;
    };
  }, [_developers]);

  // Keep Developers sorted
  useEffect(() => {
    setSortedDevelopers(
      sort({
        items: developers ? developers.map(setFullName) : [],
        getter: (a) => a[sorting.sortBy],
        order: sorting.sortDirection,
      })
    );
  }, [developers, sorting]);

  const deleteDeveloper = async () => {
    try {
      await authClient.removeDeveloperFromAccount({
        developerId: developerToDelete.id,
        accountMappingId,
      });
      setDevelopers(developers.filter((developer) => developer.id !== developerToDelete.id));
    } catch (e) {
      LOG.error(e);
      addTranslatedAlert({
        alert: codeToAlert(e.status || 500),
        props: { entity: translate('headings.workspaces') },
      });
    }
    setDeveloperToDelete(false);
  };

  const Container = css`
    flex-grow: 1;
    height: 100%;
    padding: 1rem;
    width: 100%;
    > div {
      box-sizing: content-box;
    }
  `;

  const NameCell = css`
    cursor: pointer;
  `;

  const ActionCell = css`
    display: flex;
    align-items: center;
    margin-top: -3px;
    justify-content: space-evenly;

    svg {
      cursor: pointer;
    }
  `;

  return (
    <div css={Container}>
      {sortedDevelopers && sortedDevelopers.length ? (
        <DataTable
          tableData={sortedDevelopers}
          rowCount={sortedDevelopers.length}
          sort={(newSort) => setSorting(newSort)}
          sortBy={sorting.sortBy}
          sortDirection={sorting.sortDirection}
          aria-label="Developers"
          testID="developers.table."
          dynamicHeightDataKey="name"
        >
          <Columns.BasicColumn
            cellRenderer={(cell) => (
              <a
                css={NameCell}
                testID="developers.table.edit"
                onClick={(event) => {
                  event.preventDefault();
                  history.push(`/developers/edit/${cell.rowData.id}`);
                }}
              >
                {cell.rowData.name}
              </a>
            )}
            dataKey="name"
            width={250}
            flexGrow={1}
            label={translate('headings.name')}
          />
          <Columns.BasicColumn dataKey="email" width={250} flexGrow={1} label={translate('headings.email')} />
          <Columns.BasicColumn
            flexGrow={1}
            width={100}
            dataKey="workspaces"
            disableSort
            testID="developers.table.workspaces"
            label={translate('headings.workspaces')}
            cellRenderer={({ rowData }) => {
              if (developerWorkspaces[rowData.id]) {
                return developerWorkspaces[rowData.id].length;
              }
              return <LoadingIcon size="m" />;
            }}
          />
          <Columns.BasicColumn
            testID={'developers.column.actions'}
            dataKey={'id'}
            disableSort
            width={50}
            alignment={'center'}
            cellRenderer={(cell) => (
              <div css={ActionCell} title={''}>
                <i
                  {...injectTestId('developers.table.edit')}
                  onClick={(event) => {
                    event.preventDefault();
                    history.push(`/developers/edit/${cell.rowData.id}`);
                  }}
                >
                  <EditPencilIcon
                    size="l"
                    color={tokens.default.font.color.soft}
                    title={translate('dialogues.editDeveloper.createTitle')}
                  />
                </i>
                <i
                  {...injectTestId('developers.table.delete')}
                  onClick={(event) => {
                    event.preventDefault();
                    setDeveloperToDelete(cell.rowData);
                  }}
                >
                  <TrashIcon
                    size="l"
                    color={tokens.default.font.color.soft}
                    title={translate('dialogues.deleteDeveloper.button')}
                  />
                </i>
              </div>
            )}
            label={translate('headings.workspace.actions')}
          />
        </DataTable>
      ) : (
        <EmptyTable message="emptyDeveloperState" testID="developers.table.empty">
          <AddPersonAttendeeIcon size="xl" color={tokens.default.font.color.brand.focus} />
        </EmptyTable>
      )}

      <ConfirmationDialog
        isOpen={!!developerToDelete}
        title={translate('dialogues.deleteDeveloper.title')}
        description={translate('dialogues.deleteDeveloper.description')}
        confirmBtnText={translate('dialogues.deleteDeveloper.button')}
        onCancelClick={() => setDeveloperToDelete(false)}
        onConfirmClick={deleteDeveloper}
      />
    </div>
  );
}
