/** @jsx jsx */
import { useContext } from 'react';
import { css, jsx } from '@emotion/core';

import { ThemeContext } from '@cvent/carina-theme-provider';
import UniversalLink from '@universal-frontend/universal-link';

import AppWidth from 'components/layout/AppWidth';
import CventLogo from 'components/images/CventLogo';
import translate from 'translate';

/**
 * A UniversalLink component styled to show appropriate text color on the footer background
 * @param {*} props props to drop
 */
const FooterLink = ({ ...props }) => {
  const theme = useContext(ThemeContext);

  const Wrapper = css`
    a {
      text-decoration: none;
      color: ${theme.font.color.soft};
      &:hover {
        text-decoration: underline;
        color: ${theme.font.color.hover};
      }
    }
  `;

  return (
    <span css={Wrapper}>
      <UniversalLink target="_blank" {...props} />
    </span>
  );
};

const AppFooter = () => {
  const theme = useContext(ThemeContext);

  const Footer = css`
    background-color: ${theme.backgroundColor.surface};
  `;

  const BottomFooterContainer = css`
    display: flex;
    align-items: center;
    min-height: 40px;
  `;

  const Logo = css`
    height: 1rem;
    width: auto;
    margin-right: 1rem;
  `;

  const Copyright = css`
    color: ${theme.font.color.soft};
  `;

  const BottomFooterLinks = css`
    margin-left: auto;
    span {
      padding: 0 0.5rem;
      border-right: 1px solid ${theme.borderColor.base};
      &:last-of-type {
        border: 0;
      }
    }
  `;

  return (
    <footer css={Footer}>
      <AppWidth css={BottomFooterContainer}>
        <CventLogo testID="footer.logo" css={Logo} />
        <span css={Copyright}>{translate('footer.copyright', { year: new Date().getFullYear() })}</span>
        <div css={BottomFooterLinks}>
          <FooterLink href="https://www.cvent.com/en/product-terms-of-use" testID="footer.link.terms-of-use">
            {translate('termsOfUse')}
          </FooterLink>
          <FooterLink href="https://www.cvent.com/en/privacy-policy" testID="footer.link.privacy-policy">
            {translate('footer.privacyPolicy')}
          </FooterLink>
          <FooterLink href="https://community.cvent.com/knowledgebase" testID="footer.link.help-and-support">
            {translate('footer.helpAndSupport')}
          </FooterLink>
        </div>
      </AppWidth>
    </footer>
  );
};

export default AppFooter;
