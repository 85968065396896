import { useEffect } from 'react';

const asyncUseEffect = (fn, ...args) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    async function caller() {
      return await fn();
    }

    caller();
  }, ...args);

export default asyncUseEffect;
