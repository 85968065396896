/** @jsx jsx */
import { useState, useMemo, useContext, Fragment } from 'react';
import { css, jsx } from '@emotion/core';

import { LoadingIcon } from '@cvent/carina-progress-indicator';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { Button } from '@cvent/carina-button';
import { ThemeContext } from '@cvent/carina-theme-provider';

import StatusLabel, { StatusLabelVariants } from '@universal-frontend/status-label';

import { translate } from 'translate';
import { useAppState } from 'components/AppContextProvider';

const WorkspaceList = ({ children, testID }) => {
  const List = css`
    padding: 0;
  `;

  return (
    <ul css={List} {...injectTestId(testID)}>
      {children}
    </ul>
  );
};

/**
 *
 * @param {object} workspace - The workspace object to be displayed
 * @param {func} onError - A function to run when there is an error
 * @param {func} setAssigned - A function to add or remove this workspace (depending on if it is assigned)
 * @param {boolean} isAssigned - Whether or not this workspace is currently assigned
 * @param {string} developerId - The current developer's id to assign the workspaces to
 * @param {boolean} disabled - Whether or not to disable the action to add or remove
 * @param {string} title - the title for the button to display a message to the user
 */
const WorkspaceListItem = ({ workspace, onError, setAssigned, isAssigned, developerId, title }) => {
  const theme = useContext(ThemeContext);
  const [isLoading, setLoading] = useState(false);
  const [{ clients, pushDeveloperWorkspaces }] = useAppState();
  const { authClient } = clients;

  // The action performed when the button a WorkspaceListItem is clicked. Either add or remove a workspace
  // e is the event object passed onClick.
  const action = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (isAssigned) {
        await authClient.deleteDeveloperWorkspace({ developerId, workspaceId: workspace.id });
        setAssigned((workspaces) => workspaces.filter((_workspace) => _workspace.id !== workspace.id));
      } else {
        await authClient.updateDeveloperWorkspaces({ developerId, workspaceId: workspace.id });
        setAssigned((workspaces) => [...workspaces, workspace]);
      }
    } catch (error) {
      onError(error);
    }
    const { data } = await authClient.getDeveloperWorkspaces({ id: developerId });
    pushDeveloperWorkspaces({ developerId, developerWorkspaces: data });
  };

  const ListItem = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    align-items: center;
    overflow: hidden;
    background-color: ${theme.backgroundColor.surface};
    height: 55px;
    padding: 0 0.5rem;
  `;

  const Label = css`
    span {
      margin-right: 1rem;
    }
  `;

  return (
    <li css={ListItem} {...injectTestId(`developer.edit.workspace.${workspace.id}`)}>
      <span css={Label}>
        {isAssigned && <StatusLabel variant={StatusLabelVariants.SUCCESS}>{translate('workspaceAdded')}</StatusLabel>}
        {workspace.name}
      </span>
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Button
          title={title}
          {...injectTestId(`developer.edit.workspace.button.${workspace.id}`)}
          onClick={action}
          text={isAssigned ? translate('buttons.remove') : translate('buttons.add')}
          appearance={isAssigned ? 'tertiary' : 'secondary'}
        />
      )}
    </li>
  );
};

export default function WorkspacePicker({ workspaces, onError, initialWorkspaces, developerId }) {
  const [assigned, setAssigned] = useState(initialWorkspaces || []);

  const unassigned = useMemo(() => {
    return workspaces.filter((workspace) => !assigned.some((_workspace) => _workspace.id === workspace.id));
  }, [workspaces, assigned]);

  const SelectedCount = css`
    margin-bottom: 1rem;
  `;

  return (
    <Fragment>
      <p css={SelectedCount}>{translate('xOfYSelected', { x: assigned.length, y: workspaces.length })}</p>
      <WorkspaceList testID="developer.workspaces.assigned">
        {assigned.map((workspace) => (
          <WorkspaceListItem
            isAssigned
            key={workspace.id}
            workspace={workspace}
            onError={onError}
            setAssigned={setAssigned}
            developerId={developerId}
            title={assigned.length === 1 ? translate('workspace.lastAssigned') : ''}
          />
        ))}
      </WorkspaceList>
      <WorkspaceList testID="developer.workspaces.unassigned">
        {unassigned.map((workspace) => (
          <WorkspaceListItem
            key={workspace.id}
            workspace={workspace}
            onError={onError}
            setAssigned={setAssigned}
            developerId={developerId}
          />
        ))}
      </WorkspaceList>
    </Fragment>
  );
}
