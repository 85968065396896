/** @jsx jsx */
import { jsx } from '@emotion/core';

export default function CompleteIllustration() {
  return (
    <svg width="375px" height="375px" viewBox="0 0 375 375" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Hero/Confirmation/Update@2x</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stopColor="#DA66FF" offset="0%" />
          <stop stopColor="#9B66FF" offset="100%" />
        </linearGradient>
        <rect id="path-2" x="114.4657" y="81.3161974" width="95.7061176" height="178.116897" rx="8.94136258" />
        <filter x="-59.6%" y="-26.9%" width="246.3%" height="180.8%" filterUnits="objectBoundingBox" id="filter-3">
          <feOffset dx="13" dy="26" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="19" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.607843137   0 0 0 0 0.4   0 0 0 0 1  0 0 0 0.324501812 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feOffset dx="0" dy="9" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
          <feColorMatrix
            values="0 0 0 0 0.607843137   0 0 0 0 0.4   0 0 0 0 1  0 0 0 0.402032382 0"
            type="matrix"
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
        <linearGradient x1="50%" y1="-2.48949813e-15%" x2="50%" y2="100%" id="linearGradient-4">
          <stop stopColor="#D266FF" offset="0%" />
          <stop stopColor="#FFA6FA" offset="100%" />
        </linearGradient>
        <rect id="path-5" x="0" y="0" width="99.4056503" height="185.14143" rx="8.94136258" />
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-7">
          <stop stopColor="#DA66FF" offset="0%" />
          <stop stopColor="#9B66FF" offset="100%" />
        </linearGradient>
        <filter x="-31.6%" y="-17.0%" width="163.2%" height="134.0%" filterUnits="objectBoundingBox" id="filter-8">
          <feGaussianBlur stdDeviation="10.0799999" in="SourceGraphic" />
        </filter>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-9">
          <stop stopColor="#F09A13" offset="0%" />
          <stop stopColor="#FBFF00" offset="100%" />
        </linearGradient>
        <rect id="path-10" x="0" y="0" width="23.8139998" height="23.0579998" />
        <radialGradient
          cx="50.5075786%"
          cy="40.3983801%"
          fx="50.5075786%"
          fy="40.3983801%"
          r="37.37968%"
          gradientTransform="translate(0.505076,0.403984),scale(0.263158,1.000000),rotate(90.000000),scale(1.000000,7.290929),translate(-0.505076,-0.403984)"
          id="radialGradient-12"
        >
          <stop stopColor="#747474" stopOpacity="0.2" offset="0%" />
          <stop stopColor="#8F8F8F" stopOpacity="0.0345052083" offset="79.8960093%" />
          <stop stopColor="#979797" stopOpacity="0" offset="100%" />
        </radialGradient>
        <filter x="-11.1%" y="-42.2%" width="122.2%" height="184.5%" filterUnits="objectBoundingBox" id="filter-13">
          <feGaussianBlur stdDeviation="4.43520006" in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Hero/Confirmation/Update" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Illustration" transform="translate(46.000000, 29.000000)">
          <g id="Group-27" transform="translate(6.300000, 0.000000)">
            <g id="Group-20">
              <g id="Group-12">
                <polygon
                  id="Rectangle"
                  fill="#E1E1E1"
                  transform="translate(8.914630, 124.145933) rotate(-49.000000) translate(-8.914630, -124.145933) "
                  points="5.33134448 115.448835 12.491962 115.451287 12.4979153 132.843031 5.33729772 132.84058"
                />
                <polygon
                  id="Rectangle"
                  fill="#0071F2"
                  transform="translate(149.170622, 33.075677) rotate(52.000000) translate(-149.170622, -33.075677) "
                  points="146.615408 26.9380541 151.729954 26.9363384 151.725836 39.2133 146.611291 39.2150156"
                />
                <polygon
                  id="Rectangle"
                  fill="#00CA9D"
                  transform="translate(117.914565, 50.290936) rotate(156.000000) translate(-117.914565, -50.290936) "
                  points="115.35491 44.1537313 120.471066 44.1550453 120.474219 56.4281408 115.358063 56.4268268"
                />
                <polygon
                  id="Rectangle"
                  fill="#9B66FF"
                  transform="translate(109.295103, 109.600305) rotate(-25.000000) translate(-109.295103, -109.600305) "
                  points="106.735423 103.463024 111.851532 103.464378 111.854783 115.737586 106.738673 115.736231"
                />
                <polygon
                  id="Rectangle"
                  fill="#00CA9D"
                  transform="translate(24.538746, 73.539276) rotate(-72.000000) translate(-24.538746, -73.539276) "
                  points="21.9807272 67.3990722 27.0942697 67.4001115 27.096764 79.6794796 21.9832216 79.6784403"
                />
                <polygon
                  id="Rectangle"
                  fill="#0071F2"
                  transform="translate(83.308555, 182.026624) rotate(-189.000000) translate(-83.308555, -182.026624) "
                  points="80.749572 175.890402 85.8662265 175.890948 85.8675379 188.162847 80.7508834 188.162301"
                />
                <polygon
                  id="Rectangle"
                  fill="#9B66FF"
                  transform="translate(259.511084, 148.018319) rotate(-206.000000) translate(-259.511084, -148.018319) "
                  points="256.951382 141.880962 262.067443 141.882355 262.070787 154.155677 256.954726 154.154284"
                />
                <polygon
                  id="Rectangle"
                  fill="#0071F2"
                  transform="translate(20.723779, 102.355689) rotate(-175.000000) translate(-20.723779, -102.355689) "
                  points="18.1657904 96.2199645 23.2825046 96.2196574 23.2817677 108.491413 18.1650535 108.49172"
                />
                <polygon
                  id="Rectangle"
                  fill="#00CA9D"
                  transform="translate(222.774546, 97.422718) rotate(-143.000000) translate(-222.774546, -97.422718) "
                  points="220.218855 91.2861848 225.334316 91.2844851 225.330237 103.55925 220.214776 103.56095"
                />
                <polygon
                  id="Rectangle"
                  fill="#0071F2"
                  transform="translate(158.315280, 105.793467) rotate(50.000000) translate(-158.315280, -105.793467) "
                  points="155.760036 99.6560017 160.874702 99.6542604 160.870523 111.930932 155.755857 111.932674"
                />
                <polygon
                  id="Rectangle"
                  fill="#9B66FF"
                  transform="translate(215.111106, 40.344446) rotate(50.000000) translate(-215.111106, -40.344446) "
                  points="212.555862 34.206981 217.670528 34.2052397 217.666349 46.4819116 212.551683 46.4836529"
                />
                <polygon
                  id="Rectangle"
                  fill="#00CA9D"
                  transform="translate(59.835487, 118.586212) rotate(55.000000) translate(-59.835487, -118.586212) "
                  points="56.258254 109.890893 63.4183695 109.888566 63.4127203 127.281531 56.2526048 127.283857"
                />
                <polygon
                  id="Rectangle"
                  fill="#0071F2"
                  transform="translate(241.325245, 64.987518) rotate(-276.000000) translate(-241.325245, -64.987518) "
                  points="237.746599 56.2893812 244.90514 56.2888665 244.90389 73.6856547 237.745349 73.6861694"
                />
                <polygon
                  id="Triangle"
                  fill="#9B66FF"
                  transform="translate(247.012626, 104.717330) rotate(-115.000000) translate(-247.012626, -104.717330) "
                  points="247.015199 99.8562471 252.893538 109.575297 241.131714 109.578413"
                />
                <polygon
                  id="Rectangle"
                  fill="#00CA9D"
                  transform="translate(194.614273, 7.722299) rotate(-244.000000) translate(-194.614273, -7.722299) "
                  points="191.032185 -0.975980452 198.191623 -0.974029788 198.19636 16.4205793 191.036922 16.4186286"
                />
                <polygon
                  id="Rectangle"
                  fill="#9B66FF"
                  transform="translate(78.975925, 20.505311) rotate(-244.000000) translate(-78.975925, -20.505311) "
                  points="75.3938369 11.8070314 82.5532749 11.8089821 82.5580122 29.2035911 75.3985742 29.2016405"
                />
                <polygon
                  id="Triangle"
                  fill="#E1E1E1"
                  transform="translate(83.223944, 49.349739) rotate(-214.000000) translate(-83.223944, -49.349739) "
                  points="83.2208286 44.4902013 89.1069241 54.2092766 77.340963 54.205506"
                />
                <polygon
                  id="Rectangle"
                  fill="#E1E1E1"
                  transform="translate(120.252576, 6.839779) rotate(-22.000000) translate(-120.252576, -6.839779) "
                  points="115.135103 1.72484976 125.367593 1.7273063 125.370049 11.9547083 115.13756 11.9522517"
                />
                <polygon
                  id="Triangle"
                  fill="#0071F2"
                  transform="translate(51.477831, 85.605424) rotate(14.000000) translate(-51.477831, -85.605424) "
                  points="51.4807367 76.6560821 62.222553 94.5512798 40.7331097 94.5547663"
                />
                <polygon
                  id="Triangle"
                  fill="#00CA9D"
                  transform="translate(210.474156, 141.037273) rotate(-115.000000) translate(-210.474156, -141.037273) "
                  points="210.478897 132.082659 221.213212 149.986198 199.7351 149.991886"
                />
                <polygon
                  id="Triangle"
                  fill="#E1E1E1"
                  transform="translate(232.431016, 214.284297) rotate(-115.000000) translate(-232.431016, -214.284297) "
                  points="232.43359 209.423214 238.311928 219.142264 226.550104 219.14538"
                />
                <polygon
                  id="Triangle"
                  fill="#9B66FF"
                  transform="translate(142.669830, 73.521393) rotate(-147.000000) translate(-142.669830, -73.521393) "
                  points="142.675484 64.5697527 153.412784 82.4662486 131.926877 82.4730329"
                />
                <polygon
                  id="Rectangle"
                  fill="#E1E1E1"
                  transform="translate(238.500923, 170.719518) rotate(-22.000000) translate(-238.500923, -170.719518) "
                  points="233.38345 165.604588 243.615939 165.607045 243.618396 175.834447 233.385906 175.83199"
                />
                <polygon
                  id="Rectangle"
                  fill="#E1E1E1"
                  transform="translate(35.621075, 37.069334) rotate(-51.000000) translate(-35.621075, -37.069334) "
                  points="32.0379112 28.3720442 39.1983586 28.3744656 39.204239 45.7666233 32.0437916 45.764202"
                />
              </g>
              <g id="Rectangle">
                <use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-2" />
                <use fill="url(#linearGradient-1)" fillRule="evenodd" xlinkHref="#path-2" />
              </g>
              <rect
                id="Rectangle"
                stroke="url(#linearGradient-4)"
                strokeWidth="2.51999998"
                x="114.4657"
                y="81.3161974"
                width="95.7061176"
                height="178.116897"
                rx="8.94136258"
              />
              <g id="Rectangle" transform="translate(80.799953, 50.999792)">
                <mask id="mask-6" fill="white">
                  <use xlinkHref="#path-5" />
                </mask>
                <use id="Mask" fill="#F2F2F2" xlinkHref="#path-5" />
                <path
                  d="M69.6358133,86.0511561 C69.6358133,85.400329 69.4054937,84.7495019 68.9448546,84.2900945 L65.4516748,80.8445392 C64.9910357,80.3851319 64.3768503,80.1171442 63.7242782,80.1171442 C63.0717061,80.1171442 62.4575207,80.3851319 61.9968816,80.8445392 L46.3351522,96.4261058 L37.6597825,87.7739337 C37.1991434,87.3145263 36.584958,87.0465387 35.9323859,87.0465387 C35.2798139,87.0465387 34.6656284,87.3145263 34.2049893,87.7739337 L30.7118095,91.2194889 C30.2511704,91.6788963 30.0208508,92.3297234 30.0208508,92.9805505 C30.0208508,93.6313776 30.2511704,94.2439208 30.7118095,94.7033282 L44.6077556,108.562117 C45.0683947,109.021524 45.7209668,109.289512 46.3351522,109.289512 C46.9877243,109.289512 47.6402963,109.021524 48.1009354,108.562117 L68.9448546,87.7739337 C69.4054937,87.3145263 69.6358133,86.7019832 69.6358133,86.0511561 Z M79.3092344,92.2531555 C79.3092344,108.485549 66.1042469,121.655227 49.8283321,121.655227 C33.5524172,121.655227 20.3474298,108.485549 20.3474298,92.2531555 C20.3474298,76.0207619 33.5524172,62.851084 49.8283321,62.851084 C66.1042469,62.851084 79.3092344,76.0207619 79.3092344,92.2531555 Z"
                  id="check-circle---FontAwesome"
                  fill="url(#linearGradient-7)"
                  mask="url(#mask-6)"
                />
                <rect
                  fill="url(#linearGradient-1)"
                  opacity="0.560000002"
                  filter="url(#filter-8)"
                  mask="url(#mask-6)"
                  x="33.6657474"
                  y="30.3164052"
                  width="95.7061176"
                  height="178.116897"
                  rx="8.94136258"
                />
              </g>
              <rect
                id="Shape"
                stroke="url(#linearGradient-9)"
                strokeWidth="2.51999998"
                x="80.7999527"
                y="50.9997922"
                width="99.4056503"
                height="185.14143"
                rx="7.55999994"
              />
            </g>
          </g>
          <g id="People/Person" transform="translate(26.560000, 142.379999)">
            <g id="Bottom/Standing/2" transform="translate(4.940000, 70.819999)">
              <path
                d="M37.1259219,81.1222616 C43.2657419,80.0918871 49.2389908,78.6880453 49.8399518,81.1222616 C50.4409127,83.5564779 48.1860172,84.2292152 46.2142526,85.27093 C44.8997428,85.9654066 42.1799997,86.9575059 38.0550231,88.2472279 C34.7174954,88.9748042 32.5298562,88.9748042 31.4921057,88.2472279 C30.8073159,87.1187222 30.8073159,85.5382123 31.4921057,83.5056981 C33.7486702,82.1683527 35.6266089,81.3738738 37.1259219,81.1222616 Z M1.05540446,80.6231212 C3.34582371,79.2485693 5.26120634,78.6575465 6.80155236,78.8500529 C13.1020362,79.6374625 16.5984763,78.0379452 17.9097666,81.1788724 C18.5058735,82.6067245 17.499192,83.0849515 15.8923852,83.697109 C14.3120111,84.2991961 11.2864996,85.166476 6.81585079,86.2989486 C3.49674102,86.6446118 1.38037942,86.3801172 0.466765971,85.505465 C-0.0556133727,84.2606511 0.140599457,82.6332032 1.05540446,80.6231212 Z"
                id="Shoes"
                fill="#1F2023"
              />
              <path
                d="M32.4113755,-4.26325641e-14 C37.3812354,15.6072655 41.690988,33.7582151 41.690988,42.0719122 C41.690988,54.9340308 41.690988,63.9314644 39.1859997,82.1243408 C39.1859997,83.1129805 38.5844116,83.7105608 37.3812354,83.9170816 C35.1458998,84.3007688 33.3306944,84.1975084 31.9356192,83.6073004 C29.0297028,63.2286121 30.3008289,55.8424896 26.115306,43.4233052 C25.8040227,42.4996727 25.4802678,41.6084887 25.145012,40.7399771 C24.9257426,42.1978251 24.6935054,43.6668899 24.4439998,45.1709997 C22.3259972,57.9390829 17.0740628,68.0717456 8.56799994,79.7734367 C8.0982172,80.57447 7.04378586,81.0710681 5.40470594,81.2632311 C3.41416882,81.4965984 1.90657332,81.2993138 0.881919444,80.6713773 C7.72752215,61.4117105 9.19398115,57.2428778 9.19398115,42.0719122 C9.19398115,26.9009467 7.72752215,24.982699 5.92199996,-4.26325641e-14 L5.92199996,-4.26325641e-14 Z"
                id="Pants"
                fill="#9B66FF"
              />
              <path
                d="M9.15397477,-4.26325641e-14 C15.0182769,22.6329797 20.9724103,29.9304606 25.145012,40.7399771 C24.9257426,42.1978251 24.6935054,43.6668899 24.4439998,45.1709997 C22.3259972,57.9390829 17.0740628,68.0717456 8.56799994,79.7734367 C8.0982172,80.57447 7.04378586,81.0710681 5.40470594,81.2632311 C3.41416882,81.4965984 1.90657332,81.2993138 0.881919444,80.6713773 C7.72752215,61.4117105 9.19398115,57.2428778 9.19398115,42.0719122 C9.19398115,26.9009467 7.72752215,24.982699 5.92199996,-4.26325641e-14 L5.92199996,-4.26325641e-14 L9.15397477,-4.26325641e-14 Z"
                id="Shadow"
                fillOpacity="0.15"
                fill="#000000"
              />
            </g>
            <g id="Body/4" transform="translate(0.000000, 26.080000)">
              <path
                d="M25.8299137,0.760421741 C34.3644449,0.760421741 30.3201403,1.14399986 35.3873336,1.39150865 C35.7696938,1.41018516 36.1336102,1.4543192 36.4796892,1.52504992 L36.533241,1.51642173 L36.533241,1.51642173 C47.9987487,4.12498178 57.1349791,20.0111052 57.1349791,26.6796305 C57.1349791,33.3481559 50.0789458,43.3948942 45.8962134,44.6732466 C44.7673814,43.8931294 43.8939331,42.5582554 43.2758687,40.6686246 C46.7022952,34.6929488 48.2112506,30.0299508 47.802735,26.6796305 C47.5077376,24.2602963 45.6678057,21.4672725 42.2829393,18.3005591 L42.2825113,35.9732469 C42.2825113,48.7478849 46.1887217,49.9512365 37.0200425,52.8954318 C27.8513634,55.8396271 7.53171297,59.3065841 9.30865604,49.0966293 C10.2894044,47.2400488 10.8221205,44.8960277 11.0848139,42.2794877 C5.84734442,37.9127561 0.236656104,31.2511486 0.236656104,27.0576305 C0.236656104,20.4856231 9.1103313,4.96091106 20.3420356,2.01596405 C22.0033724,1.20947241 23.8430387,0.760421741 25.8299137,0.760421741 Z M10.8031187,23.7353882 L10.7651824,23.7995047 C10.0919917,24.9519259 9.69323102,26.0379678 9.56890017,27.0576305 C9.39367731,28.4946691 9.93011991,30.2162034 11.178228,32.2222334 C11.0556115,29.3449516 10.8759077,26.4503673 10.8031187,23.7353882 Z"
                id="Jacket"
                fill="#E1E1E1"
              />
              <path
                d="M10.8623689,18.3821286 C10.7535934,20.0344718 10.7523357,21.8412187 10.8031187,23.7353882 C10.1067858,24.9118649 9.69552283,26.0191722 9.56890017,27.0576305 C9.39367731,28.4946691 9.93011991,30.2162034 11.178228,32.2222334 C11.3286668,35.7185181 11.3950817,39.1890852 11.0848139,42.2794877 C5.84734442,37.9127561 0.236656104,31.2511486 0.236656104,27.0576305 C0.236656104,20.4860138 9.1092761,4.96275717 20.3400322,2.01648949 C14.8674668,4.6767154 11.3338451,11.2202219 10.8623689,18.3821286 Z"
                id="Shadow"
                fillOpacity="0.15"
                fill="#000000"
              />
              <path
                d="M43.4939952,40.4559996 C40.5084214,41.9679996 38.9657828,42.8962462 38.9657828,44.5230489 C38.9657828,45.7981504 40.3461663,48.3602231 42.5772522,47.9255601 C44.8083382,47.490897 45.3108759,46.0929832 46.1887217,44.5230489 C45.2710381,42.0455191 44.7188038,41.2219664 43.4939952,40.4559996 Z"
                id="Hands"
                fill="#B28B67"
              />
            </g>
            <g id="Head/Back/1" transform="translate(17.540000, 0.000000)">
              <g id="Combined-Shape" transform="translate(0.882000, 4.166000)">
                <mask id="mask-11" fill="white">
                  <use xlinkHref="#path-10" />
                </mask>
                <g id="Mask" />
                <path
                  d="M14.1738925,2.52769041 C20.7279842,2.52769041 20.7591779,7.64800227 21.2126659,10.2181124 C21.7885252,13.4817538 20.7591779,18.5548534 19.0192323,19.5810643 C18.1005208,20.1229156 17.5949955,20.3682567 16.4321102,20.8898524 C14.886278,21.5832135 15.140502,25.0041317 15.1662647,26.7634155 L15.4979999,29.1059998 L6.42599995,29.1059998 L7.73736171,22.9916205 C8.0498085,21.3624319 9.06251838,17.5453252 8.43394037,16.8719413 C6.58085831,14.8867689 5.70414546,13.6026958 5.70414546,11.3399999 C5.70414546,8.11352631 7.07568745,2.52769041 14.1738925,2.52769041 Z"
                  id="Head"
                  fill="#B28B67"
                  mask="url(#mask-11)"
                />
              </g>
              <path
                d="M10.4280648,0.00799999237 C13.8213381,0.00799999237 16.5721296,2.75217097 16.5721296,6.13727743 L16.572855,6.11591343 C19.5499104,6.36732235 21.754995,8.38847132 22.0499998,10.3865823 C22.0499998,11.8529244 20.1052293,13.2656644 18.8476536,13.8111495 C18.8476536,12.9517216 17.5908278,11.6872588 16.5410042,12.6496347 C15.4197289,13.6775106 16.0808744,14.9481138 17.0710723,15.3029489 C14.0199013,16.7226638 15.0269258,21.5764425 11.6689529,21.9071481 C8.31097994,22.2378538 7.12371742,19.209457 6.61146201,16.3117134 C6.39347275,15.0785844 6.36101328,13.1124224 7.02112946,11.2387955 C5.37071856,10.1393898 4.28399997,8.26493652 4.28399997,6.13727743 C4.28399997,2.75217097 7.03479148,0.00799999237 10.4280648,0.00799999237 Z"
                id="Hair"
                fill="#262225"
              />
            </g>
          </g>
          <ellipse
            id="Oval"
            fill="url(#radialGradient-12)"
            filter="url(#filter-13)"
            cx="59.8499995"
            cy="302.029998"
            rx="59.8499995"
            ry="15.7499999"
          />
        </g>
      </g>
    </svg>
  );
}
