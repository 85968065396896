/** @jsx jsx */
import { useEffect } from 'react';
import { css, jsx } from '@emotion/core';

import Logger from '@cvent/nucleus-logging';

import InvitationTable from 'components/tables/InvitationTable';
import { useAppState } from 'components/AppContextProvider';
import useBreadcrumb from 'utils/useBreadcrumb';
import { translate } from 'translate';

import { codeToAlert } from '@universal-frontend/alerts';
const LOG = new Logger('components/pages/InvitationsView.js');

export default function InvitationsView() {
  const [{ clients, addTranslatedAlert, invitations, setInvitations }] = useAppState();
  const { authClient } = clients;

  useBreadcrumb({ link: '/developers/invitations', label: translate('title.invitations') });

  useEffect(() => {
    let isMounted = true;

    const fetchInvitations = async () => {
      try {
        const { data } = await authClient.getInvitations({ query: { filter: 'status eq "SENT"' } });
        if (isMounted) {
          setInvitations(data);
        }
      } catch (e) {
        LOG.error("Couldn't fetch invitations", e);
        addTranslatedAlert({
          alert: codeToAlert(e.status || 500),
          props: { entity: translate('title.invitations') },
        });
      }
    };

    fetchInvitations();

    return () => {
      isMounted = false;
    };
  }, []);

  const Container = css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
    margin-top: 1rem;
  `;

  return (
    <div css={Container}>
      <InvitationTable invitations={invitations} />
    </div>
  );
}
