/** @jsx jsx */
import { jsx } from '@emotion/core';

export default function EmailSent() {
  return (
    <svg width="344px" height="277px" viewBox="0 0 344 277" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Hero/Confirmation/Message Sent</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stopColor="#00A7FA" offset="0%" />
          <stop stopColor="#0076F3" offset="100%" />
        </linearGradient>
        <path
          d="M240.239509,130.605114 L115.931999,198.893907 C114.42919,199.719481 112.541663,199.170473 111.716089,197.667664 C111.464456,197.209612 111.332526,196.695449 111.332526,196.17283 L111.332526,59.5952438 C111.332526,57.8805987 112.72252,56.4906041 114.437166,56.4906041 C114.959785,56.4906041 115.473947,56.6225342 115.931999,56.8741669 L240.239509,125.16296 C241.742319,125.988534 242.291326,127.876061 241.465752,129.378871 C241.181778,129.895796 240.756435,130.321139 240.239509,130.605114 Z"
          id="path-2"
        />
        <filter x="-63.5%" y="-38.0%" width="224.5%" height="216.1%" filterUnits="objectBoundingBox" id="filter-3">
          <feOffset dx="0" dy="32" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="23" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0.560784314   0 0 0 0 0.964705882  0 0 0 0.324501812 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feOffset dx="0" dy="6" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0.560784314   0 0 0 0 0.964705882  0 0 0 0.402032382 0"
            type="matrix"
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
        <linearGradient x1="50%" y1="-2.48949813e-15%" x2="50%" y2="100%" id="linearGradient-4">
          <stop stopColor="#0096F7" offset="0%" />
          <stop stopColor="#00FFD6" offset="100%" />
        </linearGradient>
        <linearGradient x1="8.35366833%" y1="0%" x2="50%" y2="74.4382679%" id="linearGradient-5">
          <stop stopColor="#FFFFFF" stopOpacity="0" offset="0%" />
          <stop stopColor="#FFFFFF" offset="100%" />
        </linearGradient>
        <path
          d="M160.836073,93.5606414 L4.59634038,179.157557 C3.09258182,179.981401 1.20568749,179.430221 0.38184395,177.926462 C0.131320113,177.469182 1.69168577e-14,176.956171 0,176.434762 L0,5.2409296 C-5.85642019e-15,3.52628444 1.38999453,2.13628991 3.10463969,2.13628991 C3.62604874,2.13628991 4.13906015,2.26761002 4.59634038,2.51813386 L160.836073,88.1150499 C162.339832,88.9388935 162.891012,90.8257878 162.067168,92.3295464 C161.782621,92.8489302 161.355457,93.2760937 160.836073,93.5606414 Z"
          id="path-6"
        />
        <filter x="-25.7%" y="-23.4%" width="151.4%" height="146.8%" filterUnits="objectBoundingBox" id="filter-8">
          <feGaussianBlur stdDeviation="11.4659986" in="SourceGraphic" />
        </filter>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-9">
          <stop stopColor="#00A7FA" offset="0%" />
          <stop stopColor="#0076F3" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-10">
          <stop stopColor="#00A7FA" offset="0%" />
          <stop stopColor="#0076F3" offset="100%" />
        </linearGradient>
        <radialGradient
          cx="50.5075786%"
          cy="40.3983801%"
          fx="50.5075786%"
          fy="40.3983801%"
          r="37.37968%"
          gradientTransform="translate(0.505076,0.403984),scale(0.229971,1.000000),rotate(90.000000),scale(1.000000,7.290929),translate(-0.505076,-0.403984)"
          id="radialGradient-11"
        >
          <stop stopColor="#747474" stopOpacity="0.220023777" offset="0%" />
          <stop stopColor="#8F8F8F" stopOpacity="0.0345052083" offset="75.9302746%" />
          <stop stopColor="#979797" stopOpacity="0" offset="100%" />
        </radialGradient>
        <filter x="-7.0%" y="-30.3%" width="113.9%" height="160.5%" filterUnits="objectBoundingBox" id="filter-12">
          <feGaussianBlur stdDeviation="6.10464" in="SourceGraphic" />
        </filter>
        <rect id="path-13" x="0" y="0" width="21.1679996" height="20.4959997" />
      </defs>
      <g id="Illustrations" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Hero-pt1" transform="translate(-985.000000, -337.000000)">
          <g id="Hero/Confirmation/Message-Sent" transform="translate(984.000000, 286.000000)">
            <g id="Group" transform="translate(8.000000, 50.000000)">
              <g id="Group-10" transform="translate(46.190806, -0.000000)">
                <path
                  d="M234.05768,113.52278 L90.3669023,192.674195 C88.8650394,193.501489 86.9768844,192.954644 86.1495901,191.452781 C85.896852,190.993962 85.7643136,190.478655 85.7643136,189.954832 L85.7643136,31.6520011 C85.7643136,29.9373559 87.1543082,28.5473614 88.8689533,28.5473614 C89.3927766,28.5473614 89.9080839,28.6798998 90.3669023,28.9326378 L234.05768,108.084053 C235.559543,108.911348 236.106388,110.799503 235.279094,112.301365 C234.995694,112.815847 234.572161,113.23938 234.05768,113.52278 Z"
                  id="Triangle"
                  stroke="#E1E1E1"
                  strokeWidth="0.776159922"
                />
                <path
                  d="M288.293281,119.733914 L144.602504,198.88533 C143.100641,199.712624 141.212486,199.165778 140.385192,197.663915 C140.132454,197.205097 139.999915,196.68979 139.999915,196.165967 L139.999915,37.8631358 C139.999915,36.1484907 141.38991,34.7584961 143.104555,34.7584961 C143.628378,34.7584961 144.143686,34.8910345 144.602504,35.1437726 L288.293281,114.295188 C289.795144,115.122482 290.34199,117.010637 289.514696,118.5125 C289.231296,119.026982 288.807763,119.450515 288.293281,119.733914 Z"
                  id="Triangle"
                  stroke="#E1E1E1"
                  strokeWidth="0.776159922"
                />
                <g id="Triangle">
                  <use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-2" />
                  <use fill="url(#linearGradient-1)" fillRule="evenodd" xlinkHref="#path-2" />
                </g>
                <path
                  d="M240.239509,130.605114 L115.931999,198.893907 C114.42919,199.719481 112.541663,199.170473 111.716089,197.667664 C111.464456,197.209612 111.332526,196.695449 111.332526,196.17283 L111.332526,59.5952438 C111.332526,57.8805987 112.72252,56.4906041 114.437166,56.4906041 C114.959785,56.4906041 115.473947,56.6225342 115.931999,56.8741669 L240.239509,125.16296 C241.742319,125.988534 242.291326,127.876061 241.465752,129.378871 C241.181778,129.895796 240.756435,130.321139 240.239509,130.605114 Z"
                  id="Triangle"
                  stroke="url(#linearGradient-4)"
                  strokeWidth="2.64599968"
                />
                <g id="Triangle" transform="translate(63.295279, 0.000000)">
                  <mask id="mask-7" fill="white">
                    <use xlinkHref="#path-6" />
                  </mask>
                  <use
                    id="Mask"
                    stroke="url(#linearGradient-5)"
                    strokeWidth="1.55231984"
                    fill="#ECECEC"
                    xlinkHref="#path-6"
                  />
                  <polygon
                    fill="url(#linearGradient-1)"
                    opacity="0.5"
                    filter="url(#filter-8)"
                    mask="url(#mask-7)"
                    points="181.897464 127.884037 48.0372472 201.420645 48.0372472 54.347429"
                  />
                </g>
                <path
                  d="M235.297248,175.419567 C235.597026,175.634076 235.740042,175.97692 235.683378,176.333823 L232.055914,198.261784 C232.013421,198.533031 231.842504,198.7613 231.600175,198.90382 C231.471878,198.975072 231.315022,199.017735 231.158118,199.017562 C231.044007,199.017437 230.929864,198.988754 230.815706,198.945793 L224.351243,196.297137 L220.904001,200.505536 C220.733069,200.719527 220.476444,200.833474 220.205429,200.833176 C220.105581,200.833176 219.991454,200.818662 219.89156,200.775716 C219.53482,200.646816 219.291957,200.303862 219.291533,199.918339 L219.286057,194.935097 L231.593475,179.827584 L216.359849,193.018543 L210.723055,190.699207 C210.394843,190.570338 210.180554,190.270252 210.151634,189.913254 C210.136994,189.570552 210.3078,189.242331 210.607154,189.071317 L234.327269,175.389943 C234.469814,175.304428 234.626671,175.261937 234.783574,175.261937 C234.969005,175.261937 235.154499,175.31946 235.297248,175.419567 Z"
                  id="paper-plane---FontAwesome"
                  fill="url(#linearGradient-9)"
                  transform="translate(222.923164, 188.047557) rotate(32.000000) translate(-222.923164, -188.047557) "
                />
                <path
                  d="M43.4527697,28.5082478 C43.8869308,28.8189161 44.0940572,29.3154488 44.011993,29.8323429 L38.7584244,61.5900784 C38.6968818,61.9829187 38.4493482,62.3135164 38.0983877,62.519924 C37.9125783,62.6231164 37.6854074,62.6849046 37.4581682,62.6846546 C37.2929034,62.6844728 37.1275931,62.6429322 36.9622601,62.5807122 L27.5999344,58.7447287 L22.607377,64.839651 C22.3598206,65.1495692 21.9881566,65.3145953 21.5956526,65.3141644 C21.4510458,65.3141644 21.2857583,65.2931433 21.1410834,65.230946 C20.6244262,65.0442632 20.272693,64.5475714 20.2720794,63.9892277 L20.2641482,56.7721182 L38.0886846,34.8922726 L16.0261916,53.9964201 L7.86255862,50.6373809 C7.38721768,50.4507436 7.07686888,50.0161354 7.03498453,49.499105 C7.01378102,49.0027768 7.26115559,48.5274234 7.69470309,48.2797479 L42.0479731,28.4653437 C42.2544178,28.3414945 42.4815888,28.2799556 42.7088279,28.2799556 C42.9773833,28.2799556 43.2460296,28.3632648 43.4527697,28.5082478 Z"
                  id="paper-plane---FontAwesome"
                  fill="url(#linearGradient-10)"
                  transform="translate(25.531682, 46.797060) rotate(32.000000) translate(-25.531682, -46.797060) "
                />
                <path
                  d="M189.497249,12.9610335 C189.797027,13.1755425 189.940042,13.5183866 189.883379,13.8752896 L186.255915,35.8032498 C186.213421,36.0744967 186.042505,36.3027665 185.800175,36.4452861 C185.671878,36.516538 185.515022,36.5592013 185.358119,36.5590287 C185.244008,36.5589031 185.129865,36.5302203 185.015706,36.4872589 L178.551243,33.8386037 L175.104001,38.0470024 C174.93307,38.2609935 174.676444,38.3749401 174.40543,38.3746426 C174.305582,38.3746426 174.191455,38.360128 174.09156,38.3171822 C173.734821,38.1882822 173.491958,37.8453283 173.491534,37.4598053 L173.486058,32.476563 L185.793476,17.3690506 L170.559849,30.5600096 L164.923055,28.240673 C164.594844,28.1118044 164.380555,27.8117178 164.351635,27.4547206 C164.336995,27.1120178 164.507801,26.7837976 164.807155,26.6127835 L188.52727,12.9314092 C188.669815,12.8458943 188.826671,12.8034031 188.983575,12.8034031 C189.169006,12.8034031 189.3545,12.8609262 189.497249,12.9610335 Z"
                  id="paper-plane---FontAwesome"
                  fill="url(#linearGradient-9)"
                  transform="translate(177.123164, 25.589023) rotate(32.000000) translate(-177.123164, -25.589023) "
                />
                <path
                  d="M134.76843,80.9463982 C135.068208,81.1609072 135.211224,81.5037513 135.154561,81.8606543 L131.527097,103.788615 C131.484603,104.059861 131.313687,104.288131 131.071357,104.430651 C130.94306,104.501903 130.786204,104.544566 130.629301,104.544393 C130.515189,104.544268 130.401047,104.515585 130.286888,104.472624 L123.822425,101.823968 L120.375183,106.032367 C120.204251,106.246358 119.947626,106.360305 119.676611,106.360007 C119.576764,106.360007 119.462637,106.345493 119.362742,106.302547 C119.006003,106.173647 118.763139,105.830693 118.762716,105.44517 L118.757239,100.461928 L131.064657,85.3544153 L115.831031,98.5453743 L110.194237,96.2260377 C109.866025,96.0971691 109.651737,95.7970825 109.622817,95.4400853 C109.608176,95.0973825 109.778983,94.7691623 110.078337,94.5981482 L133.798452,80.9167739 C133.940997,80.831259 134.097853,80.7887678 134.254756,80.7887678 C134.440187,80.7887678 134.625681,80.8462909 134.76843,80.9463982 Z"
                  id="paper-plane---FontAwesome"
                  fill="url(#linearGradient-9)"
                  transform="translate(122.394346, 93.574388) rotate(32.000000) translate(-122.394346, -93.574388) "
                />
              </g>
              <ellipse
                id="Oval"
                fill="url(#radialGradient-11)"
                filter="url(#filter-12)"
                cx="131.564313"
                cy="255.255932"
                rx="131.564313"
                ry="30.2559318"
              />
              <g id="People/Person" transform="translate(66.599999, 102.199998)">
                <g id="Bottom/Standing/2" transform="translate(2.072000, 63.951999)">
                  <path
                    d="M40.705939,5.68434189e-14 L51.0719991,74.2559987 L43.9571016,74.2559987 L34.2021221,42.7482408 L30.4510992,30.7574422 C29.4830845,33.3784945 28.7715911,35.1433774 28.3380825,36.052091 C26.9277901,39.0083222 20.1673489,51.7429581 8.05675882,74.2559987 L8.05675882,74.2559987 L0.671999989,74.2559987 C10.1848533,50.7885152 15.1039426,38.0538793 15.4292679,36.052091 C15.7545933,34.0503027 16.8111614,22.032939 17.1210071,-2.84217094e-14 L17.1210071,-2.84217094e-14 L40.705939,5.68434189e-14 Z"
                    id="Pants"
                    fill="#DBB59D"
                  />
                  <path
                    d="M8.11908183,73.2479988 C11.7939271,75.5999987 16.5915664,77.5039987 22.5119996,78.9599987 L22.5119996,80.3039986 L8.488131,80.3039986 L3.69049174,79.6319986 L3.69049174,80.3039986 L0,80.3039986 L0.369049174,73.2479988 L8.11908183,73.2479988 Z M51.1270811,73.2479988 C54.8019264,75.5999987 59.5995656,77.5039987 65.5199989,78.9599987 L65.5199989,80.3039986 L51.4961303,80.3039986 L46.698491,79.6319986 L46.698491,80.3039986 L43.0079993,80.3039986 L43.3770484,73.2479988 L51.1270811,73.2479988 Z"
                    id="Shoes"
                    fill="#1F2023"
                  />
                  <path
                    d="M40.705939,0 L51.0719991,74.2559987 L43.9571016,74.2559987 L34.2021221,42.7482408 L30.4510992,30.7574422 C32.6322344,24.8516748 36.1157107,14.599194 40.6559993,0 L40.705939,0 Z"
                    id="Shadow"
                    fillOpacity="0.15"
                    fill="#000000"
                  />
                </g>
                <g id="Body/4" transform="translate(0.000000, 24.520000)">
                  <path
                    d="M13.4858497,24.7990929 L20.8433732,24.8267053 C15.239649,42.982064 12.2650124,52.487922 11.9194635,53.3442794 C11.1419785,55.2710834 12.7872413,58.2055726 13.490821,59.7825452 C11.1992854,60.8079391 11.443451,57.0104869 8.55274049,58.3553481 C5.91423401,59.5828752 3.90712869,61.8070719 0.858715697,59.9261075 C0.483931231,59.694854 0.0732285176,58.8241428 1.0655382,58.143728 C3.53774349,56.4485666 7.10034108,53.4817288 7.58170412,52.5051711 C8.23812862,51.1734603 10.2061772,41.9381009 13.4858497,24.7990929 L13.4858497,24.7990929 Z M60.0296884,33.2026715 L69.5478641,42.4880391 C72.3173023,43.6693516 74.5864625,44.973759 76.3553446,46.4012615 C76.7531396,46.8810937 77.1862044,47.8004769 75.3145982,47.3890661 C73.4429921,46.9776553 71.4558407,46.6290859 71.1040757,47.2377459 C70.7523108,47.846406 71.7888149,48.7790895 71.1387097,49.5756689 C70.7053062,50.1067218 69.2477363,48.4175464 66.7659997,44.5081426 L56.8683558,38.6727364 L60.0296884,33.2026715 Z"
                    id="Hands"
                    fill="#DBB59D"
                  />
                  <path
                    d="M30.7380508,0.549752436 L39.7879517,1.18258315 C40.5284142,1.23436134 41.0867032,1.87659936 41.034925,2.61706191 L40.7302288,6.97442161 C40.7119879,7.2352782 40.6204666,7.47352571 40.4771863,7.67045595 C47.4398241,21.5519164 67.0339239,31.1805361 68.347699,36.6846405 C68.8771156,38.9026492 67.2808183,40.0238697 68.1053897,40.6994542 L64.3288929,43.463999 C63.6893765,42.7178547 62.3246526,44.0827272 59.4746734,43.127999 C57.535975,42.4785452 47.3284829,33.4379254 40.9990044,21.6925249 C43.4290302,31.9706353 48.8464426,37.0211916 46.3337419,43.0000011 C43.4463964,49.8702536 21.0643762,55.5691277 17.9125614,48.3112933 C17.7588659,47.9573714 17.6179767,47.5959798 17.4894659,47.2275948 C16.3655428,50.2532602 13.1196721,50.879865 12.9351113,52.1048214 L12.926903,52.2145348 L7.98446741,50.8502437 C8.31970116,48.9967974 5.98971763,48.0942795 6.61444696,43.953519 C9.41357908,25.4006268 16.9343054,11.9521779 29.176626,3.60817209 L29.303572,1.7967258 C29.3553502,1.05626324 29.9975882,0.49797425 30.7380508,0.549752436 Z"
                    id="Shirt"
                    fill="#00CA9D"
                  />
                  <path
                    d="M68.347699,36.6846405 C68.8771156,38.9026492 67.2808183,40.0238697 68.1053897,40.6994542 L64.3288929,43.463999 C63.6893765,42.7178547 62.3246526,44.0827272 59.4746734,43.127999 C57.535975,42.4785452 47.3284829,33.4379254 40.9990044,21.6925249 C40.1113257,17.9403706 39.6222039,13.4904712 40.0630055,8.04389306 C40.2252565,7.94975374 40.3665892,7.82244039 40.4771251,7.67054014 C47.4406761,21.5523351 67.0339525,31.1806557 68.347699,36.6846405 Z"
                    id="Shadow"
                    fillOpacity="0.15"
                    fill="#000000"
                  />
                </g>
                <g id="Head/Back/1" transform="translate(22.903999, 0.448000)">
                  <g id="Combined-Shape" transform="translate(2.688000, 4.704000)">
                    <mask id="mask-14" fill="white">
                      <use xlinkHref="#path-13" />
                    </mask>
                    <g id="Mask" />
                    <path
                      d="M6.87762906,20.43713 C7.15532966,18.9890378 8.05558907,15.595863 7.49683581,14.997281 C5.84965178,13.2326833 5.07035148,12.091285 5.07035148,10.0799998 C5.07035148,7.21202331 6.28949989,2.2468359 12.5990154,2.2468359 C18.4248747,2.2468359 18.4526024,6.79822418 18.8557028,9.08276646 C19.3675778,11.983781 18.4526024,16.4932028 16.9059841,17.4053903 C16.0893516,17.8870359 15.6399958,18.1051169 14.6063201,18.5687575 C13.2334515,19.1845382 13.4578289,22.2205653 13.4810634,23.7855865 L13.7759998,25.8719996 L5.7119999,25.8719996 Z"
                      id="Head"
                      fill="#DBB59D"
                      mask="url(#mask-14)"
                    />
                  </g>
                  <path
                    d="M11.7599998,0 C13.3542652,0 14.7756406,0.789578295 15.6991809,2.02212658 C16.2498753,1.80108397 16.8476809,1.67999997 17.4719997,1.67999997 C20.2555148,1.67999997 22.5119996,4.08691712 22.5119996,7.05599988 C22.5119996,9.84914509 20.5150145,12.1447747 17.9609125,12.4070291 C18.2934153,13.1229327 18.4799997,13.930224 18.4799997,14.7839997 C18.4799997,16.8501513 17.3872715,18.6440678 15.7846274,19.5440828 C15.7897349,19.6364374 15.7919997,19.7299364 15.7919997,19.8239997 C15.7919997,22.7930824 13.5355149,25.1999996 10.7519998,25.1999996 C9.08678723,25.1999996 7.61019465,24.3385827 6.69250823,23.010721 C6.27143386,23.1241272 5.83029756,23.1839996 5.37599991,23.1839996 C2.40691714,23.1839996 0,20.6266501 0,17.4719997 C0,16.2896533 0.338102302,15.1912104 0.91718474,14.2798634 C0.338349227,13.3695912 0,12.2707779 0,11.0879998 C0,9.01201049 1.04233875,7.19468814 2.60129021,6.19461667 C3.25912042,3.57388635 5.15542904,1.67999997 7.39199987,1.67999997 C7.61566285,1.67999997 7.83592302,1.69893983 8.05188315,1.73565314 C8.97148119,0.669010073 10.2924956,0 11.7599998,0 Z"
                    id="Hair"
                    fill="#625140"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
