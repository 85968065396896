/** @jsx jsx */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { css, jsx } from '@emotion/core';

import { Button } from '@cvent/carina-button';
import { FolderOpenIcon, PlusIcon } from '@cvent/carina-icon';
import { tokens } from '@cvent/carina-tokens';
import Logger from '@cvent/nucleus-logging';
import Card from '@cvent/carina-card';
import { injectTestId } from '@cvent/nucleus-test-automation';

import { translate } from 'translate';
import { useAppState } from 'components/AppContextProvider';
import EmptyTable from 'components/EmptyTable';
import LoadingSpinner from '@cvent/carina-progress-indicator';
import testID from 'utils/generateTestId';
import WorkspaceTable from 'components/tables/WorkspaceTable';
import { codeToAlert } from '@universal-frontend/alerts';

const LOG = new Logger('components/pages/WorkspaceView.js');

export default function WorkspaceView() {
  const [{ clients, workspaces, setWorkspaces, addTranslatedAlert }] = useAppState();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const { authClient } = clients;
  const isAuthenticated = authClient.isAuthenticated();

  useEffect(() => {
    let isMounted = true;

    const fetchWorkspaces = async () => {
      if (isAuthenticated) {
        setIsLoading(true);

        try {
          const { data } = await clients.authClient.getWorkspaces();

          if (isMounted) {
            setWorkspaces(data || []);
          }
        } catch (error) {
          LOG.error('Failed to return workspaces', error);

          if (isMounted) {
            addTranslatedAlert({
              alert: codeToAlert(error.status || 500),
              props: { entity: translate('headings.workspaces') },
            });
          }
        } finally {
          if (isMounted) {
            setIsLoading(false);
          }
        }
      }
    };

    fetchWorkspaces();

    return () => {
      isMounted = false;
    };
  }, []);

  const Container = css`
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    min-height: 500px;
    padding: 1rem 0;
  `;

  const Content = css`
    flex-grow: 1;
    margin: 1rem 2rem;
    overflow: hidden;
  `;

  let cardContent = <LoadingSpinner />;
  if (!isLoading) {
    cardContent = (
      <section css={Container}>
        <div css={Content}>
          {workspaces && workspaces.length > 0 ? (
            <WorkspaceTable workspaces={workspaces} />
          ) : (
            <EmptyTable
              message="emptyWorkspaceState"
              testID={testID({ type: 'table', entity: 'workspace', intent: 'empty' })}
            >
              <FolderOpenIcon size="xl" color={tokens.default.font.color.brand.focus} />
            </EmptyTable>
          )}
        </div>
      </section>
    );
  }

  const WorkspacePage = css`
    display: flex;
    flex-direction: column;
    > div:nth-of-type(2) {
      flex-grow: 1;
    }
  `;

  const WorkspaceHeader = css`
    display: flex;
    justify-content: flex-end;
    margin: 1.625rem 0;
  `;

  return (
    <div css={WorkspacePage} {...injectTestId('app.pages.workspaces')}>
      <div css={WorkspaceHeader}>
        <Button
          appearance="primary"
          text={translate('dialogues.createWorkspace.button')}
          onClick={() => {
            history.push('/workspaces/create');
          }}
          testID={testID({ type: 'button', entity: 'workspace', intent: 'create' })}
          disabled={isLoading}
          icon={PlusIcon}
        />
      </div>
      <Card height="100%" bodyContent={cardContent} />
    </div>
  );
}
