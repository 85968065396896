import { handleActions, combineActions } from 'redux-actions';
import actions from './actions';
export { default as actions } from './actions';

const defaultState = {};

const { pushBreadcrumb, popBreadcrumb, ...restActions } = actions;

export default handleActions(
  {
    [combineActions(...Object.values(restActions))]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    PUSH_BREADCRUMB: (state, { payload }) => ({
      ...state,
      breadcrumbs: [...state.breadcrumbs, payload],
    }),
    POP_BREADCRUMB: (state) => ({
      ...state,
      breadcrumbs: state.breadcrumbs.slice(0, -1),
    }),
  },
  defaultState
);
