/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import { DismissButton } from '@cvent/carina-scroll-view-with-bars';

/**
 * The generic header for all forms
 * @param {func} onDismiss a function to be executed when the X is clicked. If it is falsey
 *                         it will not render the button
 */
export default function DialogueFormHeader({ onDismiss, testID, title, hasPadding = false }) {
  const Header = css`
    display: flex;
    align-items: center;
    padding: ${hasPadding ? '1rem 1.5rem' : '1rem 0'};
    button {
      margin-left: auto;
    }
  `;

  return (
    <header css={Header}>
      <h2>{title}</h2>
      {onDismiss && <DismissButton testID={`${testID}.button.dismiss`} onClick={onDismiss} />}
    </header>
  );
}
