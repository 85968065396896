import initalizeApplicationLogger from '@universal-frontend/logging';
import { logging } from 'config.json';
initalizeApplicationLogger(logging);

import React from 'react';
import ReactDOM from 'react-dom';

// Components
import { ThemeProvider } from '@cvent/carina-theme-provider';
import { getDefaultTheme } from '@cvent/carina-tokens';
import { Breakpoints } from '@cvent/carina-layout';
import AppContextProvider from 'components/AppContextProvider';
import App from 'components/containers/App';
import { HashRouter } from 'react-router-dom';

// Clients
import UniversalAuthClient from 'clients/UniversalAuthClient';

// Reducers
import reducers from 'reducers';

// Configs
import { authServiceEndpoint, ignoreEnvironment } from 'config.json';
import isProd from 'utils/isProd';

// Utils
import Logger from '@cvent/nucleus-logging';
import { storage } from 'utils/storage';

// Translations
import { translate, setLocale } from 'translate';

// Constants
import { storageMap } from 'enums';

// Automation
import { manageAutomationSupport } from '@universal-frontend/automation-support';

// Disable automation ids in prod environments
manageAutomationSupport({ isProduction: isProd, location: window.location });

window._info_ = {
  // eslint-disable-next-line no-undef
  version: VERSION,
};

const fullToken = storage.get(storageMap.FULL_TOKEN);
const LOG = new Logger('main.js');

let { returnUrl, relaunchUrl, logoutUrl } = storage.get('app-state') || {};

const urlParams = new URLSearchParams(window.location.search);
const environment = ignoreEnvironment ? null : urlParams.get('environment');

returnUrl = urlParams.get('returnUrl') || returnUrl;
relaunchUrl = urlParams.get('relaunchUrl') || relaunchUrl;
logoutUrl = urlParams.get('logoutUrl') || logoutUrl;
const sessionTimeoutDuration = urlParams.get('sessionTimeoutDuration');
const locale = urlParams.get('locale') || null;

if (locale) {
  setLocale(locale);
}

// Remove unwanted params from url
if (window.location.href.indexOf('#') === -1) {
  window.history.replaceState(
    null,
    null,
    environment ? `${window.location.pathname}?environment=${environment}` : window.location.pathname
  );
}

const authClient = new UniversalAuthClient({
  endpoint: authServiceEndpoint,
  fullToken,
  environment,
  onUnauthorized: () => {
    // We will need to set the onUnauthorized when we can show a graceful modal
    LOG.error('onUnauthorized called before it was properly set');
  },
});

const initialContext = {
  developerWorkspaces: {},
  breadcrumbs: [],
  developers: [],
  invitations: [],
  workspaces: [],
  clients: { authClient },
  translate,
  fullToken,
  alert: false,
};

ReactDOM.render(
  <AppContextProvider reducers={reducers} initialContext={initialContext}>
    <ThemeProvider theme={getDefaultTheme()}>
      <HashRouter>
        <Breakpoints>
          <App
            initialToken={urlParams.get('token')}
            environment={environment}
            sessionTimeoutDuration={sessionTimeoutDuration}
            returnUrl={returnUrl}
            relaunchUrl={relaunchUrl}
            logoutUrl={logoutUrl}
          />
        </Breakpoints>
      </HashRouter>
    </ThemeProvider>
  </AppContextProvider>,
  document.getElementById('react-mount')
);

LOG.info('Application has started');
