import { useEffect, useState } from 'react';
import { useAppState } from 'components/AppContextProvider';
import Logger from '@cvent/nucleus-logging';
import { codeToAlert } from '@universal-frontend/alerts';

const LOG = new Logger('utils/useFetchData');

/**
 * A hook to fetch data and save it to the app state. This hook requires that the setter action is set in
 * reducers/data/actions.js and the fetching function is set as getDataType in the authClient
 * @param {string} dataType A string value of the dataType example: 'workspaces'
 * @param {object} payload  The payload that should be passed to the method
 * @param {function} errorHandler A function to run when the fetch fails
 *
 * @return an Array where the first element is the data of the specified dataType and the second element is the loading
 * state
 */
export default function useFetchData(dataType, payload, errorHandler = () => {}) {
  const [context] = useAppState();
  const { addTranslatedAlert, clients } = context;
  const { authClient } = clients;
  const capitalizedDataType = `${dataType.charAt(0).toUpperCase()}${dataType.substring(1)}`;
  const data = context[dataType];
  const setData = context[`set${capitalizedDataType}`];
  const fetcher = authClient[`get${capitalizedDataType}`];
  const [isLoading, setIsLoading] = useState(false);

  if (typeof setData !== 'function') {
    LOG.error(
      'Unknown data type passed to useFetchData. You should define the action for your datatype in src/reducers/data/actions',
      dataType
    );
    throw new Error('Unknown data type passed to useFetchData');
  }

  if (typeof fetcher !== 'function') {
    LOG.error(
      'Unknown data type passed to useFetchData. You should define the fetcher for your datatype in src/clients/UniversalAuthClient.js',
      dataType
    );
    throw new Error('Unknown data type passed to useFetchData');
  }

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    const asyncFetch = async () => {
      try {
        const { data: _data } = await authClient[`get${capitalizedDataType}`](payload);
        setData(_data);
      } catch (e) {
        LOG.error(`Failed to fetch dataType: ${dataType}`, e);
        addTranslatedAlert({
          alert: codeToAlert(e.status || 500),
          props: { entity: capitalizedDataType },
        });
        errorHandler(e, dataType);
      }

      if (isMounted) {
        setIsLoading(false);
      }
    };

    asyncFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  return [data, isLoading];
}
