/** @jsx jsx */
import { useState } from 'react';
import { jsx } from '@emotion/core';

import { Form } from '@cvent/carina-layout';
import Button from '@cvent/carina-button';
import FormElement from '@cvent/carina-form-element';
import LoadingSpinner from '@cvent/carina-progress-indicator';
import Logger from '@cvent/nucleus-logging';
import Textbox from '@cvent/carina-textbox';

import { storageMap } from 'enums';
import { apiRequest } from '@universal-frontend/api-request';
import { coreAuthEndpoint } from 'config.json';
import { storage } from 'utils/storage';
import { translate } from 'translate';
import { useAppState } from 'components/AppContextProvider';
import testID from 'utils/generateTestId';

import { codeToAlert } from '@universal-frontend/alerts';

const LOG = new Logger('components/pages/DeveloperLogin.js');

const DeveloperLoginForm = () => {
  const [accountName, setAccountName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [{ addTranslatedAlert }] = useAppState();

  const handleFormSubmit = async () => {
    setIsLoading(true);
    try {
      const result = await apiRequest({
        endpoint: coreAuthEndpoint + 'v1/normandy/login',
        method: 'POST',
        body: {
          Account: accountName,
        },
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Basic ' + window.btoa(username + ':' + password),
        },
      });
      const initialToken = result.BearerToken.replace(/-/g, '');

      const singleResult = await apiRequest({
        endpoint: coreAuthEndpoint + 'v1/exchange-token/single',
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + initialToken,
        },
      });
      const singleToken = singleResult.token;

      const environment = new URLSearchParams(window.location.search).get('environment');
      let url = `${window.location.pathname}?token=${singleToken}`;
      url += environment ? `&environment=${environment}` : '';
      // add logout URL, even for the dev login flow in lower regions
      url += `&logoutUrl=${window.location.pathname}%23%2Fdev-login`;
      window.location.href = url;
    } catch (error) {
      LOG.error('An error occurred', error.body);
      storage.remove(storageMap.INITIAL_TOKEN, null);
      addTranslatedAlert({ alert: codeToAlert(error.status || 401) });
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Form>
      <FormElement>
        <FormElement.Label label={translate('headings.accountName')} labelFor="accountName" required />
        <Textbox
          id="accountName"
          testID={testID({
            type: 'textbox',
            entity: 'accountName',
            intent: 'input',
          })}
          value={accountName}
          onChange={({ target }) => setAccountName(target.value)}
        />
      </FormElement>
      <FormElement>
        <FormElement.Label label={translate('headings.username')} labelFor="username" required />
        <Textbox
          id="username"
          testID={testID({
            type: 'textbox',
            entity: 'username',
            intent: 'input',
          })}
          value={username}
          onChange={({ target }) => setUsername(target.value)}
        />
      </FormElement>
      <FormElement>
        <FormElement.Label label={translate('headings.password')} labelFor="password" required />
        <Textbox
          id="password"
          type="password"
          testID={testID({
            type: 'textbox',
            entity: 'password',
            intent: 'input',
          })}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
        />
      </FormElement>
      <Button
        appearance="primary"
        size="l"
        text={translate('buttons.login')}
        type="submit"
        onClick={() => handleFormSubmit(null)}
        testID={testID({
          type: 'button',
          entity: 'login',
          intent: 'login',
        })}
        disabled={!username || !password || !accountName}
      />
    </Form>
  );
};

export default DeveloperLoginForm;
