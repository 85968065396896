/** @jsx jsx */
import { jsx, css } from '@emotion/core';

/**
 * The Cvent logo. Component created to tuck the log data src away
 * @param {*} props - Properties to drop
 */
export default function CventLogo(props) {
  const whiteFill = css`
    fill: #fff;
  `;

  return (
    <svg
      version="1.1"
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 572 115"
      style={{ enableBackground: 'new 0 0 572 115' }}
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path css={whiteFill} d="M207.6,28.4h26.3L179.6,109H171l-55.6-80.6H142l33.4,50.7L207.6,28.4z" />
        <path
          css={whiteFill}
          d="M349.4,70.8l-88.8,0.1c0,7.1,5.9,14.1,13.7,17.3c10.9,4.7,17.4,5.1,29.9,5.1c16.5,0,29.9-3.1,43.3-9.8V100
        c-8.7,5.9-25.3,10.7-47.4,10.7c-14.8,0-27.7-1.6-36.8-5.8c-8.9-4.2-13.5-7.4-18.2-14.4c-4.4-6.6-7.3-14.2-7.3-22.5
        c0-12.5,5.1-22,14.4-30.3c10.4-9.3,25.9-11.9,44.4-11.9c17.8,0,31.2,5.1,40.8,14c9.9,9.1,12,19.6,12,29L349.4,70.8L349.4,70.8z
         M262.6,56.1l63.3-0.1c-1.3-4.9-5-8.8-10-11.7c-5.1-2.9-11.5-4.9-20.1-4.9s-15.2,1.4-21.4,4.5C268.9,46.7,264.6,51.1,262.6,56.1z"
        />
        <path
          css={whiteFill}
          d="M398.1,28.4v10.1c11.3-7.9,25.2-11.9,39.1-11.9c7.7,0,14.9,1.3,21.5,3.9s10.8,6,14,10.7
        c3.4,5,5.2,11.7,5.2,21.5v46h-23.1V66.5c0-8.2-3.2-14.1-7-17.7c-3.9-3.5-10.3-5.8-19.3-5.8c-11.6,0-22,4.4-30.4,11.9v53.8h-23.5
        V28.4H398.1z"
        />
        <path
          css={whiteFill}
          d="M492.2,41.3l40-38.2h1.4v23.1H565v18.1h-31.4v32.6c0,10.1,6.1,15.2,16,15.2c7,0,12.6-1.7,19.4-6.2v18.7
        c-8.1,4-14.1,5.7-24.6,5.7c-9.5,0-14.2-1.6-21.2-6.2c-2.2-1.4-4-3.5-5.5-5.3c-1.4-1.8-2.8-4.4-3.8-7.2c-1-2.9-1.5-8.3-1.5-16.3
        V44.2h-20.2V41.3z"
        />
        <path
          css={whiteFill}
          d="M106.7,87.2v19.2c-12.9,3-25.6,4.5-37.9,4.5c-20.4,0-35.4-3.8-47-11.4C10.3,92,5,81.9,5,69.1
        s4.9-23.6,16.1-31.4c11.2-7.9,25.7-11.5,45-11.5c6.7,0,12.7,0.4,18.1,1.2c5.3,0.8,11.9,2.3,19.8,4.5v19.6
        c-13.1-5.2-23.7-7.2-36.4-7.2c-10.8,0-22.3,2.7-28.9,6.9c-7.4,4.7-9.9,11.4-9.9,17.4c0,7.1,4.1,13.1,10.9,17.4
        c7.2,4.5,20.8,6.6,32.8,6.6C82.4,92.6,93.8,90.8,106.7,87.2z"
        />
      </g>
    </svg>
  );
}
