/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { ThemeContext } from '@cvent/carina-theme-provider';
import { DataTable, Columns } from '@cvent/carina-data-table';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { TrashIcon, EditPencilIcon } from '@cvent/carina-icon';

import { onSort, onDialogConfirm } from './helpers';
import { sort, sortDirections } from 'utils/sorting';
import { translate } from 'translate';
import { useAppState } from 'components/AppContextProvider';
import ConfirmationDialog from 'components/ConfirmationDialog';
import dialogs from './dialogs.json';
import { workspaceActions } from 'enums';

/**
 * A table to list workspaces
 * @param {array} workspaces A list of workspaces to display
 */
const WorkspaceTable = ({ workspaces = [] }) => {
  const [ctx] = useAppState();
  const theme = useContext(ThemeContext);

  const [sortDirection, setSortDirection] = useState(sortDirections.ASC);
  const [sortBy, setSortBy] = useState('created');
  const [isLoading, setIsLoading] = useState();
  const [activeDialog, setActiveDialog] = useState();
  const [selectedWorkspace, setSelectedWorkspace] = useState();

  const history = useHistory();

  const sortedWorkspaces = sort({
    items: workspaces,
    getter: (a) => a[sortBy],
    order: sortDirection,
  });

  const boundOnSort = onSort.bind(null, { setSortDirection, setSortBy });

  const tableProps = {
    tableData: sortedWorkspaces,
    sort: boundOnSort,
    rowCount: sortedWorkspaces.length,
    sortBy,
    sortDirection,
    dynamicHeightDataKey: 'name',
    testID: 'workspace.table.row',
    fluidOverflow: true,
  };

  const { dialoguesKey } = dialogs[activeDialog] || {};

  const boundOnDialogClick = onDialogConfirm.bind(null, {
    ...ctx,
    setIsLoading,
    setActiveDialog,
  });

  const Container = css`
    height: 100%;
    min-height: 300px;
    box-sizing: content-box;
    > div {
      box-sizing: content-box;
    }
  `;

  const NameCell = css`
    cursor: pointer;
  `;

  const ActionCell = css`
    display: flex;
    align-items: center;
    margin-top: -3px;
    justify-content: space-evenly;

    svg {
      cursor: pointer;
    }
  `;

  return (
    <Fragment>
      <div css={Container} {...injectTestId('workspaces.table')}>
        <DataTable {...tableProps}>
          <Columns.BasicColumn
            dataKey="name"
            label={translate('headings.workspace.name')}
            width={200}
            linkProps={{ testID: (cellInfo) => `workspaces.table.name.${cellInfo.rowData.id}` }}
            flexGrow={10}
            cellRenderer={(cell) => (
              <a
                {...injectTestId(`workspaces.table.name.${cell.rowData.id}`)}
                css={NameCell}
                onClick={(event) => {
                  event.preventDefault();
                  history.push(`/workspaces/${cell.rowData.id}`);
                }}
              >
                {cell.rowData.name}
              </a>
            )}
          />
          <Columns.BasicColumn
            dataKey={'id'}
            disableSort
            width={50}
            alignment={'center'}
            cellRenderer={(cell) => (
              <div css={ActionCell} title={''}>
                <i
                  {...injectTestId('workspaces.table.edit')}
                  onClick={(event) => {
                    event.preventDefault();
                    history.push(`/workspaces/${cell.rowData.id}`);
                  }}
                >
                  <EditPencilIcon
                    size="l"
                    color={theme.font.color.soft}
                    title={translate('dialogues.editWorkspace.title')}
                  />
                </i>
                <i
                  {...injectTestId('workspaces.table.delete')}
                  onClick={(event) => {
                    event.preventDefault();
                    setActiveDialog(workspaceActions.DELETE_WORKSPACE);
                    setSelectedWorkspace(cell.rowData);
                  }}
                >
                  <TrashIcon
                    size="l"
                    color={theme.font.color.soft}
                    title={translate('dialogues.deleteWorkspace.title')}
                  />
                </i>
              </div>
            )}
            label={translate('headings.workspace.actions')}
          />
        </DataTable>
      </div>
      {dialoguesKey ? (
        <ConfirmationDialog
          isOpen
          isLoading={isLoading}
          onCancelClick={() => setActiveDialog(null)}
          onConfirmClick={(e) => boundOnDialogClick(activeDialog, selectedWorkspace, e)}
          title={translate(`dialogues.${dialoguesKey}.title`)}
          description={translate(`dialogues.${dialoguesKey}.description`, {
            workspace: selectedWorkspace.name,
          })}
          confirmBtnText={translate(`dialogues.${dialoguesKey}.button`)}
        />
      ) : null}
    </Fragment>
  );
};

export default WorkspaceTable;
