/** @jsx jsx */
import { jsx } from '@emotion/core';

export default function ComeBackLater() {
  return (
    <svg
      width="252px"
      height="339px"
      viewBox="0 0 252 339"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Hero/Error/Come Back Later</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient x1="41.316643%" y1="0%" x2="61.8590825%" y2="100%" id="linearGradient-1">
          <stop stopColor="#EEC200" offset="0%" />
          <stop stopColor="#F27126" offset="100%" />
        </linearGradient>
        <path
          d="M30,33.75 L121.985399,59.7843117 C123.029343,60.0797756 123.75,61.0327059 123.75,62.1176566 L123.75,123.760051 L123.75,123.760051 L123.75,210.875717 C123.75,212.083719 122.860903,213.107509 121.664821,213.276789 L30,226.25 L30,226.25 L30,33.75 Z"
          id="path-2"
        />
        <filter x="-78.9%" y="-26.0%" width="257.9%" height="179.0%" filterUnits="objectBoundingBox" id="filter-3">
          <feOffset dx="0" dy="28" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.949019608   0 0 0 0 0.443137255   0 0 0 0 0.149019608  0 0 0 0.19 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur stdDeviation="10.5" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
          <feColorMatrix
            values="0 0 0 0 0.949019608   0 0 0 0 0.443137255   0 0 0 0 0.149019608  0 0 0 0.402032382 0"
            type="matrix"
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
          <stop stopColor="#F09A13" offset="0%" />
          <stop stopColor="#FBFF00" offset="100%" />
        </linearGradient>
        <polygon id="path-5" points="26.25 24.495497 105 0 103.514151 123.807497 105 261.25 26.25 231.019325" />
        <polygon id="path-7" points="0 24.6127004 80 0 78.490566 124.399877 80 262.5 0 232.12468" />
        <linearGradient x1="41.7666085%" y1="0%" x2="61.2445531%" y2="100%" id="linearGradient-9">
          <stop stopColor="#EEC200" offset="0%" />
          <stop stopColor="#F27126" offset="100%" />
        </linearGradient>
        <polygon id="path-10" points="-5 8.75 110 40.5120249 110 116.495889 110 223.297998 -5 251.25" />
        <filter x="-41.1%" y="-9.6%" width="182.2%" height="140.6%" filterUnits="objectBoundingBox" id="filter-11">
          <feGaussianBlur stdDeviation="2.425" in="SourceGraphic" />
        </filter>
        <filter x="-70.7%" y="-23.6%" width="241.3%" height="168.7%" filterUnits="objectBoundingBox" id="filter-12">
          <feOffset dx="0" dy="28" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.949019608   0 0 0 0 0.443137255   0 0 0 0 0.149019608  0 0 0 0.19 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur stdDeviation="10.5" in="shadowOffsetOuter2" result="shadowBlurOuter2" />
          <feColorMatrix
            values="0 0 0 0 0.949019608   0 0 0 0 0.443137255   0 0 0 0 0.149019608  0 0 0 0.402032382 0"
            type="matrix"
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-13">
          <stop stopColor="#F09A13" offset="0%" />
          <stop stopColor="#FBFF00" offset="100%" />
        </linearGradient>
        <filter x="-13.7%" y="-6.7%" width="127.4%" height="113.3%" filterUnits="objectBoundingBox" id="filter-14">
          <feGaussianBlur stdDeviation="4.85" in="SourceGraphic" />
        </filter>
        <linearGradient x1="45.4568348%" y1="26.2596298%" x2="52.1232451%" y2="60.1403897%" id="linearGradient-15">
          <stop stopColor="#FFFFFF" stopOpacity="0" offset="0%" />
          <stop stopColor="#FFFFFF" stopOpacity="0.4" offset="97.6810874%" />
        </linearGradient>
        <linearGradient x1="3.0565022%" y1="3.81578571%" x2="3.0565022%" y2="100%" id="linearGradient-16">
          <stop stopColor="#E1E1E1" offset="0%" />
          <stop stopColor="#FFFFFF" offset="100%" />
        </linearGradient>
        <path
          d="M26.25,24.495497 L103.40744,0.495372088 C104.046866,0.296476216 104.726459,0.653596189 104.925355,1.29302187 C104.963075,1.41428475 104.981509,1.54072054 104.979985,1.66770522 L103.514151,123.807497 L103.514151,123.807497 L104.980706,259.465286 C104.987945,260.134892 104.45099,260.683583 103.781384,260.690822 C103.628429,260.692476 103.476542,260.665172 103.333738,260.610353 L26.25,231.019325 L26.25,231.019325 L26.25,24.495497 Z"
          id="path-17"
        />
        <filter x="-58.5%" y="-15.2%" width="278.0%" height="160.6%" filterUnits="objectBoundingBox" id="filter-18">
          <feMorphology radius="0.60625" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
          <feOffset dx="24" dy="49" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="15" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.08 0" type="matrix" in="shadowBlurOuter1" />
        </filter>
        <radialGradient
          cx="50.5075786%"
          cy="40.3983801%"
          fx="50.5075786%"
          fy="40.3983801%"
          r="37.37968%"
          gradientTransform="translate(0.505076,0.403984),scale(0.310680,1.000000),rotate(90.000000),scale(1.000000,7.290929),translate(-0.505076,-0.403984)"
          id="radialGradient-19"
        >
          <stop stopColor="#747474" stopOpacity="0.220023777" offset="0%" />
          <stop stopColor="#8F8F8F" stopOpacity="0.0345052083" offset="75.9302746%" />
          <stop stopColor="#979797" stopOpacity="0" offset="100%" />
        </radialGradient>
        <filter x="-6.5%" y="-20.8%" width="112.9%" height="141.6%" filterUnits="objectBoundingBox" id="filter-20">
          <feGaussianBlur stdDeviation="2.77199977" in="SourceGraphic" />
        </filter>
        <rect id="path-21" x="15.1849994" y="0" width="27.689999" height="4.28999984" />
        <pattern
          id="pattern-22"
          width="12.7949089"
          height="12.7949089"
          x="2.39009047"
          y="-12.7949089"
          patternUnits="userSpaceOnUse"
        >
          <use xlinkHref="#image-23" transform="scale(0.127949089,0.127949089)" />
        </pattern>
        <image
          id="image-23"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAIAAAD/gAIDAAAABGdBTUEAALGOfPtRkwAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAZKADAAQAAAABAAAAZAAAAAAvu95BAAADN0lEQVR4Ae2WUZIDIQhEc4zc/yo52C6MCkIlKnxK52eYaBfVD6aqX+/3+/P5/H370f84FTBE4wVSDcfJZryE3FycKAtS/gILpH7tjYcFUr9I0f8GFkgtSBlYILUmpbBAakuqwwKpE1IMC6QOSTEsZHSBtd0bJPjOakuKtspEh3PGNffxC6wTxnRHyM7F3VoP626381xbHfJrYIWUrnEFrcKq4HYecMJvh5VQSuM6WoZVx20bcNovErx8IVysOSLBK6w1KTpFgu+wtqSQ4AOkeLN0C0d1wpjujOvmebfWw7rbrRns8xLya2CFlK5xBa3CquB2HnDCb4eVUErjOlqGVcdtG3DaLxK8fCFcrDkiwSusNSk6RYLvsLakkOADpHizdAtHdcKY7ozr5nm31sO6260Z7PMS8mtghZSucQWtwqrgdh5wwm+HlVBK4zpahlXHbRtw2i8SvHwhXKw5IsErrDUpOkWC77C2pJDgA6R4s3QLR3XCmO6M6+Z5t9bDututGezzEvJrYIWUrnEFrcKq4HYecMJvh5VQSuM6WoZVx20bcNovErx8IVysOSLBK6w1KTpFgu+wtqSQ4AOkeLN0C0d1wpjujOvmebfWw7rbrRns8xLya2CFlK5xBa3CquB2HnDCb4eVUErjOlqGVcdtG3DaLxK8fCFcrDkiwSusNSk6RYLvsLakkOADpHizdAtHdcKY7ozr5nm31sO6260Z7PMS8mtghZSucQWtwqrgdh5wwm+HlVBK4zpahlXHbRtw2i8SvHwhXKw5IsErrDUpOkWC77C2pJDgA6R4s3QLR3XCmO6M6+Z5t9bDututGezzEvJrYIWUrnEFrcKq4HYecMJvh5VQSuM6WoZVx20bcNovErx8IVysOSLBK6w1KTpFgu+wtqSQ4AOkeLN0C0d1wpjujOvmebfWw7rbrRns8xLya2CFlK5xBa3CquB2HnDCb4eVUErjOlqGVcdtG3DaLxK8fCFcrDkiwSusNSk6RYLvsLakkOADpHizdAtHdcKY7ozr5nm31sO6260Z7PMS8mtghZSucQWtwqrgdh5wwm+HlVBK4zpahlXHbRtw2i8SvHwhXKw5/gPew69lYQAXzgAAAABJRU5ErkJggg=="
        />
        <rect id="path-24" x="0" y="0" width="24.5699991" height="23.7899991" />
      </defs>
      <g id="Developer-Management" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Session_timeout" transform="translate(-517.000000, -271.000000)">
          <g id="sessiontimeout_illustration" transform="translate(370.000000, 199.000000)">
            <g id="Group" transform="translate(44.000000, 30.000000)">
              <g id="Hero/Error/Come-Back-Later" transform="translate(44.000000, 0.000000)">
                <g id="Group" transform="translate(68.000000, 43.000000)">
                  <path
                    d="M21.9548361,13.3620245 L113.75,39.222169 L113.75,39.222169 L113.75,103.149254 L113.75,193.004483 L20.3368717,208.285917 C19.0151501,208.502137 17.768402,207.605951 17.5521819,206.284229 C17.53008,206.149124 17.5194587,206.01239 17.5204317,205.875492 L18.872332,15.6789332 C18.8818513,14.3396765 19.9752503,13.2617114 21.314507,13.2712307 C21.5310465,13.2727698 21.7464042,13.3033059 21.9548361,13.3620245 Z"
                    id="Rectangle-Copy-5"
                    stroke="#E1E1E1"
                    strokeWidth="0.652294304"
                  />
                  <path
                    d="M4.44685239,34.5988228 L96.25,60.1317592 L96.25,60.1317592 L96.25,123.244486 L96.25,211.955063 L2.8322334,227.042578 C1.51007555,227.256114 0.265149531,226.357399 0.0516133114,225.035241 C0.0300360932,224.90164 0.0196804045,224.766468 0.020654715,224.631141 L1.37212139,36.9176845 C1.38176353,35.5784287 2.47526138,34.5005639 3.81451719,34.510206 C4.02828013,34.511745 4.24090118,34.5415422 4.44685239,34.5988228 Z"
                    id="Rectangle-Copy-6"
                    stroke="#E1E1E1"
                    strokeWidth="0.652294304"
                  />
                  <path
                    d="M11.9548361,45.8620245 L103.75,71.722169 L103.75,71.722169 L103.75,135.649254 L103.75,225.504483 L10.3368717,240.785917 C9.01515014,241.002137 7.76840202,240.105951 7.55218187,238.784229 C7.53008001,238.649124 7.51945869,238.51239 7.52043175,238.375492 L8.87233196,48.1789332 C8.88185128,46.8396765 9.97525027,45.7617114 11.314507,45.7712307 C11.5310465,45.7727698 11.7464042,45.8033059 11.9548361,45.8620245 Z"
                    id="Rectangle-Copy-7"
                    stroke="#E1E1E1"
                    strokeWidth="0.652294304"
                  />
                  <path
                    d="M31.9468524,34.5988228 L121.974794,59.6380264 C123.024006,59.9298407 123.75,60.8853096 123.75,61.9743468 L123.75,123.244486 L123.75,123.244486 L123.75,209.890291 C123.75,211.080336 122.886463,212.094529 121.711642,212.284269 L27.5,227.5 L27.5,227.5 L28.8721214,36.9176845 C28.8817635,35.5784287 29.9752614,34.5005639 31.3145172,34.510206 C31.5282801,34.511745 31.7409012,34.5415422 31.9468524,34.5988228 Z"
                    id="Rectangle-Copy-2"
                    stroke="url(#linearGradient-4)"
                    strokeWidth="2.425"
                  />
                  <mask id="mask-6" fill="white">
                    <use xlinkHref="#path-5" />
                  </mask>
                  <use id="Mask" fill="#FFFFFF" xlinkHref="#path-5" />
                  <g id="Rectangle-Copy-3" mask="url(#mask-6)">
                    <g transform="translate(26.250000, 0.000000)">
                      <mask id="mask-8" fill="white">
                        <use xlinkHref="#path-7" />
                      </mask>
                      <use
                        id="Mask"
                        stroke="none"
                        fillOpacity="0.7"
                        fill="#ECECEC"
                        fillRule="evenodd"
                        xlinkHref="#path-7"
                      />
                      <g id="Rectangle-Copy-3" stroke="none" fill="none" filter="url(#filter-11)" mask="url(#mask-8)">
                        <use fill="black" fillOpacity="1" filter="url(#filter-12)" xlinkHref="#path-10" />
                        <use fill="url(#linearGradient-9)" fillRule="evenodd" xlinkHref="#path-10" />
                      </g>
                      <polygon
                        id="Rectangle-Copy-4"
                        stroke="url(#linearGradient-13)"
                        strokeWidth="2.425"
                        fill="none"
                        filter="url(#filter-14)"
                        mask="url(#mask-8)"
                        points="-11.25 11.25 103.75 43.0120249 103.75 118.995889 103.75 225.797998 -11.25 253.75"
                      />
                    </g>
                  </g>
                  <g id="Mask-Copy">
                    <use fill="black" fillOpacity="1" filter="url(#filter-18)" xlinkHref="#path-17" />
                    <use
                      stroke="url(#linearGradient-16)"
                      strokeWidth="1.2125"
                      fill="url(#linearGradient-15)"
                      fillRule="evenodd"
                      xlinkHref="#path-17"
                    />
                  </g>
                  <ellipse
                    id="Oval-Copy"
                    fill="url(#radialGradient-19)"
                    filter="url(#filter-20)"
                    cx="174.375"
                    cy="247.5"
                    rx="64.375"
                    ry="20"
                  />
                  <g
                    id="People/Person"
                    transform="translate(164.750000, 157.750000) scale(-1, 1) translate(-164.750000, -157.750000) translate(114.250000, 73.750000)"
                  >
                    <g id="Bottom/Standing/2" transform="translate(16.249999, 73.124997)">
                      <g id="Pants-Guide">
                        <use fill="#29FDFF" xlinkHref="#path-21" />
                        <use fill="url(#pattern-22)" style={{ mixBlendMode: 'multiply' }} xlinkHref="#path-21" />
                      </g>
                      <path
                        d="M21.3923218,87.2035997 C26.091351,88.8521785 31.0143533,90.2195204 32.6728822,91.33526 C33.4753993,91.8751361 33.1882677,93.3233191 32.4061711,93.5074474 C30.9754368,93.8442839 24.5811057,93.7759319 19.5315569,93.1367783 C16.1162526,92.7044814 13.5059653,91.8239863 12.7362419,91.3470736 C11.8555252,90.2539271 12.4899708,86.8950431 14.1822459,86.6020743 C15.874521,86.3091055 14.4195599,84.7573184 21.3923218,87.2035997 Z M45.3286132,87.4051595 C50.1935277,88.4687825 55.2464718,89.2259694 57.0286127,90.1312686 C57.8909423,90.5693183 57.7824401,92.0416993 57.0286127,92.3197687 C54.7168815,93.1725129 39.2796144,93.513382 37.2420168,92.5726594 C36.2346438,91.5949934 36.4550153,88.1838265 38.0989726,87.686805 C39.7429298,87.1897836 38.1096985,85.8268784 45.3286132,87.4051595 Z"
                        id="Shoes"
                        fill="#1F2023"
                      />
                      <path
                        d="M42.8749984,-9.9475983e-14 C42.8084231,0.515469033 42.74068,1.02523314 42.6718303,1.52938662 L43.8312869,88.5570378 C43.8437612,89.1104656 43.0164242,89.3871795 41.3492758,89.3871795 C39.4226352,89.4165539 38.4245298,89.13984 38.3549595,88.5570378 C36.7350373,76.7062922 34.0599505,64.5955248 30.3296992,52.2247354 C28.4897833,59.1724238 25.6074462,68.2128651 19.8314836,88.3433757 C19.5982707,89.2095286 18.4271282,89.4667356 16.318056,89.1149967 C14.2089838,88.7632578 13.4011811,88.1472178 13.8946479,87.2668767 C18.0635345,60.5501638 18.6949993,51.1463653 18.6949993,43.0949984 L18.6945719,42.8530527 C18.6663924,34.8549952 17.2471038,25.4365989 15.1849994,-9.9475983e-14 L15.1849994,-9.9475983e-14 Z"
                        id="Legs"
                        fill="#957557"
                      />
                      <path
                        d="M42.8749984,0 C45.1267716,11.4912886 48.5018146,16.24289 46.3065412,27.6252529 C44.1112679,39.0076158 52.9140703,50.6603888 61.3089723,53.0666582 C59.5699685,59.5785916 55.1296454,67.1444642 40.3438071,68.8509936 C28.7596087,70.188001 8.0983918,60.0498427 3.66971663,70.3005443 C-3.81721669,53.2121465 2.47240189,40.9979868 7.40632782,32.984325 C12.3402537,24.9706631 5.24076149,14.539687 15.1849994,0 C23.9742813,0 30.9846674,0 42.8749984,0 Z"
                        id="Dress"
                        fill="#E1E1E1"
                      />
                    </g>
                    <g id="Body/4" transform="translate(0.000000, 9.750000)">
                      <path
                        d="M19.5432296,49.5299982 L27.534999,51.1156775 C19.4979114,66.0937105 15.1877657,74.0320767 14.6045619,74.9307761 C13.2923533,76.9528497 14.5166704,80.65872 14.9689281,82.6102568 C12.1317172,83.2706986 13.250626,79.0003491 9.66019054,79.8919592 C6.38300751,80.7057796 3.60435147,82.7943328 0.54851252,79.9775856 C0.172815078,79.6312831 -0.102213214,78.5488394 1.17872968,77.9936593 C4.37002556,76.6105031 9.08489644,74.0205711 9.84941315,73.015055 C10.8919683,71.6438541 14.1232404,63.8155019 19.5432296,49.5299982 Z M98.5883817,37.5395143 C97.3318279,39.3748424 96.0926633,41.361844 96.5947493,42.005064 C97.0968352,42.6482841 98.5512686,41.9383443 99.1258707,42.9843463 C99.5089388,43.6816809 97.057288,44.5158388 91.7709183,45.4868199 L81.1872737,53.6013199 L76.6749972,47.8206624 L90.8064777,41.6146263 C93.2819837,39.1478172 95.6724375,37.2729281 97.977839,35.9899592 C98.667198,35.7704903 99.8449355,35.7041862 98.5883817,37.5395143 Z"
                        id="Hands"
                        fill="#957557"
                      />
                      <path
                        d="M48.3946934,17.5499994 C48.6830503,18.5329506 48.9636128,19.503661 49.2363808,20.4621305 C49.2412317,20.1796346 49.2479593,19.9021823 49.2560457,19.6294909 L52.6284236,19.0510554 C59.9737858,26.1763951 62.9712897,43.294858 67.866448,45.5607716 C72.494622,47.7031012 79.0554016,45.6299983 86.8539282,42.8307717 L89.2421782,48.1667875 C82.1564654,55.8326233 67.0611906,62.3090709 60.9424346,58.61179 C59.0746285,57.4831613 57.4811164,55.8003528 56.1277141,53.7328966 C56.7439349,60.8920813 56.2963107,66.381128 54.7851891,70.1999974 L54.7851891,70.1999974 L27.9249989,70.1999974 C28.3499643,63.7895858 29.7125904,57.0103324 31.5789512,50.4004458 C25.4957377,61.3736067 20.3827271,69.2731871 16.239603,74.0999973 L9.9849996,72.8749371 C14.0139306,47.8883604 25.6811771,29.4467145 44.9867392,17.5499994 Z"
                        id="Coat"
                        fill="#9B66FF"
                      />
                      <path
                        d="M54.054998,18.7199993 C54.0729348,35.0418049 57.0535604,48.8059656 59.3283767,60.2721365 C59.5254805,61.2656347 59.7824082,63.2344962 58.4188969,63.2344962 L58.4188969,63.2344962 L56.2529185,63.2353264 C57.0448203,53.5757256 54.5392332,38.7375587 48.7361572,18.7208257 Z"
                        id="Shirt"
                        fill="#DDE3E9"
                      />
                      <path
                        d="M67.866448,45.5607716 C72.494622,47.7031012 79.0554016,45.6299983 86.8539282,42.8307717 L89.2421782,48.1667875 C82.1564654,55.8326233 67.0611906,62.3090709 60.9424346,58.61179 C60.1231166,58.1167141 59.3565771,57.515005 58.639931,56.820972 C56.5896863,46.5437599 54.2829337,34.5390736 54.0707452,20.6408787 C60.3911718,28.4407255 63.3049047,43.4492846 67.866448,45.5607716 Z"
                        id="Shadow"
                        fillOpacity="0.15"
                        fill="#000000"
                      />
                    </g>
                    <g id="Head/Back/1" transform="translate(37.999999, 0.000000)">
                      <g id="Head" transform="translate(1.000000, 4.000000)">
                        <g id="Combined-Shape">
                          <mask id="mask-25" fill="white">
                            <use xlinkHref="#path-24" />
                          </mask>
                          <g id="Mask" />
                          <path
                            d="M14.6238569,2.60793447 C21.3860148,2.60793447 21.4181988,7.89079577 21.8860832,10.5424966 C22.4802238,13.9097456 21.4181988,19.1438958 19.6230169,20.2026848 C18.6751399,20.7617377 18.1535662,21.0148674 16.953764,21.5530217 C15.3598585,22.2679454 15.620824,25.793485 15.6476827,27.6096284 L15.9899994,30.0299989 L6.62999976,30.0299989 L7.97881471,23.743381 C8.29620958,22.0750252 9.35304618,18.1053499 8.70168425,17.407558 C6.78977425,15.3593643 5.88522928,14.034527 5.88522928,11.6999996 C5.88522928,8.37109833 7.30031223,2.60793447 14.6238569,2.60793447 Z"
                            id="Head"
                            fill="#765D45"
                            mask="url(#mask-25)"
                          />
                        </g>
                      </g>
                      <path
                        d="M10.7591141,0.0649999976 C14.2601103,0.0649999976 17.0982284,2.89628744 17.0982284,6.38885749 L17.0985625,6.36678028 C20.1703373,6.62601788 22.4456155,8.71142509 22.7499992,10.7730607 C22.7499992,12.2859534 20.74349,13.7435422 19.4459912,14.3063443 C19.4459912,13.419633 18.1492663,12.1150286 17.066115,13.1079561 C15.9092436,14.1684629 16.5913778,15.4794026 17.6130106,15.8455024 C14.4649771,17.3102875 15.5039706,22.3181543 12.0393954,22.6593585 C8.57482032,23.0005627 7.34986696,19.8760265 6.8213495,16.8862911 C6.59639556,15.6137641 6.56293681,13.5846347 7.24442557,11.6511838 C5.54147127,10.5184587 4.41999984,8.58430975 4.41999984,6.38885749 C4.41999984,2.89628744 7.25811798,0.0649999976 10.7591141,0.0649999976 Z"
                        id="Hair"
                        fill="#262225"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
