/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Route } from 'react-router-dom';

import Card from '@cvent/carina-card';

/**
 * A route wrapped in a carina card component
 * @param {object} props Props to drop to the Route
 */
export const CardRoute = (props) => {
  return <Card height="100%" width="100%" bodyContent={<Route {...props} />} />;
};

export default CardRoute;
