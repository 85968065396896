// Requires all files, given some list of require.context entities
// Returns the original list of items; which will now be available for import
// via items('file-path')
const requireAll = (items) => {
  items.keys().forEach((i) => items(i));

  return items;
};

export default requireAll;
