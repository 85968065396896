export const storageMap = {
  STORAGE_PREFIX: 'universalAdminUI',
  FULL_TOKEN: 'fullToken',
  ENVIRONMENT: 'environment',
  INITIAL_TOKEN: 'initialToken',
  SESSION_TIMEOUT: 'sessionTimeout',
  RELAUNCH_LOCATION: 'relaunchLocation',
};

export const roleMap = {
  ADMIN: 'Admin',
  DEVELOPER: 'Developer',
  READ_ONLY: 'Read Only',
};

export const statusMap = {
  INVITED: 'Invited',
  ACCEPTED: 'Active',
  EXPIRED: 'Expired',
};

export const colPaddingShape = {
  top: 16,
  end: 32,
  bottom: 16,
  start: 32,
};

export const actions = {
  DELETE: 'Delete',
  RESEND: 'Resend',
  EDIT: 'Edit',
};

export const workspaceActions = {
  DELETE_WORKSPACE: 'Delete_Workspace',
};

export const sortDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const localeTypes = {
  ENGLISH: 'en',
};

export const workspaceCallStatus = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

export const invitationCallStatus = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

export const developerPageTabs = {
  INVITATIONS: 'invitations',
  ACCEPTED: 'accepted',
};
