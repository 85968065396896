/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ThemeContext } from '@cvent/carina-theme-provider';
import { injectTestId } from '@cvent/nucleus-test-automation';
import Tabs from '@cvent/carina-tabs';

import { pixelToRem } from 'utils/styling';
import AppWidth from 'components/layout/AppWidth';
import translate from 'translate';
import FreeTierInfoBanner from 'components/banners/FreeTierInfoBanner';
import { useAppState } from './AppContextProvider';

const navOptions = [
  {
    value: '/workspaces',
    label: translate('workspaces'),
  },
  {
    value: '/developers',
    label: translate('header.developers'),
  },
];

export const AccessManagementNav = () => {
  const theme = useContext(ThemeContext);
  const [{ usageTier }] = useAppState();
  const history = useHistory();
  const location = useLocation();

  const handleTabSelect = (option) => {
    history.push(option);
  };

  const Nav = css`
    background-color: ${theme.backgroundColor.base};
  `;

  const Title = css`
    font-weight: 300;
    color: ${theme.font.color.active};
    font-size: ${pixelToRem(28)}rem;
    line-height: 2rem;
    margin: 2.5rem 0 2.5rem;
  `;

  return (
    <nav css={Nav} {...injectTestId('app.pages.accessManagement')}>
      <AppWidth inner>
        <FreeTierInfoBanner usageTier={usageTier} />
        <h1 css={Title} {...injectTestId('app.accessManagement.title')}>
          {translate('header.accessManagement')}
        </h1>
        <Tabs
          testID="app.accessManagement.nav"
          options={navOptions}
          onUpdate={handleTabSelect}
          removeBottomBorder
          selected={`/${location.pathname.split('/')[1]}`}
        />
      </AppWidth>
    </nav>
  );
};

export default AccessManagementNav;
