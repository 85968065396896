/** @jsx jsx */
import { useState, useEffect, useMemo } from 'react';
import { css, jsx } from '@emotion/core';

import { Alerts } from '@cvent/carina-alert';
import { Form } from '@cvent/carina-layout';
import FormElement from '@cvent/carina-form-element';
import LoadingSpinner from '@cvent/carina-progress-indicator';
import Textbox from '@cvent/carina-textbox';

import { ScrollViewWithBars } from 'components/layout/ScrollViewWithBars';
import { translate } from 'translate';
import { useAppState } from 'components/AppContextProvider';
import AppWidth from 'components/layout/AppWidth';
import DialogueFormFooter from './DialogueFormFooter';
import DialogueFormHeader from './DialogueFormHeader';
import useFetchData from 'utils/useFetchData';
import WorkspacePicker from './WorkspacePicker';

/**
 * A form to edit developers.
 * @param {func} onClose A function for closing the form.
 * @param {object} developer The developer to be edited.
 */
export default function EditDeveloperForm({ onClose, developer }) {
  const [workspaces, isLoadingWorkspaces] = useFetchData('workspaces', {});
  const [{ developerWorkspaces }] = useAppState();
  const [alerts, setAlerts] = useState();
  const [initialWorkspaces, setInitialWorkspaces] = useState(false);

  useEffect(() => {
    if (
      developer &&
      developer.hasOwnProperty('id') &&
      workspaces &&
      workspaces.length &&
      developerWorkspaces[developer.id]
    ) {
      // TODO - API-2088 Update this logic when the developer workspaces have the name of the workspace.
      const _initialWorkspaces = developerWorkspaces[developer.id].map((devWorkspace) =>
        workspaces.find((element) => element.id === devWorkspace.workspace.id)
      );
      setInitialWorkspaces(_initialWorkspaces);
    }
  }, [developer, workspaces, developerWorkspaces]);

  const finalSetAlerts = useMemo(() => (_alerts) => setAlerts(_alerts.filter((item) => item != null)), [setAlerts]);

  if (isLoadingWorkspaces || !workspaces || !initialWorkspaces) {
    return <LoadingSpinner testID="invitation-form.loading-spinner" />;
  }

  const WorkspaceHeading = css`
    margin-bottom: 0.5rem;
  `;

  const Separator = css`
    margin-bottom: 2rem;
  `;

  return (
    <AppWidth>
      <Form>
        <ScrollViewWithBars
          header={
            <DialogueFormHeader
              onDismiss={onClose}
              title={translate('dialogues.editDeveloper.createTitle')}
              testID="edit-developer-form-header"
            />
          }
          footer={<DialogueFormFooter onCancel={onClose} testID="edit-developer-form-footer" />}
        >
          {alerts ? <Alerts testID="edit-developer.form.alert" variant="user" alerts={alerts} /> : null}
          <FormElement>
            <FormElement.Label required label={translate('headings.email')} labelFor="email" />
            <Textbox id="email" testID="invitation-form.input.email" disabled value={developer.email} />
          </FormElement>
          <FormElement>
            <p css={WorkspaceHeading}>{translate('headings.editWorkspaces')}</p>
            <FormElement.Label required label={translate('labels.selectWorkspaces')} labelFor="workspaces" />
            <hr css={Separator} />
            <WorkspacePicker
              workspaces={workspaces}
              onError={finalSetAlerts}
              initialWorkspaces={initialWorkspaces}
              developerId={developer.id}
            />
          </FormElement>
        </ScrollViewWithBars>
      </Form>
    </AppWidth>
  );
}
