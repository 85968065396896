import { useState, useMemo } from 'react';

/**
 * A hook to handle validation and form state values.
 * @param {*} initialValue The initial form value
 * @param {func} validator A function that takes the value and returns a false if it is valid or
 *                         the error message if it is invalid
 * @param {func} setDirty A function to set the dirtiness of the form (when the value is changed it will set to true)
 *
 * @return {array} Returns an array where:
 *                  0: The state value
 *                  1: A function to give to any form element's onChange.
 *                  2: The validation error or false if no errors
 */
export default function useFormValidator(initialValue, validator, setDirty = () => {}) {
  const [value, setValue] = useState(initialValue);
  // Only re-run validator on initial value if it changes.
  const initialValidation = useMemo(() => validator(initialValue), [initialValue]);
  const [validationError, setValidationError] = useState(initialValidation);

  const onChange = (newValue) => {
    setDirty(true);
    setValue(newValue);
    setValidationError(validator(newValue));
  };

  return [value, onChange, validationError];
}
