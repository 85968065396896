/** @jsx jsx */
import { jsx } from '@emotion/core';
import { createContext, useReducer, useContext } from 'react';

import { actions } from 'reducers';

const Context = createContext();

const AppContextProvider = ({ reducers, initialContext = {}, children }) => {
  const value = useReducer(reducers, initialContext);
  return <Context.Provider value={value} children={children} />;
};

export default AppContextProvider;

export const useAppState = () => {
  const [ctx, dispatch, ...rest] = useContext(Context);

  const bindActionCreator =
    (action) =>
    (...args) => {
      return dispatch(action(...args));
    };

  // take all action creators, bind them to the current dispatch, return the
  // updated context
  const finalCtx = {
    ...ctx,
    ...Object.keys(actions).reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: bindActionCreator(actions[cur]),
      }),
      {}
    ),
  };

  return [finalCtx, dispatch, ...rest];
};
