/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Route, Switch } from 'react-router-dom';
import { Fragment } from 'react';

import CardRoute from 'components/containers/CardRoute';
import Alert from 'components/Alert';
import AppWidth from 'components/layout/AppWidth';
import DeveloperLoginView from 'components/pages/DeveloperLoginView';
import isProd from 'utils/isProd';
import OverviewView from 'components/pages/OverviewView';
import DevelopersView from 'components/pages/DevelopersView';
import WorkspaceView from 'components/pages/WorkspaceView';
import AppBreadcrumb from 'components/AppBreadcrumb';
import WorkspaceDialogue from 'components/dialogues/Workspace';
import InviteDeveloperDialogue from 'components/dialogues/InviteDeveloperDialogue';
import EditDeveloperDialogue from 'components/dialogues/EditDeveloperDialogue';
import AccessManagementNav from 'components/AccessManagementNav';

export default function Routes() {
  const FillHeight = css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `;

  const Container = css`
    padding: 0 0 3rem;
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-grow: 1;
    > div {
      flex-grow: 1;
    }
  `;

  return (
    <Fragment>
      <Route path="/workspaces" component={AccessManagementNav} />
      <Route path="/developers" component={AccessManagementNav} />
      <AppWidth css={FillHeight} inner>
        <Alert testID="app.alert.message" />
        <section css={Container}>
          <AppBreadcrumb />
          <Switch>
            <Route path="/developers" component={DevelopersView} />
            {!isProd && <CardRoute path="/dev-login" component={DeveloperLoginView} />}
            <Route path="/overview" component={OverviewView} />
            <Route path="/workspaces" component={WorkspaceView} />
            <Route exact path="/" component={OverviewView} />
          </Switch>
          <Switch>
            <Route path="/workspaces/create" component={WorkspaceDialogue} />
            <Route path="/workspaces/:id" component={WorkspaceDialogue} />
            <Route path="/developers/invitations/create" component={InviteDeveloperDialogue} />
            <Route path="/developers/edit/:id" component={EditDeveloperDialogue} />
          </Switch>
        </section>
      </AppWidth>
    </Fragment>
  );
}
