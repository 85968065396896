/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import { ScrollViewWithBars as CarinaScrollViewWithBars } from '@cvent/carina-scroll-view-with-bars';

/**
 * A simple wrapping div to provide enough padding to stop cutting off ScrollViewWithBars nodes.
 * @param {node} children - The wrapped children
 */
const PaddingWrapper = ({ children }) => {
  const Wrapper = css`
    padding: 0 4px;
  `;
  return <div css={Wrapper}>{children}</div>;
};

/**
 * A custom version of carina's ScrollViewWithBars that adds padding to the nodes to have inputs not cut-off.
 * @param {node} header - The header node
 * @param {node} footer - The footer node
 * @param {node} children - The component children
 */
export const ScrollViewWithBars = ({ header, footer, children, ...props }) => {
  return (
    <CarinaScrollViewWithBars
      {...props}
      header={!!header && <PaddingWrapper>{header}</PaddingWrapper>}
      footer={!!footer && <PaddingWrapper>{footer}</PaddingWrapper>}
    >
      <PaddingWrapper>{children}</PaddingWrapper>
    </CarinaScrollViewWithBars>
  );
};

export default ScrollViewWithBars;
